<!--
	@name common-dialog-on-air-article
	@description Display an on air article by ID
	@date 2020/03/04
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			{{ displayItem.title }}
		</template>

		<template #body>
			<p v-if="!$moment(displayItem.posted).isAfter($moment())" class="text-body-2 font-weight-regular font-italic grey--text text--darken-2 mb-1">
				{{ displayItem.posted | moment('MMM Do YYYY') }}
			</p>
			<h2 class="mb-1 text-h5 font-weight-bold">
				{{ displayItem.title }}
			</h2>
			<v-chip-group class="mb-3">
				<v-chip v-if="$moment(displayItem.posted).isAfter($moment())" dark label x-small color="pink">
					Coming soon
				</v-chip>
				<v-chip v-for="(category, index) in displayItem.changelogCategoryId" :key="index" dark label x-small :color="getCategoryColour(category)">
					{{ getCategoryName(category) }}
				</v-chip>
			</v-chip-group>
			<div class="text-body-1 font-weight-medium" v-html="$sanitize(displayItem.abstract)" />
			<video v-if="displayItem.video" class="d-block my-5" :src="displayItem.video | urlize" controls width="100%" />
			<v-img
				v-else-if="displayItem.image"
				:src="displayItem.image | urlize"
				class="align-end my-5"
				gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
				height="200px"
				cover
			/>
			<div class="text-body-1 mt-4" v-html="$sanitize(displayItem.description)" />
			<v-divider v-if="1===2" class="mt-6 mb-5 " />
			<div v-if="1===2" class="d-flex ml-n1">
				<div>
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn icon :loading="displayItem.loading" @click="submitChangelogLike(displayItem)" v-on="on">
								<v-icon v-if="displayItem.changelogLikeActive" color="red lighten-2">
									mdi-heart
								</v-icon>
								<v-icon v-else color="blue-grey lighten-2">
									mdi-heart-outline
								</v-icon>
							</v-btn>
						</template>
						<span>{{ displayItem.changelogLikeActive ? 'Thank you for registering your interest' : 'Click here to show your interest' }}</span>
					</v-tooltip>
					{{ displayItem.changelogLikeCount }}
				</div>
				<div class="ml-4">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn icon @click="openCommentDialog(displayItem)" v-on="on">
								<v-icon v-if="displayItem.changelogCommentActive" color="blue lighten-2">
									mdi-comment-text
								</v-icon>
								<v-icon v-else color="blue-grey lighten-2">
									mdi-comment-text-outline
								</v-icon>
							</v-btn>
						</template>
						<span>{{ displayItem.changelogCommentActive ? 'Thank you for leaving a comment' : 'Click here to leave a comment' }}</span>
					</v-tooltip>
					{{ displayItem.changelogCommentCount }}
				</div>
				<div class="ml-4">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn icon @click="openChat" v-on="on">
								<v-icon color="blue-grey lighten-2">
									mdi-chat-question-outline
								</v-icon>
							</v-btn>
						</template>
						<span>Got a question? Speak with one of our team</span>
					</v-tooltip>
				</div>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import CommonDialog from '@/component/common/dialog';
	import { EventBus } from '@/utils';
	import { cloneDeep } from 'lodash';

	export default {
		name: 'common-dialog-on-air-article',

        components: {
			CommonDialog
		},

		data() {
			return {
				loading: false,
                items: null,
                displayItem: {},
				categories: []
            };
        },

        computed: {
			...mapState('CmsChangelog', ['changelogItems'])
        },

		methods: {
			...mapActions('CmsChangelog', ['loadChangelogCategoryList']),

            /**
			 * @name init
			 * @description init component
			 */
			init(id) {
                if (!this.changelogItems) {
                    EventBus.$on('changelog-loaded', this.init(id));
                    return;
                }

                this.items = cloneDeep(this.changelogItems);
                let item = this.items.find((i) => i.id == id);
                this.displayItem = item;

                this.loadChangelogCategoryList().then(({ data: changelogCategoryList }) => this.categories = changelogCategoryList.data);
				this.$refs.dialog.open();
			},

			/**
			 * @name close
			 * @description Close dialog
			 */
			close() {
				this.$refs.dialog.close();
			},

            /**
			 * @name getCategoryName
			 * @description Get category name by id
			 * @param {String} id category id
			 */
			getCategoryName(id) {
				const category = this.categories.find((category) => category.id === id);
				return category ? category.name : '';
			},

            /**
			 * @name getCategoryColour
			 * @description Get category colour by id
			 * @param {String} id category id
			 */
			getCategoryColour(id) {
				const category = this.categories.find((category) => category.id === id);
				return category ? category.colour : '';
			},

            /**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			}
		}
	};
</script>
