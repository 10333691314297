import AccountSettings from '@/component/app/account-settings';
import Details from '@/component/app/account-settings/details';
import Security from '@/component/app/account-settings/security';
import Credentials from '@/component/app/account-settings/credentials';
import Preferences from '@/component/app/account-settings/preferences';

/**
 * @namespace Router
 * @exports Router
 * @description Routes for system
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default [
	{
		name: 'settings',
		path: '/settings',
		meta: { layout: 'default-authed', requiresSiteCheck: true },
		component: AccountSettings,
		beforeEnter(to, from, next) {
			if (to.name === 'settings') next('/settings/details');
			else next();
		},
		children: [
			{
				name: 'settings.details',
				path: 'details',
				meta: { layout: 'default-authed' },
				component: Details
			},
			{
				name: 'settings.security',
				path: 'security',
				meta: { layout: 'default-authed' },
				component: Security
			},
			{
				name: 'settings.credentials',
				path: 'credentials',
				meta: { layout: 'default-authed' },
				component: Credentials
			},
			{
				name: 'settings.preferences',
				path: 'preferences',
				meta: { layout: 'default-authed' },
				component: Preferences
			}
		]
	}
];
