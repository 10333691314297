<!--
	@name app-client-service-home-reversion-plan-referral-form
	@description Home Reversion Plan Referral Form
	@date 2020/07/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div v-if="form('home_reversion_plan').value">
			<!-- Applicant Details-->
			<div v-show="productType.clientChoice">
				<common-form-client
					ref="applicantDetails"
					:form="formFromGroup('home_reversion_plan', 'client')"
					:clients="clientList"
					:show-error="submitFailed"
					read-only
					:master-client="false"
					@set-client="setApplicant"
					@set-validation="setValidation"
				/>
			</div>

			<!-- Property Details -->
			<common-form-property
				ref="propertyDetails"
				:property-form="formFromGroup('home_reversion_plan', 'asset')"
				:property="property"
				:client="client"
				:show-error="submitFailed"
				@set-validation="setValidation"
				@set-property="setProperty"
			/>

			<common-form-section ref="borrowing" :form="formFromGroup('home_reversion_plan', 'borrowing')" title="Borrowing" :item="borrowing" @set-validation="setValidation" />

			<common-form-section ref="fee" :form="formFromGroup('home_reversion_plan', 'fee')" title="Fee" :item="fee" @set-validation="setValidation" />

			<!-- Information -->
			<common-form-section ref="information" title="Other Details" :form="formFromGroup('home_reversion_plan', 'information')" :item="otherDetails" />

			<!-- Form Footer -->
			<div v-show="form('home_reversion_plan').value" class="text-center">
				<v-alert v-show="!isValid" transition="slide-y-transition" dense text border="left" type="error" class="mb-6 text-body-2 font-weight-bold">
					You cannot proceed until ALL sections are validated. Please go back and address any sections displaying this error message.
				</v-alert>
				<v-btn color="primary" :loading="submitting" @click="submit">
					Submit
				</v-btn>
			</div>
		</div>
		<v-card v-else class="flex-grow-1 mb-6">
			<v-skeleton-loader type="list-item-three-line" />
		</v-card>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import CommonFormClient from '@/component/common/form/client';
	import CommonFormProperty from '@/component/common/form/property';
	import CommonFormSection from '@/component/common/form/section';

	export default {
		name: 'app-client-service-home-reversion-plan-referral-form',

		components: { CommonFormClient, CommonFormProperty, CommonFormSection },

		props: {
			client: { type: Object, required: false, default: null },
			association: { type: Object, required: false, default: null },
			assetId: { type: String, required: false, default: null },
			submitting: { type: Boolean, required: true, default: false }
		},

		data() {
			return {
				submitFailed: false,
				productType: {},
				clientList: [{}],
				property: {},
				borrowing: {},
				fee: {},
				otherDetails: {},
				validations: {
					property: false,
					borrowing: false,
					fee: false
				}
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form', 'formFromGroup']),

			isValid() {
				return !Object.keys(this.validations).find((key) => !this.validations[key]);
			},

			productTypeForm() {
				if (!this.client) return [];
				return this.formFromGroup('home_reversion_plan', 'product_type').reduce((acc, cur) => {
					acc.push(cur);
					return acc;
				}, []);
			}
		},

		created() {
			this.fetchForm();
			this.productType.clientChoice = `${this.client.id}${this.association ? `,${this.association.id}` : ''}`;
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),

			getClientFullName(client) {
				return `${client.nameTitleName.charAt(0).toUpperCase() + client.nameTitleName.slice(1)} ${client.nameGiven} ${client.nameMiddle || ''} ${client.nameFamily}`;
			},

			setApplicant({ dataIndex, data }) {
				this.$set(this.clientList, dataIndex, data);
				this.$nextTick(() => this.$refs.applicantDetails.validateForm());
			},

			setProperty(property) {
				this.property = property;
				this.$nextTick(() => this.$refs.propertyDetails.validateForm());
			},

			setValidation(validation) {
				this.validations = { ...this.validations, ...validation };
			},

			clientChoiceSelected() {
				this.clientList = [{}];
				this.productType.clientChoice
					.split(',')
					.forEach((clientId, index) => this.$refs.applicantDetails.fetchClient(index, clientId === this.client.id ? this.client.id : this.association.id));
				this.$refs.propertyDetails.fetchAsset(this.client.id, this.assetId);
			},

			/**
			 * @name fetchForm
			 * @description Fetch home_reversion_plan form
			 */
			fetchForm() {
				this.loadForm('home_reversion_plan').then(() => this.clientChoiceSelected());
			},

			/**
			 * @name createPayload
			 * @description Create Payload for home_reversion_plan
			 */
			createPayload() {
				// map asset
				const property = this.property;
				const asset = {
					type: 'property',
					id: property.id,
					data: {
						location: {
							address1: property.address1,
							address2: property.address2,
							townCity: property.townCity,
							county: property.county,
							countryId: property.countryId,
							postcode: property.postcode
						},
						valuation: {
							value: property.valuation
						}
					}
				};

				// map client
				const client = this.clientList.map((a) => ({
					id: a.id,
					nameTitleId: a.nameTitleId,
					nameTitleName: a.nameTitleName,
					nameGiven: a.nameGiven,
					nameFamily: a.nameFamily,
					communication: [
						{ id: a.landlineId, clientId: a.id, identity: a.landline, type: 'phone', context: 'landline' },
						{ id: a.mobileId, clientId: a.id, identity: a.mobile, type: 'phone', context: 'mobile' },
						{ id: a.emailId, clientId: a.id, identity: a.email, type: 'email', context: null }
					]
				}));

				return {
					client,
					asset,
					process: {
						loanAmount: this.borrowing.loanAmount,
						fee: this.fee.fee,
						ongoingCommission: this.fee.ongoingCommission,
						information: this.otherDetails.information
					},
					matterType: 'home_reversion_plan',
					processType: 'application_home_reversion_plan'
				};
			},

			/**
			 * @name submit
			 * @description Submit home reversion plan referral to the server if forms are valid
			 */
			async submit() {
				if (!this.validateForms()) {
					this.submitFailed = true;
					return;
				}
				const payload = this.createPayload();
				this.$emit('submit', payload);
			},

			/**
			 * @name validateForms
			 * @description Validate all forms in the page
			 * @return {Boolean} Validation result
			 */
			validateForms() {
				this.$refs.borrowing.validateForm();
				this.$refs.fee.validateForm();

				return this.isValid;
			}
		}
	};
</script>
