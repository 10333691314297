import { AccountOrganisation, AccountOrganisationAddress, AccountOrganisationCommunication } from '@/service';

import { shuffle, merge } from 'lodash';

/**
 * @class @name Organisation
 * @description Exposes menu store module
 */
export default class Organisation {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			organisationList: undefined
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name mappedProviderList
			 * @description used to get mapped provider list from store
			 * @param {Object} state
			 */
			mappedProviderList: (state) => {
				return (state.organisationList || []).reduce((acc, cur) => {
					if (cur.type !== 'provider') return acc;
					acc.push({
						title: cur.name,
						subTitle: `${cur.organisationAddress.address1} ${cur.organisationAddress.address2}, ${
							cur.organisationAddress.address3 ? cur.organisationAddress.address3 + ',' : ''
						} ${cur.organisationAddress.townCity}, ${cur.organisationAddress.postalCode}`,
						address: `${cur.organisationAddress.address1} ${cur.organisationAddress.address2}, ${
							cur.organisationAddress.address3 ? cur.organisationAddress.address3 + ',' : ''
						} ${cur.organisationAddress.townCity}, ${cur.organisationAddress.postalCode}`,
						latitude: cur.organisationAddress.geolocation?.x,
						longitude: cur.organisationAddress.geolocation?.y,
						image: `logo/organisation/${cur.organisationDetail?.logo?.find((l) => l.primary).name}`,
						phone: cur.organisationCommunication?.find((c) => c.type === 'phone')?.identifier,
						email: cur.organisationCommunication?.find((c) => c.type === 'email')?.identifier,
						website: cur.organisationDetail?.website,
						detail: cur.organisationProvider?.data?.bio
					});
					return acc;
				}, []);
			},

			/**
			 * @name mappedAbassadorList
			 * @description used to get mapped ambassador list from store
			 * @param {Object} state
			 */
			mappedAmbassadorList: (state) => {
				const mappedList = (state.organisationList || []).reduce((acc, cur) => {
					if (!cur.ambassador) return acc;
					acc.push({
						title: cur.name,
						subTitle: `${cur.organisationAddress.address1} ${cur.organisationAddress.address2}, ${
							cur.organisationAddress.address3 ? cur.organisationAddress.address3 + ',' : ''
						} ${cur.organisationAddress.townCity}, ${cur.organisationAddress.postalCode}`,
						address: `${cur.organisationAddress.address1} ${cur.organisationAddress.address2}, ${
							cur.organisationAddress.address3 ? cur.organisationAddress.address3 + ',' : ''
						} ${cur.organisationAddress.townCity}, ${cur.organisationAddress.postalCode}`,
						latitude: cur.organisationAddress.geolocation?.x,
						longitude: cur.organisationAddress.geolocation?.y,
						image: `logo/organisation/${cur.organisationDetail?.logo?.find((l) => l.primary).name}`,
						phone: cur.organisationCommunication.find((c) => c.type === 'phone')?.identifier,
						email: cur.organisationCommunication.find((c) => c.type === 'email')?.identifier,
						website: cur.organisationDetail?.website,
						detail: cur.organisationProvider?.data?.bio,
						id: cur.id
					});
					return acc;
				}, []);

				return shuffle(mappedList);
			},

			/**
			 * @name mappedPartnerList
			 * @description used to get mapped partner list from store
			 * @param {Object} state
			 */
			mappedPartnerList: (state) => {
				return (state.organisationList || []).reduce((acc, cur) => {
					if (!cur.partner) return acc;
					acc.push({
						title: cur.name,
						subTitle: `${cur.organisationAddress.address1} ${cur.organisationAddress.address2}, ${
							cur.organisationAddress.address3 ? cur.organisationAddress.address3 + ',' : ''
						} ${cur.organisationAddress.townCity}, ${cur.organisationAddress.postalCode}`,
						address: `${cur.organisationAddress.address1} ${cur.organisationAddress.address2}, ${
							cur.organisationAddress.address3 ? cur.organisationAddress.address3 + ',' : ''
						} ${cur.organisationAddress.townCity}, ${cur.organisationAddress.postalCode}`,
						latitude: cur.organisationAddress.geolocation?.x,
						longitude: cur.organisationAddress.geolocation?.y,
						image: `logo/organisation/${cur.organisationDetail?.logo?.find((l) => l.primary).name}`,
						phone: cur.organisationCommunication.find((c) => c.type === 'phone')?.identifier,
						email: cur.organisationCommunication.find((c) => c.type === 'email')?.identifier,
						website: cur.organisationDetail?.website,
						detail: cur.organisationProvider?.data?.bio
					});
					return acc;
				}, []);
			},

			/**
			 * @name getOrganisation
			 * @description used to get organisation from store
			 * @param {Object} state
			 */
			getOrganisation: (state) => (orgId) => (state.organisationList && state.organisationList.find((o) => o.id === orgId)) || {},

			/**
			 * @name tradingStyleList
			 * @description used to get mapped partner list from store
			 * @param {Object} state
			 */
			tradingStyleList: (state) => (orgId) => state.organisationList.find((o) => o.id === orgId).tradingStyle.filter((o) => !o.delted) || []
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setOrganisationList
			 * @param {Object} state
			 * @param {Object} data
			 */
			setOrganisationList(state, data) {
				// state.organisationList = state.organisationList ? [...state.organisationList, data] : [data];
				if (!state.organisationList) state.organisationList = data;
				else {
					const arr = [...state.organisationList];
					data.forEach((item) => {
						let idx = arr.reduce((p, c, i) => (c.id === item.id ? i : p), -1);
						if (idx >= 0) arr[idx] = item;
						else arr.push(item);
					});
					state.organisationList = arr;
				}
			},

			/**
			 * @name setOrganisation
			 * @param {Object} state
			 * @param {Object} data
			 */
			setOrganisation(state, data) {
				if (!state.organisationList) state.organisationList = [data];
				else {
					const arr = [...state.organisationList];
					const idx = arr.reduce((p, c, i) => (c.id === data.id ? i : p), -1);
					if (idx >= 0) arr[idx] = data;
					else arr.push(data);
					state.organisationList = arr;
				}
			},

			/**
			 * @name setOrganisationAddress
			 * @param {Object} state
			 */
			setOrganisationAddress(state, data) {
				const arr = [...state.organisationList];
				const idx = arr.reduce((p, c, i) => (c.id === data.organisationId ? i : p), -1);
				arr[idx] = { ...state.organisationList[idx], organisationAddress: { ...state.organisationList[idx].organisationAddress, ...data } };
				state.organisationList = arr;
			},

			/**
			 * @name setOrganisationCommunication
			 * @param {Object} state
			 */
			setOrganisationCommunication(state, data) {
				const arr = [...state.organisationList];
				const idx = arr.reduce((p, c, i) => (c.id === data.organisationId ? i : p), -1);
				arr[idx] = {
					...state.organisationList[idx],
					organisationCommunication: [...state.organisationList[idx].organisationCommunication.filter((oc) => oc.id !== data.id), data]
				};
				state.organisationList = arr;
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadOrganisationDetails
			 * @param {function} commit
			 */
			async loadOrganisationDetails({ commit }, id) {
				const { data } = await AccountOrganisation.get(id);
				commit('setOrganisation', data);
				return data;
			},

			/**
			 * @async @name loadOrganisationDetails
			 * @param {function} commit
			 */
			async loadOrganisationList({ commit }, payload) {
				const { data } = await AccountOrganisation.list(payload);
				commit('setOrganisationList', data.data);
				return data;
			},

			/**
			 * @async @name loadProviderList
			 * @param {function} commit
			 */
			async loadProviderList(context, payload) {
				const { data } = await AccountOrganisation.list(merge(payload || {}, { where: { type: 'provider', 'organisation_provider.equity_release': true } }));
				return data;
			},

			/**
			 * @async @name loadAmbassadorList
			 * @param {function} commit
			 */
			async loadAmbassadorList({ commit }) {
				const { data } = await AccountOrganisation.list({ where: { type: 'provider', ambassador: true, active: true } });
				commit('setOrganisationList', data.data);
			},

			/**
			 * @async @name loadPartnerList
			 * @param {function} commit
			 */
			async loadPartnerList({ commit }) {
				const { data } = await AccountOrganisation.list({ where: { type: 'provider', partner: true, active: true }, order: { property: 'organisation.name' } });
				commit('setOrganisationList', data.data);
			},

			/**
			 * @async @name addTradingStyle
			 * @param {function} commit
			 */
			async addTradingStyle({ commit, state }, payload) {
				return AccountOrganisation.post(payload)
					.then(({ data }) =>
						commit('setOrganisation', {
							...state.organisationList.find((o) => o.id === payload.tradingForOrganisationId),
							tradingStyle: [...state.organisationList.find((o) => o.id === payload.tradingForOrganisationId).tradingStyle, data]
						})
					)
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name modifyOrganisation
			 * @param {function} commit
			 */
			async modifyOrganisation({ commit, state }, payload) {
				return AccountOrganisation.patch(payload.id, payload)
					.then(() => {
						// TODO: Temporary fix to update organisation name in Account store. Needs to be refactored.
						commit('Account/setOrganisation', { name: payload.name }, { root: true });
						commit('setOrganisation', { ...state.organisationList.find((o) => o.id === payload.id), ...payload });
					})
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name modifyOrganisationAddress
			 * @param {function} commit
			 */
			async modifyOrganisationAddress({ commit }, payload) {
				return AccountOrganisationAddress.patch(payload.id, payload)
					.then(() => commit('setOrganisationAddress', payload))
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name modifyOrganisationCommunication
			 * @param {function} commit
			 */
			async modifyOrganisationCommunication({ commit }, payload) {
				return AccountOrganisationCommunication.patch(payload.id, payload)
					.then(() => commit('setOrganisationCommunication', payload))
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name modifyOrganisationFcaNumberRequest
			 * @param {function} commit
			 */
			async modifyOrganisationFcaNumberRequest(context, payload) {
				return AccountOrganisation.modifyFcaNumberRequest(payload);
			}
		};
	}
}
