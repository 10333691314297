<!--
	@name app-air-academy-dashboard
	@description Join tab view for the air academy page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-6">
		<div>
			<v-row class="mb-6">
				<v-col cols="7" class="pr-10">
					<p class="text-body-1 mb-3">
						With an increasing focus on customer outcomes and the complexities that come with advising and guiding older consumers through the later life lending landscape, <strong>Air Academy provides an unrivalled benchmark of expertise and knowledge for this industry.</strong>
					</p>
					<p class="text-body-1 mb-0">
						Our mission is simple: To empower advisers with sector-leading training that not only enhances industry knowledge and professionalism, but also supports continuous professional development.
					</p>
				</v-col>
				<v-col cols="5">
					<v-skeleton-loader v-if="!inductionWebinar.name" type="card" class="pa-0 ma-0" />
					<app-air-academy-training-webinar-item v-else :item="inductionWebinar" @play-video="playVideo" />
					<app-dialog-video-player ref="videoDialog" />
				</v-col>
			</v-row>

			<p class="text-subtitle-1 font-weight-bold mb-3">
				FOR MORE INFORMATION CLICK ON THE  FIVE STAGES OF THE ACADEMY BELOW:
			</p>
			<div class="d-flex flex-wrap justify-center ma-n4">
				<v-item v-for="(ring, i) in rings" :key="i">
					<v-card
						class="d-flex flex-grow-1 flex-column ma-4"
						color="grey lighten-2"
						height="170"
						style="flex-basis: 30%; position: relative;"
						hover
						@click="handleAction(ring)"
					>
						<v-system-bar :class="ring.cardClass" height="16" />
						<div
							class="d-flex justify-center align-center flex-grow-1 text-center pa-6"
						>
							<p class="text-h6 mb-0" v-html="$sanitize(ring.text)" />
						</div>
						<v-chip v-if="ring.accredited" label dark small color="accent" class="black--text" style="position: absolute; bottom: -12px; right: -12px;">
							LIBF Accredited
						</v-chip>
					</v-card>
				</v-item>
			</div>
			<p class="text-body-1 mt-6 mb-0">
				The five stages of the Academy offer a holistic range of development opportunities that support your growth in the later life lending market.
				Whether it's context in the market, the practicalities of engaging with your customer, navigating the best sourcing technology in the sector,
				or pushing the boundaries of your knowledge with content from our expert Ambassadors, the Academy offers hands-on learning that makes the complex simple,
				allowing more time to be spent with the customer.
			</p>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapState } from 'vuex';
	import AppAirAcademyTrainingWebinarItem from '@/component/app/air-academy/training/webinar-item';
	import AppDialogVideoPlayer from '@/component/app/dialog/video-player';
	import { EventBus } from '@/utils';

	export default {
		name: 'app-air-academy-dashboard',

		components: {
			AppAirAcademyTrainingWebinarItem,
			AppDialogVideoPlayer
		},

		data() {
			return {
				rings: [
					{
						text: 'Adviser Accreditation for Later Life Lending',
						cardClass: 'background-rotation-manual--1 white--text',
						accredited: true,
						ref: 'ringOne'
					},
					{
						text: 'Later Life Lending Adviser Induction',
						cardClass: 'background-rotation-manual--2 white--text',
						accredited: true,
						ref: 'ringTwo'
					},
					{
						text: 'Systems & Technology Training',
						cardClass: 'background-rotation-manual--3 white--text',
						ref: 'ringThree'
					},
					{
						text: 'Insights Hub: On-Demand CPD',
						cardClass: 'background-rotation-manual--4 white--text',
						ref: 'ringFour'
					},
					{
						text: 'Air Academy Events',
						cardClass: 'background-rotation-manual--5 white--text',
						path: '/events'
					}
				],
				subscriptions: [
					{
						title: 'Non Air Later Life Professional (new) members',
						price: 25
					},
					{
						title: 'Equity Release Council members',
						price: 20
					},
					{
						title: 'All Air Later Life Professional Members',
						price: 15
					}
				]
			};
		},

		computed: {
			...mapState('Account', ['showJoinForm']),
			...mapState('AppWebinar', ['courses']),
			...mapGetters('Account', ['membership']),
			...mapGetters('CmsData', ['data']),

			inductionWebinar() {
				return {
					name: 'An introduction to Air Academy',
					image: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/webinar/AcademyWelcome.jpg`,
					mimetype: 'video/mp4',
					hideStatus: true,
					fixedHeight: true,
					emit: 'play-video'
				};
			}
		},

		methods: {
			playVideo() {
				this.$refs.videoDialog.open(`${process.env.VUE_APP_S3_AIR_ASSETS_URL}/webinar/AcademyWelcome.mp4`);
			},

			handleAction(item) {
				if (item.path) this.$router.push(item.path);
				if (item.ref) this.openRing(item.ref);
			},

			openRing(ring) {
				EventBus.$emit('open-academy-ring', ring);
			}
		}
	};
</script>

<style scoped lang="scss">
	.subscriptions {
		tr td {
			border-bottom: 5px solid #ffffff !important;
		}
		tr:last-child td {
			border-bottom: none !important;
		}
	}
</style>
