<!--
	@name app-source-result-insights
	@description Show source results page
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="h-100">
		<v-card elevation="0" :class="{ 'card--design1--highlight': contentUpdated }" class="h-100 d-flex flex-column card--design1 card--design1--3 hover--disabled">
			<v-card-title class="text-body-2 pb-2">
				Insights
			</v-card-title>
			<v-card-text class="flex-grow-1 d-flex flex-column">
				<v-skeleton-loader v-if="loading || !productData.provider" type="sentences" class="mt-2" />
				<template v-else>
					<p class="text-body-2 pb-0 mb-3 line-clamp flex-grow-1" style="-webkit-line-clamp: 2">
						Monthly payments of <span class="grey--text text--darken-3">£{{ productData.repayment | numFormat('0,00') }}</span> would result in a total cost saving of
						<span class="grey--text text--darken-3">£{{ productData.saving | numFormat('0,00') }}</span> with the {{ productData.product }} from
						{{ productData.provider }}.
					</p>
					<div class="flex-grow-0">
						<v-btn small style="z-index: 2" :disabled="isArchive" @click="openBorrowingForecast">
							Compare forecast
						</v-btn>
						<v-btn
							class="ga--trigger ga--filters-opened ml-2"
							data-ga-id="insights-widget"
							small
							style="z-index: 2"
							:disabled="isArchive"
							@click="$emit('update-criteria', 850)"
						>
							Update affordability
						</v-btn>
					</div>
				</template>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { cloneDeep } from 'lodash';

	export default {
		name: 'app-source-result-insights',

		props: {
			process: {
				type: Object,
				default: null
			},
			iterationId: {
				type: String,
				default: null
			},
			quickQuote: {
				type: Object,
				default: null
			},
			isFactFind: {
				type: Boolean,
				default: false
			},
			loadingProducts: {
				type: Boolean,
				default: false
			},
			productList: {
				type: Array,
				default: () => [],
				required: true
			}
		},

		data() {
			return {
				loading: false,
				maximumMonthlyRepaymentAmount: 50,
				monthlyRepaymentIncludeVoluntaryRepayment: true,
				contentUpdated: false,
				insightsProduct: null,
				topProduct: null,
				productData: {
					provider: null,
					product: null,
					repayment: null,
					saving: null
				}
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),

			isArchive() {
				return this.$route.query && this.$route.query.view === 'archive';
			}
		},

		mounted() {
			this.init();
		},

		methods: {
			...mapActions('LifetimeMortgageSourcing', ['fetchInsights']),

			openBorrowingForecast() {
				let productOne = cloneDeep(this.insightsProduct);
				productOne.name += ' *';
				this.$emit('open-borrowing-forecast-dialog', productOne, this.topProduct, false, 'insights-widget');
			},

			async init(payloadObj) {
				this.contentUpdated = false;
				if (!this.process) return;
				if (!this.iterationId) return;
				if (!this.productList.length) return;

				this.loading = true;

				let payload = cloneDeep(payloadObj);
				payload.filters = payload.filters || {};
				payload.filters.maximumMonthlyRepaymentAmount = this.maximumMonthlyRepaymentAmount;
				payload.filters.monthlyRepaymentIncludeVoluntaryRepayment = this.monthlyRepaymentIncludeVoluntaryRepayment;

				return (
					this.fetchInsights({
						data: payload,
						iterationId: this.iterationId,
						processId: this.process.id
					})
						.then(({ data }) => {
							// No products? Let's hide!
							if (!data.products?.[0]) {
								this.$emit('hide-insights');
								return;
							}

							// Set our data
							this.insightsProduct = data.products[0];
							this.productData.provider = this.insightsProduct.provider.name;
							this.productData.product = this.insightsProduct.name;
							this.productData.repayment = this.insightsProduct.payments.totalPayments;
							this.topProduct = this.productList?.[0];
							this.productData.saving = this.topProduct.totalToPay - this.insightsProduct.totalToPay;

							// If all is well, confirm display, otherwise hide
							if (this.productData.saving <= 0) this.$emit('hide-insights');
							else this.$emit('show-insights');

							this.loading = false;
							this.contentUpdated = true;
						})
						// eslint-disable-next-line no-unused-vars
						.catch((error) => {
							this.$emit('hide-insights');
						})
				);
			}
		}
	};
</script>
