<!--
	@name app-account-settings-credentials-oauth
	@description Account settings - Account credentials oauth window content
	@date 2022/09/28
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-dialog-progress ref="progress" :text="false" :progress-color="'green'" stream indeterminate>
			<template #header>
				Processing Request
			</template>
			<template #body>
				<p class="text-body-1 mb-3">
					Please be patient whilst we redirect you...
				</p>
			</template>
		</common-dialog-progress>
	</div>
</template>

<script>
	import CommonDialogProgress from '@/component/common/dialog/progress';
	import { mapActions } from 'vuex';

	export default {
		name: 'app-account-settings-credentials-oauth',

		components: { CommonDialogProgress },

		props: {
			partner: { type: String, required: true }
		},

		mounted() {
			this.submit();
		},

		methods: {
			...mapActions('Oauth', ['checkGateway', 'submitOauth']),

			async submit() {
				const { gateway, ...params } = this.$route.query;
				//const { code, state, gateway } = this.$route.query;
				//if (!code) return this.error();

				try {
					this.$refs.progress.open();

					// Check if we access has not been declined by the user and we have all the required data to get access token
					if (
						params.error ||
						(this.partner === 'knowledge_bank' && !['sc', 'ut', 'st', 's'].every((el) => Object.prototype.hasOwnProperty.call(params, el))) ||
						(this.partner !== 'knowledge_bank' && !['state', 'code'].every((el) => Object.prototype.hasOwnProperty.call(params, el)))
					) {
						this.error('Authentication Declined');
						return;
					}

					if(!gateway) {
						// The gateway is used to redirect to a subdomain where relevant
						const redirect = await this.checkGateway({ partner: this.partner, payload: this.$route.query });
						if (redirect.error) return this.error();

						// Append gateway = true to avoid loop
						if(redirect.data.url) {
							window.location = `${redirect.data.url}${window.location.search}&gateway=true`;
							return;
						}
					}

					const response = await this.submitOauth({ partner: this.partner, payload: this.$route.query });
					if (!response.error) {
						window.opener.postMessage({ [response.data.provider]: response.data }, "*");
						setTimeout(window.close, 100);
					} else this.error();
				} catch (error) {
					this.error();
				}
			},

			error(message) {
				window.opener.postMessage({ error: message || 'Authentication failed' }, "*");
				setTimeout(window.close, 100);
			}
		}
	};
</script>
