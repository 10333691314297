<!--
	@name app-layout-account
	@description Account pages layout
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-layout v-if="!authed" :component="$options.name" align-content-center justify-center fill-height wrap>
		<v-progress-circular :size="70" :width="7" color="blue-grey" indeterminate />
	</v-layout>
	<v-app v-else :component="$options.name">
		<app-layout-default-authed-header />
		<app-layout-default-authed-sidebar v-if="!isAdmin" />

		<v-main :class="{ padding: !isAdmin }">
			<v-container fluid class="pa-5">
				<common-dialog ref="credentialsDialog" :component="$options.name" eager>
					<template #body>
						<app-account-settings-credentials slim :lenders="lenders" @accounts-updated="credentialsUpdated" />
					</template>
				</common-dialog>
				<app />
			</v-container>
		</v-main>
		<app-layout-default-authed-footer />
	</v-app>
</template>

<script>
	import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
	import App from '@/component/app';
	import AppLayoutDefaultAuthedHeader from './header';
	import AppLayoutDefaultAuthedFooter from './footer';
	import AppLayoutDefaultAuthedSidebar from './sidebar';
	import AppAccountSettingsCredentials from '@/component/app/account-settings/credentials';
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-layout-account',

		components: {
			App,
			AppLayoutDefaultAuthedHeader,
			AppLayoutDefaultAuthedFooter,
			AppLayoutDefaultAuthedSidebar,
			AppAccountSettingsCredentials,
			CommonDialog
		},

		data() {
			return {
				lenders: []
			};
		},

		computed: {
			...mapGetters('Account', ['authed', 'isAdmin']),
			...mapState('Account', ['user', 'authedByLogin']),
			...mapState('CmsMenu', ['authedMenu'])
		},

		created() {
			if (this.user.type === 'advisor') this.$connectWebSocket();

			this.setAuthedMenu();
			this.loadAuthedMenu();
			console.log('Default Authed Created');

			this.$websocket.$on('message', this.onMessage);
		},

		beforeDestroy() {
			this.$websocket.$off('message', this.onMessage);
		},

		destroyed() {
			this.$closeWebSocket();
			console.log('Default Authed Destroyed');
		},

		methods: {
			...mapActions('CmsMenu', ['loadAuthedMenu']),
			...mapMutations('CmsMenu', ['setAuthedMenu']),

			/**
			 * @name credentialsUpdated
			 * @description Updates the validity of the credentials
			 * @param {Array} accounts
			 */
			credentialsUpdated(accounts) {
				if (accounts?.every((account) => !!account.credential && new Date(account.credential.expire) > new Date())) {
					this.$refs.credentialsDialog.close();
				}
			},

			/**
			 * @name onMessage
			 * @description Handle websocket messages
			 * @param data
			 */
			onMessage(message) {
				if (message?.type === 'init' && this.authedByLogin) {
					// this.$sendMessage({ type: 'expiredCredentialsCheck' }); Temperorely disable expired credentails check
				} else if (message?.type === 'expiredCredentialsCheck' && message.expiredInterfaces?.length) {
					this.lenders = message.expiredInterfaces;
					this.$refs.credentialsDialog.open();
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.padding {
		padding-left: 70px !important;
	}
</style>
