<!--
	@name common-dialog-dynamic-form-dialog
	@description Wrapper dialog component for dynamic form
	@date 2020/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-dialog :value="active" eager scrollable :component="$options.name" persistent max-width="800" max-height="600">
		<v-card>
			<v-app-bar dark :color="color">
				<v-toolbar-title>
					{{ config.title }}
				</v-toolbar-title>

				<v-spacer />

				<v-btn icon @click="closeDialog">
					<v-icon>mdi-close-box</v-icon>
				</v-btn>
			</v-app-bar>

			<v-card-text class="dynamic-form-wrapper">
				<slot name="header" />

				<dynamic-form
					ref="dynamicForm"
					:submit-button-text="config.submitButtonText"
					:submit-button-loading="submitButtonLoading"
					:form-schema="config.formSchema"
					:form-data="data"
					@dynamic-form-submit="submit"
				/>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import { isEmpty } from 'lodash';
	import moment from 'moment';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'common-dialog-dynamic-form-dialog',

		components: {
			DynamicForm
		},

		props: {
			submitButtonLoading: { type: Boolean, default: false },
			color: { type: String, default: 'blue-grey v-app-bar-generic-gradient' }
		},

		data() {
			return {
				active: false,
				data: {},
				config: {
					formSchema: [],
					formData: {},
					submitButtonText: 'Submit'
				}
			};
		},

		methods: {
			/**
			 * @name open
			 * @description Show dialog
			 * @param {Object} config
			 * @param {Array} config.formSchema Schema for creating form layout
			 * @param {Object} config.formData Data for populating form
			 * @param {String} config.title Dialog title
			 * @param {String} config.action Action to be dispached after form submission
			 */
			open(config) {
				this.active = true;
				this.config = config;
				this.setFormValues(config.formData);
				// TODO Ilgaz check why next tick is required
				this.$nextTick().then(() => this.$refs.dynamicForm.resetValidation());
			},

			/**
			 * @name setFormValues
			 * @description Iterates the schema and creates the form object with default values and then overrides with actual values
			 */
			setFormValues(formData) {
				this.data = {};

				this.config.formSchema.map((field) => {
					if (typeof field.valueDefaultLazy !== 'undefined') {
						this.$set(this.data, field.name, field.valueDefaultLazy);
					}

					if (typeof field.ageDefaultLazy !== 'undefined') {
						const dateOfBirth = moment().subtract(field.ageDefaultLazy, 'years').format('DD/MM/yyyy');
						this.$set(this.data, field.name, dateOfBirth);
					}
				});

				if (!isEmpty(formData)) {
					Object.keys(formData).map((key) => {
						if (formData[key] !== null || typeof this.data[key] === 'undefined') {
							this.$set(this.data, key, formData[key]);
						} else {
							this.$set(formData, key, this.data[key]);
						}
					});
				}
			},

			/**
			 * @name validateForm
			 * @description Validate dynamic form manually
			 * @param {Array} formSchema Schema for creating form layout
			 * @param {Object} formData Data for populating form
			 * @returns {Boolean} Validation result
			 */
			validateForm(formSchema, formData) {
				let vm = this;

				vm.config.formSchema = formSchema;
				this.data = formData;
				return vm.$nextTick().then(() => vm.$refs.dynamicForm.validate().then((isValid) => isValid));
			},

			/**
			 * @name submit
			 * @description Submit handler for dynamic form
			 * @param {Object} formData Data to be submitted
			 */
			submit(formData) {
				this.active = false;

				this.$emit('dynamic-form-submit', {
					data: formData,
					dataIndex: this.config.dataIndex,
					config: this.config
				});
			},

			closeDialog() {
				this.active = false;
				this.$emit('closed', this.config);
			}
		}
	};
</script>

<style scoped>
	.v-app-bar {
		background-size: cover;
	}

	.header {
		font-size: 1.1rem;
		line-height: 1.5;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: normal;
	}

	.footer {
		background-color: #f4f4f4;
	}
</style>
