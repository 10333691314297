<!--
	@name app-air-club
	@description Air Club page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-2 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink">
					<div class="align-self-center image-content">
						<img class="brand-logo" :src="'logo/brand/air_club_logo_neg.svg' | urlize" />
					</div>
				</v-col>
				<v-col class="grow text-right">
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/account" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Dashboard</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-row no-gutters class="ma-n3 pa-3 pt-10">
			<v-col cols="8">
				<div class="border-effect border-effect--lg ml-6">
					<p v-if="tier.membership" class="text-h5 mb-2 font-weight-regular">
						Your current membership tier is <span class="font-weight-bold">{{ tier.membership }}</span>
						<v-chip
							v-if="tierStatus.text"
							:color="tierStatus.color"
							dark
							label
							x-small
							class="elevation-2"
							style="position: relative; left: 8px; top: -4px"
							@click="$refs.tierStatusDialog.open()"
						>
							{{ tierStatus.text }}
						</v-chip>
					</p>
					<p v-else class="text-h5 mb-2 font-weight-regular">
						Join Air Club for <span class="font-weight-bold">FREE</span> today!
					</p>

					<p class="text-body-1 mb-0">
						{{ contentClub.tierStatusDescription }}
					</p>

					<app-air-club-register-button v-if="!tier.membership" class="mt-4 mb-2" />
				</div>
			</v-col>
			<v-col cols="4" class="pr-6 text-right">
				<a
					v-if="recommendations.length"
					:class="['text-body-1', 'font-weight-medium', { 'warning--text': outstandingRecommendations, 'grey--text': !outstandingRecommendations }]"
					@click="$refs.recommendationsDialog.open()"
				>
					Recommendations
					<v-badge
						:color="outstandingRecommendations ? 'warning' : 'grey'"
						:content="outstandingRecommendations.toString()"
						inline
						style="position: relative; bottom: 2px"
					/>
				</a>
			</v-col>
		</v-row>

		<v-row no-gutters class="ma-n3 pt-3 pb-6 px-6" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
			<v-col cols="12">
				<div id="my-scrollbar" class="d-flex pa-0" style="overflow: auto; width: 100%">
					<v-card v-for="(benefit, index) in benefits" :key="index" elevation="0" width="300" class="flex-grow-1 ma-3 card--design1" @click="benefitDialogOpen(benefit)">
						<v-card-title class="text-body-1 pb-1">
							{{ benefit.title }}
						</v-card-title>
						<v-card-text>
							<p class="text-body-2 pb-0 mb-0 text-no-wrap text-truncate">
								{{ benefit.subtitle }}
							</p>
						</v-card-text>
					</v-card>
				</div>
			</v-col>
		</v-row>

		<v-row>
			<v-col class="py-0" cols="12" md="8">
				<v-alert border="left" colored-border color="#1b78d8" class="pa-5 pl-6 mt-6 mb-0">
					<v-chip color="accent" label small class="font-weight-medium black--text" style="position: absolute; top: -8px; right: -8px">
						New
					</v-chip>
					<p class="text-h6 font-weight-bold mb-2">
						Completions report
					</p>
					<p class="text-body-2 mb-0">
						{{ contentClub.completionReport }}
					</p>
					<v-simple-table dense class="elevation-3 mt-4 table--styled">
						<template #default>
							<thead>
								<tr>
									<th class="text-left">
										Client
									</th>
									<th class="text-left">
										Provider
									</th>
									<th class="text-left">
										Route
									</th>
									<th class="text-left">
										Loan size
									</th>
									<th class="text-right">
										Date
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="completion.completed">
									<td>{{ completion.data.client }}</td>
									<td>{{ completion.data.provider }}</td>
									<td>{{ completion.data.route }}</td>
									<td>£{{ completion.data.amount | numFormat('0,00') }}</td>
									<td class="text-right">
										{{ completion.completed | moment('MMM Do YYYY') }}
									</td>
								</tr>
								<tr v-else>
									<td colspan="5">
										You currently have 0 completions. <router-link to="/source">
											Click here
										</router-link> to get started with Air Club today.
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<v-btn color="primary" class="mt-5" @click="completionsReportDialogOpen">
						Open full report
					</v-btn>
				</v-alert>

				<!-- Tab view -->
				<common-base-tab-view :tabs="tabs" router-view />
			</v-col>

			<v-col class="py-0" cols="12" md="4">
				<app-air-club-my-club class="pt-6" />
				<common-base-downloads-section v-if="downloadsByTag.length" :items="downloadsByTag" :number-of-items="3" :tags="'Club'" class="pt-6">
					<template #copy>
						<p class="text-body-2">
							Below is a selection of our most popular members-only downloads.
							<router-link to="/resources">
								Visit the Downloads section
							</router-link>
							to browse our full offering.
						</p>
					</template>
				</common-base-downloads-section>
			</v-col>
		</v-row>

		<common-dialog ref="recommendationsDialog">
			<template #body>
				<div class="border-effect border-effect--grey">
					<p class="text-h6 font-weight-medium mb-1">
						Recommendations ({{ outstandingRecommendations }})
					</p>
					<p class="text-body-2 font-weight-regular mb-6">
						Earn more as you grow. There's so much more to gain by working with Air, your club membership is just the start...
					</p>
				</div>
				<v-card class="mx-auto" elevation="0">
					<v-list color="grey lighten-2" class="pa-0">
						<v-list-item-group v-model="activeRecommendations" active-class="pink--text" multiple>
							<template v-for="(recommendation, index) in recommendations">
								<v-list-item
									:key="recommendation.title"
									:value="recommendation.id"
									:disabled="activeRecommendations.includes(recommendation.id)"
									@click="recommendationUpdate(recommendation.id)"
								>
									<template #default="{ active }">
										<v-list-item-content>
											<v-list-item-title>
												{{ recommendation.title }}
											</v-list-item-title>
										</v-list-item-content>

										<v-list-item-action>
											<v-icon v-if="!active" color="grey lighten-1">
												mdi-check-bold
											</v-icon>

											<v-icon v-else color="success">
												mdi-check-bold
											</v-icon>
										</v-list-item-action>
									</template>
								</v-list-item>

								<v-divider v-if="index < recommendations.length - 1" :key="index" />
							</template>
						</v-list-item-group>
					</v-list>
				</v-card>
			</template>
		</common-dialog>

		<common-dialog ref="tierStatusDialog">
			<template #body>
				<div class="border-effect border-effect--grey">
					<p class="text-h6 mb-2 font-weight-light">
						<span class="font-weight-regular">What is meant by</span> <span class="font-weight-bold">{{ tier.membership }}</span>
						<v-chip
							v-if="tierStatus.text"
							:color="tierStatus.color"
							dark
							label
							x-small
							style="position: relative; left: 8px; top: -4px"
							@click="$refs.tierStatusDialog.open()"
						>
							{{ tierStatus.text }}
						</v-chip>
					</p>
					<div class="text-body-2 font-weight-regular last--child-mb-0" :parse-delay="100">
						<common-base-dynamic-content :html-content="contentClub.tierStatusDialog" />
					</div>
				</div>
			</template>
		</common-dialog>

		<common-dialog ref="benefitDialog">
			<template #header>
				{{ benefit.title }}
			</template>
			<template #body>
				<div class="text-body-1 last--child-mb-0" v-html="$sanitize(benefit.description)" />
				<v-btn v-if="benefit.cta" color="primary" class="mt-5" @click="handleAction(benefit.cta)">
					{{ benefit.cta.text }}
				</v-btn>
			</template>
		</common-dialog>

		<common-dialog-completions-report ref="completionsReportDialog" :completion="completion" />
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { EventBus } from '@/utils';
	import { orderBy } from 'lodash';
	import CommonBaseDownloadsSection from '@/component/common/base/downloads-section';
	import CommonBaseTabView from '@/component/common/base/tab-view';
	import CommonDialog from '@/component/common/dialog';
	import CommonBaseDynamicContent from '@/component/common/base/dynamic-content';
	import AppAirClubMyClub from '@/component/app/air-club/my-club';
	import AppAirClubRegisterButton from '@/component/app/air-club/register-button';
	import CommonDialogCompletionsReport from '@/component/common/dialog/completions-report';
	import Scrollbar from 'smooth-scrollbar';
	import { easeOutExpo } from 'es6-easing';

	export default {
		name: 'app-air-club',

		components: {
			CommonBaseDownloadsSection,
			CommonBaseTabView,
			CommonDialog,
			CommonBaseDynamicContent,
			AppAirClubMyClub,
			AppAirClubRegisterButton,
			CommonDialogCompletionsReport
		},

		data() {
			return {
				benefit: {},
				activeRecommendations: [],
				recommendations: [],
				interval: null,
				scrollbar: null,
				count: 0
			};
		},

		computed: {
			...mapState('Account', ['completion', 'user']),
			...mapState('CmsSite', ['site']),
			...mapGetters('Account', ['membership', 'tier']),
			...mapGetters('CmsDownload', ['downloads']),
			...mapGetters('CmsContent', ['contentClub']),

			downloadsByTag() {
				let tag = 'Club';
				if (this.tier.id == 'select' && !this.membership('select').bypass) tag = 'Select';
				return orderBy(this.downloads(tag), 'created', 'desc').slice(0, 4);
			},

			tabs() {
				let tabs = [
					{ title: 'Products', name: 'airClub.products', alerts: 0, path: '/club/products' },
					{ title: 'Providers', name: 'airClub.providers', alerts: 0, path: '/club/providers' }
				];

				// Show deals if select or club approved
				if (this.membership('club').approve || this.tier.id == 'select') tabs.push({ title: 'Deals', name: 'airClub.deals', alerts: 0, path: '/club/deals' });

				return tabs;
			},

			tierStatus() {
				if (this.tier.id == 'select') return this.membership('select').bypass ? { color: 'error', text: 'Inactive' } : { color: 'success', text: 'Active' };

				// Club, but not approved
				if (!this.membership('club').approve) return { color: 'blue-grey', text: 'Pending' };

				// Academy boost
				if (this.membership('academy').data?.graduated) return { color: 'success', text: 'Professional' };

				return { color: 'warning ', text: 'Standard' };
			},

			benefits() {
				return this.contentClub?.benefits?.filter((benefit) => benefit.active);
			},

			outstandingRecommendations() {
				return this.recommendations.length - this.activeRecommendations.length;
			}
		},

		watch: {
			'$route.name': {
				handler(name) {
					if (name === 'airClub') this.$router.push('/club/products');
				},
				deep: true,
				immediate: true
			}
		},

		created() {
			this.loadDownloadsList();
			this.getUserRecommendation(this.user.id).then(({ data }) => {
				this.recommendations = data[this.tier.id || 'none'];
				this.activeRecommendations = this.recommendations.filter((recommendation) => recommendation.user_selected).map((recommendation) => recommendation.id);
			});
			EventBus.$on('toggle-air-select-bypass', this.airSelectToggleChanged);
		},

		mounted() {
			this.initScrollbar();
		},

		destroyed() {
			EventBus.$off('toggle-air-select-bypass', this.airSelectToggleChanged);
		},

		methods: {
			...mapActions('CmsDownload', ['loadDownloadsList']),
			...mapActions('AccountUserRecommendation', ['getUserRecommendation', 'addUserRecommendation', 'deleteUserRecommendation']),

			initScrollbar() {
				this.scrollbar = Scrollbar.init(document.querySelector('#my-scrollbar'), {
					alwaysShowTracks: true,
					renderByPixels: true
				});

				this.scrollbar.scrollLeft = 750;

				setTimeout(() => {
					this.scrollbar.scrollTo(0, 0, 750, { easing: easeOutExpo });
				}, '500');

				this.interval = setInterval(this.autoScroll, 5000);
			},

			autoScroll() {
				let newPosX = this.count === 0 ? this.count : Math.max(this.count, Math.ceil(this.scrollbar.offset.x / 324)) * 324;
				let duration = newPosX === 0 ? 750 : 500;
				this.scrollbar.scrollTo(newPosX, 0, duration, {
					easing: easeOutExpo
				});
				this.count = newPosX / 324 + 1;
				if (newPosX >= this.scrollbar.limit.x) this.count = 0;
			},

			mouseEnter() {
				clearInterval(this.interval);
			},

			mouseLeave() {
				this.interval = setInterval(this.autoScroll, 5000);
			},

			benefitDialogOpen(benefit) {
				this.benefit = benefit;
				this.$refs.benefitDialog.open();
			},

			recommendationUpdate(id) {
				this.addUserRecommendation({
					uid: this.user.id,
					payload: {
						recommendationId: id
					}
				});
			},

			/**
			 * @name handleAction
			 * @description execute correct action
			 * @param action item action
			 */
			handleAction(action) {
				if (action.path) {
					try {
						if (new URL(action.path)) window.open(action.path, action.target ? action.target : '_self');
					} catch (e) {
						this.$router.push(action.path);
					}
				}
			},

			airSelectToggleChanged() {
				if (this.$route.name !== 'airClub.products') this.$router.push('/club');
			},

			completionsReportDialogOpen() {
				this.$refs.completionsReportDialog.open();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.last--child-mb-0 {
		::v-deep p:last-child {
			margin-bottom: 0px;
		}
	}

	#my-scrollbar {
		::v-deep .scroll-content {
			display: flex;
			padding-bottom: 20px;
		}

		::v-deep .scrollbar-thumb {
			background: rgba(71, 0, 72, 0.25);
			background: linear-gradient(135deg, rgba(71, 0, 72, 0.85) 0%, rgba(27, 120, 216, 0.85) 100%);
		}
	}

	.image-content {
		font-size: 0;
	}

	.brand-logo {
		height: 40px;
	}
</style>
