<!--
	@name common-form-section
	@description Common form section
	@date 2022/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="d-flex mb-6">
		<common-structure-section class="flex-grow-1 elevation-2">
			<template #header>
				<slot name="header">
					{{ title }}
				</slot>
			</template>

			<template #body>
				<dynamic-form ref="dynamicForm" :form-schema="form" :form-data="item" :submit-button="false" @file-upload-in-progress="fileUploadProgressHandler" @select-handler="selectHandler" />
			</template>
		</common-structure-section>

		<!-- <v-card v-else class="flex-grow-1">
			<v-skeleton-loader type="list-item-three-line" />
		</v-card> -->
	</div>
</template>

<script>
	import CommonStructureSection from '@/component/common/structure/section';
	import DynamicForm from '@/component/common/base/dynamic-form';
	import { camelCase, debounce } from 'lodash';

	export default {
		name: 'common-form-section',

		components: {
			CommonStructureSection,
			DynamicForm
		},

		props: {
			title: { type: String, required: true, default: '' },
			form: { type: Array, required: true, default: () => [] },
			item: { type: Object, required: true, default: () => ({}) }
		},

		watch: {
			item: {
				handler() {
					debounce((vm) => vm.validateForm(true), 500)(this);
				},
				deep: true
			}
		},

		methods: {
			/**
			 * @name validateForm
			 * @description Validates form and sets result to store
			 */
			async validateForm(silent) {
				if (!this.$refs || !this.$refs.dynamicForm) return;
				const isValid = await this.$refs.dynamicForm.validate(false, silent);
				const key = camelCase(this.title);
				this.$emit('set-validation', { [key]: isValid });
			},

			fileUploadProgressHandler(value) {
				this.$emit('file-upload-in-progress', value);
			},

			selectHandler() {
				this.$emit('select-handler');
			}
		}
	};
</script>
