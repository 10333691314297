import axios from 'axios';
import { $http } from '@/utils';

const PING_TIMEOUT = 90000;
const MAX_RECONNECT_ATTEMPTS = 5;
const RECONNECT_INTERVAL = 1000;

const WebSocketPlugin = {
	install(Vue) {
		let ws = null;
		let pingInterval = null;
		let reconnectAttempts = 0;

		// Establish a new WebSocket connection
		const connectWebSocket = () => {
			ws = new WebSocket(process.env.VUE_APP_WS_URL);

			ws.onmessage = onMessage;
			ws.onerror = onError;
			ws.onclose = onClose;
			ws.onopen = onOpen;
		};

		// Handle WebSocket opening
		const onOpen = () => {
			console.log(`[websocket] Connected to ${process.env.VUE_APP_WS_URL} - ${new Date()}`);
			send({ type: 'init' });

			// Clear the ping interval if it exists
			if (pingInterval) clearInterval(pingInterval);

			// Send a ping every 90 seconds to keep the connection alive
			pingInterval = setInterval(() => {
				send({ type: 'ping' });
			}, PING_TIMEOUT);
		};

		// Close the WebSocket connection
		const closeWebSocket = () => {
			if (ws) ws.close();
		};

		// Handle incoming WebSocket messages
		const onMessage = async(event) => {
			try {
				const message = JSON.parse(event.data);

				if (message.status === 200) {
					Vue.prototype.$websocket.$emit('message', message);
				} else if (message.status === 401) {
					await refreshToken();
					send({ type: 'ping' });
				} else if (message?.reconnect) {
					reconnect();
				} else if (message.status === 410) {
					console.warn(`[websocket] Error: 410 - ${message}`);
				} else {
					console.error(`[websocket] Error: ${event.data}`);
				}
			} catch (e) {
				console.error('Failed to parse message data as JSON:', event.data);
			}
		};

		// Handle WebSocket errors
		const onError = (error) => {
			console.error('WebSocket error:', error);
			Vue.prototype.$websocket.$emit('error', error);
		};

		// Handle WebSocket closure
		const onClose = (event) => {
			console.warn(`WebSocket connection closed: - ${new Date()}`, event);
			Vue.prototype.$websocket.$emit('close', event);
			if (pingInterval) clearInterval(pingInterval);

			reconnect();
		};

		// Attempt to reconnect the WebSocket
		const reconnect = () => {
			if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
				const timeout = RECONNECT_INTERVAL * Math.pow(2, reconnectAttempts);
				setTimeout(() => {
					reconnectAttempts++;
					connectWebSocket();
				}, timeout);
			} else {
				console.warn(`WebSocket failed to reconnect after ${MAX_RECONNECT_ATTEMPTS} attempts.`);
			}
		};

		// Send a message through the WebSocket
		const send = (message) => {
			if (!ws || ws.readyState !== WebSocket.OPEN) {
				console.log(`[websocket] Error: Connection not open`);
				return;
			}

			console.log(`[websocket] Message sent: ${JSON.stringify(message)}`);
			message.authorization = window.localStorage.getItem('air.authorization');
			ws.send(JSON.stringify(message));
		};

		// Refresh the access token
		const refreshToken = () => {
			return axios
				.post(`${process.env.VUE_APP_API_URL}/account/refresh`, {
					token: window.localStorage.getItem('air.refreshToken')
				})
				.then(({ data }) => {
					$http.setToken(data.accessToken, data.refreshToken);
				})
				.catch((e) => {
					if (e.response?.status === 403 || e.response?.config?.url.includes('account/refresh')) {
						$http.forceLogin();
					}
					return Promise.reject(e.response || e);
				});
		};

		window.addEventListener('beforeunload', () => closeWebSocket());

		Vue.prototype.$sendMessage = send;
		Vue.prototype.$connectWebSocket = connectWebSocket;
		Vue.prototype.$closeWebSocket = closeWebSocket;
		Vue.prototype.$websocket = new Vue();
	}
};

export default WebSocketPlugin;
