import Vue from 'vue';
import { PartnerDocebo } from '@/service';

function _ringCourses(state, name, type = 'courses') {
	let ring = state.rings.find((r) => r.name === name);
	let courses = state.courses.filter((c) => ring[type].includes(c.id_course));
	return courses;
}

/**
 * @class @name Webinar
 * @description Exposes menu store module
 */
export default class Webinar {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			authStatus: null,
			courses: [],
			catalogs: {},
			rings: [
				{
					name: 1,
					teasers: [4, 190],
					courses: [4, 9, 13, 14, 15, 16, 17, 18, 118, 190]
				},
				{
					name: 2,
					teasers: [258],
					courses: [171, 172, 173, 174, 216, 217, 218, 219, 220]
				},
				{
					name: 3,
					teasers: [230],
					courses: [/*222, 223, 224, 225, 226, 227, 228, 229, */230, 231, 232, 234, 235, 236, 237, 238, 239, 240, 241]
				},
				{
					name: 4,
					teasers: [242],
					courses: [242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 278]
				}
			]
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name ringOneCourses
			 * @description TODO
			 * @return {Array}
			 */
			ringOneCourses: (state) => {
				return _ringCourses(state, 1, 'courses');
			},

			/**
			 * @name getRingEnrolledCourseIds
			 * @description TODO
			 * @return {Array}
			 */
			getRingEnrolledCourseIds: (state) => (id) => {
				return state.rings.find((r) => r.name === id)?.courses || [];

			},

			/**
			 * @name getRingEnrolled
			 * @description TODO
			 * @return {Array}
			 */
			getRingEnrolled: (state) => (id) => {
				let ring = state.rings.find((r) => r.name === id);
				if (!ring) return [];
				let courses = state.courses.filter((c) => ring.courses.includes(Number(c.id_course)));
				return courses;
			},

			/**
			 * @name getRingTeasers
			 * @description TODO
			 * @return {Array}
			 */
			getRingTeasers: (state) => (id, catalogId) => {
				let ring = state.rings.find((r) => r.name === id);
				let catalog = state.catalogs[catalogId];
				if (!ring) return [];
				if (!catalog) return [];

				// Only display teasers that we have hardcoded as being included
				let items = catalog.filter((c) => ring.teasers.includes(Number(c.item_id)));
				// Mapping required for correct dispaly in UI component
				let teasers = items.map((x) => {
					x.name = x.item_name;
					x.id_course = x.item_id;
					x.slug_name = x.item_slug;
					x.image = x.item_thumbnail;
					// Am I enrolled?
					let enrolledCourse = state.courses.find((y) => y.id_course == x.id_course);
					if (enrolledCourse) Vue.set(x, 'enrollment_info', enrolledCourse.enrollment_info);
					return x;
				});
				return teasers;
			},

			/**
			 * @name getCatalogWithEnrolment
			 * @description TODO
			 * @return {Array}
			 */
			getCatalogWithEnrolment: (state) => (ringId, catalogId) => {
				let ring = state.rings.find((r) => r.name === ringId);
				let catalog = state.catalogs[catalogId];
				if (!ring) return [];
				if (!catalog) return [];
				// Only display courses that we have hardcoded as being included
				let items = catalog.filter((c) => ring.courses.includes(Number(c.item_id)));
				// Mapping required for correct dispaly in UI component
				let courses = items.map((x) => {
					x.name = x.item_name;
					x.id_course = x.item_id;
					x.slug_name = x.item_slug;
					x.image = x.item_thumbnail;
					// Am I enrolled?
					let enrolledCourse = state.courses.find((y) => y.id_course == x.id_course);
					if (enrolledCourse) Vue.set(x, 'enrollment_info', enrolledCourse.enrollment_info);
					return x;
				});
				return courses;
			}
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setAuthStatus
			 * @param {Object} state
			 * @param {Object} data
			 */
			setAuthStatus(state, data) {
				state.authStatus = data;
			},

			/**
			 * @name setCourses
			 * @param {Object} state
			 * @param {Object} data
			 */
			setCourses(state, data) {
				state.courses = data;
			},

			/**
			 * @name setCatalogs
			 * @param {Object} state
			 * @param {Object} data
			 */
			setCatalogs(state, data) {
				state.catalogs = data;
			},

			/**
			 * @name setCourses
			 * @param {Object} state
			 * @param {Object} payload
			 */
			setCatalogContent(state, payload) {
				Vue.set(state.catalogs, payload.catalogId, payload.data);
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name getCourseList
			 * @param {function} commit
			 */
			async getCourseList({ commit }) {
				commit('setAuthStatus', null);
				commit('setCourses', []);
				return PartnerDocebo.compactList()
					.then(({ data }) => {
						commit('setAuthStatus', 'success');
						commit('setCourses', data.data.items);
					})
					.catch((error) => {
						commit('setAuthStatus', 'error');
						throw error;
					});
			},

			/**
			 * @async @name getCatalogContent
			 * @param {function} commit
			 */
			async getCatalogContent({ commit }, catalogId) {
				commit('setCatalogs', {});
				return PartnerDocebo.catalogContent(catalogId)
					.then(({ data }) => commit('setCatalogContent', { catalogId, data: data.data.items[0].data.sub_items }))
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name updateUser
			 * @param {function} commit
			 */
			// eslint-disable-next-line no-unused-vars
			async updateUser({ commit }, data) {
				return PartnerDocebo.updateUser(data.id, data.payload)
					.catch((error) => {
						throw error;
					});
			},

			/**
			 * @async @name setAuthStatus
			 * @param {function} commit
			 */
			async setAuthStatus({ commit }, val) {
				commit('setAuthStatus', val);
			},

			/**
			 * @async @name setCourses
			 * @param {function} commit
			 */
			async setCourses({ commit }, val) {
				commit('setCourses', val);
			}
		};
	}
}
