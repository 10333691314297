<!--
	@name app-client-property-dialog-apply
	@description Proceed to KFI Application
	@date 2020/07/29
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-dialog ref="dialog">
			<template #header>
				Proceed to Application
			</template>

			<template #body>
				<div v-if="loading" class="text-center">
					<v-progress-circular :size="70" :width="7" color="blue-grey" indeterminate />
				</div>

				<div v-else-if="error" class="text-center">
					<v-icon class="text-h1" color="error">
						mdi-exclamation
					</v-icon>

					<v-card-title class="text-h6 d-block pb-5">
						<span v-if="refreshToken">
							An error occurred
						</span>
						<span v-else>
							An unexpected error occurred
						</span>
					</v-card-title>

					<v-card-title v-if="message" class="text-subtitle-1 d-block pt-0">
						{{ message }}
					</v-card-title>

					<v-btn v-if="refreshToken" :loading="updateLoading" color="primary" @click="updateCredentials">
						Update credentials
					</v-btn>
				</div>

				<div v-else-if="!applicationAvailable">
					<p class="body-1 text-center mb-0">
						Online application for this KFI is no longer available from your {{ site.softwareName }} account. Please refer directly to the lender in order to progress
						to application.
					</p>
				</div>

				<div v-else-if="site && product">
					<p class="body-1 text-center">
						Online application is not yet available directly from your {{ site.softwareName }} account. However, you can use the option{{
							onlineAndPaperApplication ? 's' : ''
						}}
						below to proceed to application with {{ product.provider.name }} and update your {{ site.softwareName }} records.
					</p>

					<p v-if="onlineAndPaperApplication" class="subtitle-1 text-center">
						There are two ways in which you can apply for this Product.
					</p>

					<v-row>
						<v-col v-if="onlineApplication || onlineAndPaperApplication" class="grow">
							<v-card color="green lighten-5" class="flex-grow-1 elevation-3 pa-3" outlined tile>
								<v-card-text class="text-center pa-0 text-center">
									<v-card-title class="text-body-1 font-weight-bold d-block pb-5" style="word-break: normal">
										Visit the {{ product.provider.name }} website to apply online immediately
									</v-card-title>
								</v-card-text>

								<v-card-actions class="mb-2 justify-center">
									<v-btn color="primary" :loading="submitting" @click="submit('online')">
										<v-icon class="mr-2" small>
											mdi-chevron-right-circle
										</v-icon>
										Apply Online
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-col>

						<v-col v-if="paperApplication || onlineAndPaperApplication" class="grow">
							<v-card color="blue lighten-5" class="flex-grow-1 elevation-3 pa-3" outlined tile>
								<v-card-text class="text-center pa-0 text-center">
									<v-card-title class="text-body-1 font-weight-bold d-block pb-5" style="word-break: normal">
										Download a paper application and return to {{ product.provider.name }}
									</v-card-title>
								</v-card-text>

								<v-card-actions class="mb-2 justify-center">
									<v-btn color="secondary" :loading="submitting" @click="submit('paper')">
										<v-icon class="mr-2" small>
											mdi-chevron-right-circle
										</v-icon>
										Apply Online
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-col>
					</v-row>
				</div>
			</template>
		</common-dialog>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { ElementTools, PopupWindow } from '@/utils';
	import CommonDialog from '@/component/common/dialog';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import { PartnerCriterion, PartnerFinova } from '@/service';

	export default {
		name: 'app-client-dashboard-workflow-application-dialog-apply',

		components: { CommonDialog, CommonDialogConfirm },

		data() {
			return {
				kfi: null,
				error: false,
				message: null,
				submitting: false,
				loading: false,
				refreshToken: false,
				updateLoading: false,
				account: null
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),

			api() {
				if (this.product && this.product.magicLink) return true;
				return false;
			},

			onlineApplication() {
				if (this.api || (this.product && this.product.application?.processTypeId === '34decf1f-e8c2-44a5-8bb2-03c3d1727ed3')) return true;
				return false;
			},

			paperApplication() {
				if (this.product && this.product.application?.processTypeId === 'b5477f51-9672-4366-9a32-e93bb024e7e0') return true;
				return false;
			},

			onlineAndPaperApplication() {
				if (this.product && this.product.application?.processTypeId === 'b4f02109-b636-4713-8cb9-f7b92c4e94e3') return true;
				return false;
			},

			product() {
				let product = this.kfi?.product;
				if (!product) return false;
				return product;
			},

			applicationAvailable() {
				return this.api || (this.product && this.product?.id);
			}
		},

		methods: {
			...mapActions('Oauth', ['getOauthOptions']),
			...mapActions('AppMatter', ['fetchMatterList']),
			...mapActions('LifetimeMortgageApplication', ['submitApplication']),
			...mapActions('AppInterface', ['loadInterfaceList']),
			...mapActions('AppInterfaceUser', ['loadCredentialList']),

			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open(kfi) {
				this.error = false;
				this.kfi = null;
				this.message = null;

				if (!kfi) {
					this.error = true;
					this.message = 'This is a permanent error. Please contact our support team and quote error code A-Se08 for guidance.';
					return;
				}

				this.kfi = kfi;
				this.$refs.dialog.open();
			},

			/**
			 * Submit Kfi Application Confirmation
			 * @param url
			 */
			submit(applicationType) {
				this.$refs.confirm
					.open('Apply', 'Are you sure you wish to apply for this product?')
					.then(async() => {
						await this.applyForProduct(applicationType);
					})
					.catch(() => (this.message = 'An error occurred'))
					.finally(() => (this.submitting = false));
			},

			async applyForProduct(applicationType) {
				this.submitting = true;

				let url = applicationType === 'online' ? this.product?.application?.url : this.product?.application?.downloadUrl;

				if (this.api) {
					const exit = await this.getApiRedirectUrl()
						.then((response) => {
							if (!response.url) {
								this.error = true;
								this.message = response.message || 'An error occurred';
								return true;
							}
							url = response.url;
							return false;
						});
					if(exit) return; // Error received from getApiRedirectUrl
				}

				const payload = {
					processId: this.kfi.processId,
					iterationId: this.kfi.id,
					data: {
						action: {
							key: 'SubmitApplication'
						}
					}
				};

				this.submitApplication(payload)
					.then(() => {
						this.$emit('submit-application');
						this.$refs.dialog.close();
						new PopupWindow().open(url, '_blank');
					})
					.catch((error) => (this.message = error.response.data.message))
					.finally(() => (this.submitting = false));
			},

			/**
			 * @static @name getApiRedirectUrl
			 * @description Get redirect url via lenders api
			 * @returns {Object} Promise a http response
			 */
			async getApiRedirectUrl() {
				if (this.product.magicLink === 'criterion') return await this.criterionApplications(this.product.provider.nameUnique);
				else if (this.product.magicLink === 'finova') return await this.finovaMagicLink(this.product.provider.nameUnique);
			},

			/**
			 * @name retry
			 * @description retry the request
			 */
			retry() {
				this.applyForProduct('online'); // Bypasses confirm dialog used by submit()
				this.$refs.dialog.close();
			},

			/**
			 * @static @name justApplications
			 * @description Create Criterion application request
			 * @returns {Object} Promise a http response
			 */
			async criterionApplications(provider) {
				this.loading = true;
				const payload = { payload: { benefitId: this.kfi.product.caseReference } };
				return await PartnerCriterion.applications(provider, payload)
					.then((resp) => {
						return { url: resp.data?._links?.case };
					})
					// eslint-disable-next-line no-unused-vars
					.catch((err) => {
						if(err.data.tokenMissing || err.data.tokenExpired) {
							this.refreshToken = true;
							return { message: `Your ${this.product.provider.name} credentials have expired. Click Update Credentials to proceed to application.` };
						}
						return { message: 'This is a permanent error. Please contact our support team and quote error code A-Se08 for guidance.' };
					})
					.finally(() => this.loading = false);
			},

			/**
			 * @static @name finovaMagicLink
			 * @description Get finova case magic link
			 * @returns {Object} Promise a http response
			 */
			async finovaMagicLink(provider) {
				this.loading = true;
				return await PartnerFinova.magicLink(provider, this.kfi.product.caseReference)
					.then((resp) => {
						return { url: resp.data?.url || null };
					})
					.catch((err) => {
						if(err.data.tokenMissing || err.data.tokenExpired) {
							this.refreshToken = true;
							return { message: `Your ${this.product.provider.name} credentials have expired. Click Update Credentials to proceed to application.` };
						}

						return { message: err.data?.message || 'An error occurred' };
					})
					.finally(() => this.loading = false);
			},

			/**
			 * @name openAuthPopup
			 * @description Popup window to authenticate account over an external party
			 */
			async updateCredentials() {
				this.updateLoading = true;
				if (!this.account) await this.fetchInterface();
				if (this.account?.type === 'api') this.openAuthPopup();
			},

			/**
			 * @name fetchInterface
			 * @description get credentials for the provider
			 */
			async fetchInterface() {
				let { data } = await this.loadInterfaceList({ where: { 'organisation.name_unique': this.product.provider.nameUnique } });
				this.account = data.data[0];

				let { data: credential } = await this.loadCredentialList({ where: { interface_id: this.account?.id } });
				this.account.credential = credential.data[0];

				return data;
			},

			/**
			 * @name openAuthPopup
			 * @description Popup window to authenticate account over an external party
			 */
			async openAuthPopup() {
				let { data } = await this.getOauthOptions({ partner: this.account.config.partner, providerNameUnique: this.account.organisationNameUnique, domain: this.site.name });

				let payload = {
					provider: data.provider,
					authUrl: data.url,
					authPopupOptions: this.account.config.authPopupOptions
				};

				new PopupWindow().openAuthPopup(
					payload,
					({ data }) => {
						if (data[payload.provider]) {
							ElementTools.fireNotification(this.$el, 'success', `Your ${this.account.organisationNameUnique} credentials has been updated successfully`);
							this.retry();
							this.closeDialog();
						} else ElementTools.fireNotification(this.$el, 'error', data.message);
					},
					({ error }) => {
						ElementTools.fireNotification(this.$el, 'error', error);
						this.$set(this.isLoading, this.account.organisationName, false);
					}
				);

				this.updateLoading = false;
			}
		}
	};
</script>
