<!--
	@name common-dialog-version-update
	@description Version Update Dialog
	@date 2024/01/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name" persistent :show-close="false" :max-width="550">
		<template #header>
			<span>System Update Required</span>
		</template>
		<template #body>
			<div>
				<p class="text-body-1">
					A system update is required. Refresh the page or click the button below to update to the latest version.
				</p>
				<v-btn color="primary" class="mt-2" @click="update">
					Update to latest version
				</v-btn>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'common-dialog-version-update',

		components: { CommonDialog },

		// props: { tutorial: { type: Object, required: true } },

		data() {
			return {
				versionCurrent: null,
				versionNew: null
			};
		},

		methods: {
			open(version) {
				this.versionNew = version;
				this.versionCurrent = process.env.VUE_APP_VERSION;
				this.$refs.dialog.open();
			},

			update() {
				window.location.reload();
			}
		}
	};
</script>

<style lang="scss" scoped>
	video {
		display: block;
		max-width: 100%;
	}
</style>
