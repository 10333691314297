import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Process
 * @exports Service/Process
 * @description API Process Service
 * @date 2022/05/16
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Process {
	/**
	 * @static @name get
	 * @description Add a new Process resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`process/${id}`);
	}

	/**
	 * @static @name list
	 * @description Get all assets
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static list(payload) {
		return $http.post('process/list', payload);
	}

	/**
	 * @static @name add
	 * @description Add a new Process resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	static add(payload) {
		return $http.post('process', payload);
	}
}
