import Firm from '@/component/app/firm';
import Accounts from '@/component/app/firm/account-list';
import Detail from '@/component/app/firm/details';
import TradingStyles from '@/component/app/firm/trading-styles';
import Domains from '@/component/app/firm/domains';
import Webhooks from '@/component/app/firm/webhooks';
// import FirmCompleteRegistration from '@/component/app/firm/complete-registration';
// import FirmAcceptInvitation from '@/component/app/firm/accept-invitation';

/**
 * @namespace Router
 * @exports Router
 * @description Routes for system
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default [
	{
		name: 'myCompany',
		path: '/firm',
		component: Firm,
		meta: { layout: 'default-authed', requiresSiteCheck: true },
		beforeEnter(to, from, next) {
			if (to.name === 'myCompany') next('/firm/accounts');
			else next();
		},
		children: [
			{
				name: 'myCompany.accounts',
				path: 'accounts',
				meta: { layout: 'default-authed' },
				component: Accounts
			},
			{
				name: 'myCompany.details',
				path: 'details',
				meta: { layout: 'default-authed' },
				component: Detail
			},
			{
				name: 'myCompany.tradingStyles',
				path: 'trading-styles',
				meta: { layout: 'default-authed' },
				component: TradingStyles
			},
			{
				name: 'myCompany.domains',
				path: 'domains',
				meta: { layout: 'default-authed' },
				component: Domains
			},
			{
				name: 'myCompany.webhooks',
				path: 'webhooks',
				meta: { layout: 'default-authed' },
				component: Webhooks
			}
		]
	} // ,
	// { path: '/accept-terms', name: 'acceptTerms', meta: { layout: 'default-minimal' }, component: FirmCompleteRegistration },
	// {
	// 	path: '/complete-registration/:token',
	// 	name: 'completeRegistration',
	// 	meta: { layout: 'default-minimal' },
	// 	component: FirmCompleteRegistration,
	// 	props: true
	// },
	// { path: '/accept-invitation/:uuid/:userId/:invitationId/:expiry', name: 'acceptInvitation', meta: { layout: 'default-minimal' }, component: FirmAcceptInvitation, props: true }
];
