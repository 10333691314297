<!--
	@name app-air-academy-cpd
	@description Air Academy page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-section>
			<template #header>
				<slot> CPD Log </slot>
			</template>

			<template #body>
				<v-row no-gutters>
					<v-col class="grow pr-6">
						<p class="text-body-2 mb-5">
							It is crucial to capture & evidence your ongoing professional development as an adviser. Air Academy captures this for you below:
						</p>
						<v-btn color="primary" small @click="active = true">
							access personal CPD log
						</v-btn>
					</v-col>
					<v-col class="shrink">
						<v-tooltip bottom>
							<template #activator="{ on }">
								<v-sheet class="pa-3 grey lighten-2 rounded text-center" v-on="on">
									<p class="text-h3 font-weight-bold text-center mb-1">
										{{ totalCpdHours }}
									</p>
									<p class="text-caption plain nowrap mb-0">
										Total CPD hours
									</p>
								</v-sheet>
							</template>
							<span>Logged since 1st January {{ Date.now() | moment('YYYY') }}</span>
						</v-tooltip>
					</v-col>
				</v-row>
			</template>
		</common-structure-section>

		<v-dialog v-model="active" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
			<v-card tile>
				<v-toolbar flat dark class="pl-3 pr-3 flex-grow-0 generic-gradient">
					<v-row>
						<v-col cols="12" md="2" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								CPD
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-col cols="12" md="2" class="d-flex pa-0 justify-end">
							<v-btn icon dark class="align-self-center" @click="active = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-toolbar>
				<v-card-text>
					<v-container class="px-0 pb-10">
						<v-row class="mb-6">
							<v-col cols="7" class="pr-10">
								<p class="text-h6 mb-3">
									Personal CPD Log
								</p>
								<p class="text-body-1 mb-3">
									Air Academy's CPD log offers a seamless way to record your continuous professional development, evidencing your dedication to staying current in
									the constantly evolving financial landscape. It dynamically updates with any CPD you engage with within the Academy, while also allowing you to
									include external learning sources for a comprehensive overview of your development progress. Each learning activity is time-stamped for
									documentation, and editable fields are provided for capturing notes or reflections alongside each entry.
								</p>
							</v-col>
							<v-col cols="5">
								<v-skeleton-loader v-if="!testimonial.name" type="card" class="pa-0 ma-0" />
								<app-air-academy-training-webinar-item v-else :item="testimonial" @play-video="playVideo" />
								<app-dialog-video-player ref="videoDialog" />
							</v-col>
						</v-row>
						<v-divider inset class="my-10" />
						<v-row class="mb-6">
							<v-col cols="12">
								<v-sheet class="mb-4">
									<v-btn color="primary" @click="addItem">
										Add new log item
									</v-btn>
									<v-btn class="ml-2" color="secondary" @click="print">
										print
									</v-btn>
								</v-sheet>

								<v-data-table
									class="table--styled elevation-3"
									:headers="headers"
									:items="visibleCpdTrainingItems"
									:options.sync="options"
									:server-items-length="options.total"
									:loading="loading"
									:footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
								>
									<template #progress>
										<v-progress-linear indeterminate />
									</template>
									<template #item="{ item }">
										<tr>
											<td>
												{{ item.name || 'Name missing' }}
												<a v-if="!item.reflection" class="text-caption plain d-block error--text mt-1 mb-0" @click="editItem(item)">
													<v-icon color="error" size="16" left>
														mdi-alert
													</v-icon>Add reflection log
												</a>
											</td>
											<td>{{ convertTime(item.points) }}</td>
											<td>
												{{ item.issued | moment('MMM Do YYYY') }}
											</td>
											<td class="text-right shrunk">
												<v-tooltip bottom content-class="text-center" max-width="250">
													<template #activator="{ on }">
														<v-btn color="primary" icon :disabled="deleting[item.id]" @click="editItem(item)" v-on="on">
															<v-icon v-on="on">
																mdi-pencil
															</v-icon>
														</v-btn>
													</template>
													<span> Edit item </span>
												</v-tooltip>
												<v-tooltip bottom content-class="text-center" max-width="250">
													<template #activator="{ on }">
														<v-btn color="error" icon :loading="deleting[item.id]" @click="deleteItem(item)" v-on="on">
															<v-icon> mdi-trash-can </v-icon>
														</v-btn>
													</template>
													<span> Delete this item </span>
												</v-tooltip>
											</td>
										</tr>
									</template>
								</v-data-table>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<dynamic-form-dialog ref="dynamicFormDialog" @dynamic-form-submit="submit" />
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonStructureSection from '@/component/common/structure/section';
	import DynamicFormDialog from '@/component/common/dialog/dynamic-form-dialog';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import AppAirAcademyTrainingWebinarItem from '@/component/app/air-academy/training/webinar-item';
	import AppDialogVideoPlayer from '@/component/app/dialog/video-player';
	import pdfMake from 'pdfmake/build/pdfmake';
	import pdfFonts from 'pdfmake/build/vfs_fonts';

	export default {
		name: 'app-air-academy-cpd',

		components: {
			CommonStructureSection,
			DynamicFormDialog,
			CommonDialogConfirm,
			AppAirAcademyTrainingWebinarItem,
			AppDialogVideoPlayer
		},

		data() {
			return {
				active: false,
				month: false,
				loading: false,
				options: {
					page: 1,
					itemsPerPage: 10,
					total: 0
				},
				headers: [
					{
						text: 'Name',
						align: 'start',
						sortable: false,
						value: 'name',
						cellClass: 'shrink'
					},
					{
						text: 'CPD Hours',
						align: 'start',
						sortable: false,
						value: 'points',
						cellClass: 'shrink'
					},
					{
						text: 'Date',
						align: 'start',
						sortable: false,
						value: 'issued',
						cellClass: 'shrink'
					},
					{ text: '', value: 'actions', sortable: false, class: 'shrunk actions' }
				],
				testimonial: {
					name: 'Insights Hub: On-Demand CPD',
					image: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/webinar/AcademyCPD.jpg`,
					mimetype: 'video/mp4',
					hideStatus: true,
					fixedHeight: true,
					emit: 'play-video'
				},
				deleting: {}
			};
		},

		computed: {
			...mapState('CmsCpdTrainingItem', ['cpdTrainingItems']),
			...mapGetters('CmsForm', ['form']),

			totalCpdHours() {
				const items = this.cpdTrainingItems.filter((item) => this.$moment(item.issued).format('YYYY') == this.$moment().format('YYYY'));
				const totalMinutes = items.reduce((acc, item) => acc + item.points, 0);
				return this.convertTime(totalMinutes, 0);
			},

			visibleCpdTrainingItems() {
				if (!this.cpdTrainingItems.length) return [];
				return this.cpdTrainingItems.slice(
					(this.options.page - 1) * this.options.itemsPerPage,
					this.options.page * this.options.itemsPerPage
				);
			}
		},

		created() {
			this.loadForm('academy_cpd');
			this.fetchCpdTrainingItems(true);
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsCpdTrainingItem', ['addNewCpdTrainingItem', 'modifyCpdTrainingItem', 'deleteCpdTrainingItem', 'loadCpdTrainingItems']),

			/**
			 * @name fetchCpdTrainingItems
			 * @description Fetch the CPD training items.
			 */
			fetchCpdTrainingItems(loading = false) {
				this.loading = loading;
				this.loadCpdTrainingItems({ order: { property: 'issued', direction: 'desc' } })
				.finally(() => {
					this.options.total = this.cpdTrainingItems.length;
					this.loading = false;
				});
			},

			/**
			 * @name addItem
			 * @description Open a dialog to add a new CPD training item.
			 */
			addItem() {
				this.openFormDialog();
			},

			/**
			 * @name editItem
			 * @description Open a dialog to edit a CPD training item.
			 * @param {Object} item - The CPD training item to edit.
			 */
			editItem(item) {
				const formData = { ...item, issued: this.$moment(item.issued).format('DD/MM/YYYY') };
				this.openFormDialog(formData);
			},

			/**
			 * @name openFormDialog
			 * @description Open a dialog with the provided form data and configuration.
			 * @param {Object} formData - The data to populate the form with.
			 * @param {string} submitButtonText - The text for the form's submit button.
			 * @param {string} title - The title for the form dialog.
			 */
			openFormDialog(formData = {}) {
				this.$refs.dynamicFormDialog.open({
					formSchema: this.form('academy_cpd').value,
					formData,
					submitButtonText: 'Submit',
					title: 'Log CPD'
				});
			},

			/**
			 * @name submit
			 * @description Handle the response from the form submission.
			 * @param {Object} val - The response data from the form submission.
			 */
			submit({ data }) {
				const payload = {
					...data,
					issued: this.$moment(data.issued, 'DD/MM/YYYY').toISOString()
				};

				if (data.id) {
					this.modifyCpdTrainingItem(payload)
						.then(() => {
							ElementTools.fireNotification(this.$el, 'success', 'CPD Log updated successfully');
							this.fetchCpdTrainingItems();
						})
						.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update CPD Log'));
				} else {
					this.addNewCpdTrainingItem(payload)
						.then(() => {
							ElementTools.fireNotification(this.$el, 'success', 'CPD Log added successfully');
							this.fetchCpdTrainingItems();
						})
						.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to add CPD Log'));
				}
			},

			/**
			 * @name convertTime
			 * @description Convert minutes to hours.
			 * @param {number} mins - The number of minutes to convert.
			 * @returns {number} The converted time in hours.
			 * @returns {decimal} The presions to use for decimal places.
			 */
			convertTime(mins, decimal = 2) {
				return this.$moment.duration(mins, 'minutes').asHours().toFixed(decimal);
			},

			/**
			 * @name deleteItem
			 * @description Delete a CPD training item.
			 * @param {Object} item - The CPD training item to delete.
			 */
			async deleteItem(item) {
				this.$refs.confirm
					.open('Delete item', `Are you sure you wish to delete this item?`)
					.then(() => {
						this.$set(this.deleting, item.id, true);
						return this.deleteCpdTrainingItem(item.id);
					})
					.then(() => this.fetchCpdTrainingItems())
					.catch(() => {})
					.finally(() => this.$set(this.deleting, item.id, false));
			},

			/**
			 * @name playVideo
			 * @description Open a dialog to play a video.
			 */
			playVideo() {
				this.$refs.videoDialog.open(`${process.env.VUE_APP_S3_AIR_ASSETS_URL}/webinar/AcademyCPD.mp4`);
			},

			/**
			 * @name print
			 * @description Print the CPD log.
			 */
			print() {
				pdfMake.vfs = pdfFonts.pdfMake.vfs;

				let rows = [];
				this.cpdTrainingItems.forEach((item) => {
					rows.push([
						{ text: item.name, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
						{ text: `${this.convertTime(item.points)}`, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
						{ text: this.$moment(item.issued).format('DD/MM/YYYY'), fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
						{ text: item.reflection, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] }
					]);
				});

				const docDefinition = {
					pageOrientation: 'portrait',

					info: {
						title: `Personal CPD Log - ${this.$moment().format('DD/MM/YYYY HH:mm:ss')}`,
						author: 'Air Sourcing'
					},
					content: [
						{
							alignment: 'left',
							text: `Personal CPD Log - ${this.$moment().format('DD/MM/YYYY HH:mm:ss')}`,
							style: 'header',
							fontSize: 12,
							bold: true,
							margin: [0, 10]
						},
						{
							margin: [0, 10, 0, 0],
							layout: {
								fillColor: function(rowIndex) {
									return rowIndex === 0 ? '#E6E6E6' : null;
								}
							},
							table: {
								widths: ['25%', '10%', '15%', '50%'],
								headerRows: 1,
								body: [
									[
										{ text: 'Name', bold: true, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
										{ text: 'Hours', bold: true, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
										{ text: 'Date', bold: true, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] },
										{ text: 'Reflection Log', bold: true, fontSize: 9, lineHeight: 1.2, margin: [4, 3, 0, 0] }
									],
									...rows
								]
							}
						}
					]
				};

				pdfMake.createPdf(docDefinition).download(`Personal CPD Log - ${this.$moment().format('DD-MM-YYYY')}.pdf`);
			}
		}
	};
</script>

<style lang="scss" scoped>
	td {
		&.shrunk {
			width: 1px;
			white-space: nowrap;
		}

		&.nowrap {
			white-space: nowrap;
		}
	}
</style>
