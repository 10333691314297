<!--
	@name app
	@description Main app, thats bootstrapped to html
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-app>
		<transition name="fade" mode="out-in">
			<div v-if="(!initialised || !routerLoaded || !documentLoaded) && !isExternalRoute" key="loading">
				<v-dialog :value="true" persistent width="300">
					<v-card>
						<v-card-text>
							Loading site...
							<v-progress-linear indeterminate color="primary" class="mt-2 mb-0" />
						</v-card-text>
					</v-card>
				</v-dialog>
			</div>
			<div v-else key="layout" class="fill-height">
				<component :is="layout" />
			</div>
		</transition>
		<app-notification ref="notification" />
		<common-dialog-version-update ref="versionUpdate" />
		<common-dialog ref="welcomeDialog" :max-width="800">
			<template #header>
				We've enhanced our website!
			</template>
			<template #body>
				<p class="text-body-1 mb-3">
					We've made some changes to our website, meaning that all our services will be accessible in one place. Take time to explore Air and how we can support you and
					your business.
				</p>
				<p class="text-body-1 mb-0">
					<strong>If you're looking for Air Sourcing</strong>, we've added a new login button above the navigation bar at the top right of your screen or click below for instant access.
				</p>

				<v-divider class="mt-5 mb-6" inset />

				<v-btn color="primary" @click="goToSourcing">
					continue to sourcing
				</v-btn>
				<v-btn class="ml-2" color="secondary" @click="closeWelcomeDialog">
					explore the changes
				</v-btn>
			</template>
		</common-dialog>
	</v-app>
</template>

<script>
	import Vue from 'vue';
	import { mapGetters, mapState } from 'vuex';
	import { EventBus } from '@/utils';
	import { $localStorage } from '@/utils/storage';
	import LayoutDefault from '@/component/layout/default';
	import LayoutDefaultMinimal from '@/component/layout/default-minimal';
	import LayoutDefaultAuthed from '@/component/layout/default-authed';
	import LayoutBlank from '@/component/layout/blank';
	import LayoutFactFind from '@/component/layout/fact-find';
	import LayoutSelect from '@/component/layout/select';
	import LayoutAirLaterLife from '@/component/layout/air-later-life';
	import AppNotification from '@/component/common/base/notification';
	import CommonDialog from '@/component/common/dialog';
	import CommonDialogVersionUpdate from '@/component/common/dialog/version-update';

	export default {
		name: 'app',

		components: {
			LayoutDefault,
			LayoutDefaultMinimal,
			LayoutDefaultAuthed,
			LayoutBlank,
			LayoutFactFind,
			LayoutSelect,
			LayoutAirLaterLife,
			AppNotification,
			CommonDialog,
			CommonDialogVersionUpdate
		},

		data() {
			return {
				routerLoaded: false,
				documentLoaded: false,
				isExternalRoute: false
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapState('Account', ['user']),
			...mapGetters('App', ['initialised']),

			layout() {
				return this.$route.meta && this.$route.meta.layout ? `layout-${this.$route.meta.layout}` : 'layout-blank';
			}
		},

		watch: {
			$route: {
				handler() {
					// Growthbook
					let attributes = {
						// Only update the `url` attribute, keep the rest the same
						...Vue.GrowthBook.getAttributes(),
						url: window.location.href,
						query: window.location.search
					};
					if (this.user.id) attributes.id = this.user.id;
					Vue.GrowthBook.setAttributes(attributes);

					this.routerLoaded = !!this.$route.name;
				},
				deep: true
			},

			initialised(val) {
				if (val && !$localStorage.isDialogHidden('welcome06062024') && !this.site?.allowedPublicRoutes?.includes('adviserLogIn') && !this.site?.maintenance) {
					this.$refs.welcomeDialog.open();
					$localStorage.hideDialog('welcome06062024');
				}
			}
		},

		mounted() {
			this.isExternalRoute = window._isExternalRoute;

			document.onreadystatechange = () => {
				if (document.readyState == 'complete') {
					this.documentLoaded = true;
				}
			};

			document.addEventListener('notification', (ev) => {
				if (ev.detail.hide) this.$refs.notification.hide();
				else this.$refs.notification.show(ev.detail);
			});

			EventBus.$on('app-version-update', (version) => {
				this.$refs.versionUpdate.open(version);
			});
		},

		methods: {
			goToSourcing() {
				this.$refs.welcomeDialog.close();
				this.$router.push('/advisers');
			},

			closeWelcomeDialog() {
				this.$refs.welcomeDialog.close();
			}
		}
	};
</script>

<style lang="scss">
	@import '@/assets/scss/global.scss';
</style>

<style lang="scss" scoped>
	.fade-enter {
		opacity: 0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.25s ease;
	}

	.fade-leave-active {
		opacity: 0;
	}

	.v-application--is-ltr ::v-deep .v-data-footer__select {
		margin-left: 0;
	}
</style>
