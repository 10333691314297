import { OptionAdvisor, OptionCountry, OptionGender, OptionWebhook } from '@/service';

/**
 * @class @name Option
 * @description Exposes menu store module
 */
export default class Option {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name country
			 * @param {Object} context
			 * @param {Object} payload
			 */
			async getCountryOption(context, id) {
				let { data } = await OptionCountry.get(id);

				return data.name;
			},

			/**
			 * @async @name gender
			 * @param {Object} context
			 * @param {Object} payload
			 */
			async getGenderOption(context, id) {
				let { data } = await OptionGender.get(id);

				return data.name;
			},

			/**
			 * @async @name getAdvisorList
			 */
			async getAdvisorList() {
				return await OptionAdvisor.get();
			},

			/**
			 * @async @name getWebhookList
			 */
			async getWebhookList() {
				return await OptionWebhook.list();
			}
		};
	}
}
