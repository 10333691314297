<!--
	@name app-source-result-product-loader-dialog
	@description Load products via API
	@date 2020/07/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-dialog width="975" persistent :value="model">
		<v-row no-gutters>
			<v-col cols="12" sm="5" class="d-flex" :style="`background-color: ${advert.color};`" style="position: relative; overflow: hidden; transform: translate3d(0,0,0);">
				<div class="py-6 px-10 text-center align-self-center">
					<div class="bg" />
					<div class="bg bg2" />
					<div class="bg bg3" />
					<p v-html="$sanitize(advert.title)" />
					<span v-html="$sanitize(advert.copy)" />
					<v-btn
						v-if="onAirArticle"
						class="ga--trigger ga--on-air-article-opened font-weight-medium"
						data-ga-id="product-loader"
						:data-ga-value="onAirArticle.title"
						:color="advert.button.color"
						block
						large
						dark
						@click="handleClick('onAirArticle', onAirArticle)"
					>
						{{ advert.button.text }}
					</v-btn>
					<v-btn
						v-if="video"
						class="ga--trigger ga--article-opened font-weight-medium"
						data-ga-id="product-loader"
						:data-ga-value="video.title"
						:color="advert.button.color"
						block
						large
						dark
						@click="handleClick('video', video)"
					>
						{{ advert.button.text }}
					</v-btn>
				</div>
			</v-col>
			<v-col cols="12" sm="7" class="d-flex flex-column">
				<v-toolbar dark dense flat :color="failures.length ? 'error' : 'blue-grey darken-4'" class="flex-grow-0">
					<v-toolbar-title>
						{{ state === 'active' ? 'Retrieving Live Rates' : 'Loading session' }}
					</v-toolbar-title>

					<v-spacer />

					<div v-if="state === 'active'">
						<v-tooltip bottom>
							<template #activator="{ on }">
								<v-btn icon color="white" v-on="on" @click="productLoaderDisplayInfo = !productLoaderDisplayInfo">
									<v-icon>mdi-frequently-asked-questions</v-icon>
								</v-btn>
							</template>
							<span>What's all this?</span>
						</v-tooltip>

						<v-tooltip bottom>
							<template #activator="{ on }">
								<v-btn icon color="white" v-on="on" @click="close">
									<v-icon>mdi-window-minimize</v-icon>
								</v-btn>
							</template>
							<span>Minimise window</span>
						</v-tooltip>
					</div>
				</v-toolbar>

				<v-card-text v-show="!productLoaderDisplayInfo" class="py-2 px-0 yellow lighten-4 flex-grow-1">
					<div v-if="state === 'active'">
						<template v-if="failures.length">
							<p class="text-body-2 error--text font-weight-medium px-4 py-1 mb-0">
								{{ failures.length }} provider{{ failures.length !== 1 ? 's' : '' }} failed to return products.
								Click "VIEW DETAILS" for an explanation and, where possible, a resolution.
							</p>
							<v-divider class="my-2" />
						</template>
						<v-list class="pa-0">
							<app-source-result-product-loader-item
								v-for="(provider, index) in providers"
								:key="index"
								:provider="provider"
								:loading-products="loadingProducts"
								v-on="$listeners"
							/>
						</v-list>
					</div>
					<div v-else class="pa-4">
						<v-progress-linear indeterminate color="black" />
					</div>
				</v-card-text>
				<v-card-text v-show="productLoaderDisplayInfo" class="pa-5 yellow lighten-4 flex-grow-1">
					<v-btn icon class="float-left mr-3 mb-2" @click="productLoaderDisplayInfo = false">
						<v-icon> mdi-arrow-left </v-icon>
					</v-btn>
					<p class="text-body-2 mb-3">
						Where a provider has made their product feed available via an API, we will return products directly from the provider. Loading times can be dependent on a variety
						of factors including your own connection speed and that of the provider platform. Please wait until all connection attempts have completed in order to ensure you
						are working from a full product list.
					</p>
					<p class="text-body-2 mb-0 font-weight-bold">
						Should an API request result in 0 products, an explanation can be found by clicking the 'view details' button.
					</p>
				</v-card-text>
			</v-col>
		</v-row>
	</v-dialog>
</template>

<script>
	import { EventBus } from '@/utils';
	import AppSourceResultProductLoaderItem from '@/component/app/source-result/product-loader/item';

	export default {
		name: 'app-source-result-product-loader-dialog',

		components: {
			AppSourceResultProductLoaderItem
		},

		props: {
			providers: { type: Array, required: true },
			loadingProducts: { type: Boolean, required: true }
		},

		data() {
			return {
				model: false,
				productLoaderDisplayInfo: false,
				state: null,
				advert: {
					title: "<p class='text-h4 font-weight-bold mb-6'>Speedy service guaranteed or £500!</p>",
					copy: "<p class='text-h6 font-weight-regular mb-9'>Get from <strong>app to offer in 10 working days, or we'll give your client £500</strong>. That's more2life's Service Promise. </p>",
					button: {
						text: "Learn More",
						color: '#209547'
					},
					color: '#86b42e'
				},
				/*onAirArticle: {
					id: "797ccabf-569e-40d8-a997-7545419cf825",
					title: "Knowledge Bank Enhancement"
				}*/
				onAirArticle: null,
				video: {
                    title: "Speedy service guaranteed or £500!",
                    abstract: "Get from app to offer in 10 working days, or we'll give your client £500. That's more2life's Service Promise. Terms and Conditions apply which are available on the more2life website.",
                    video: "manager_documents/96d9f7440ba56942c1c8967afd5b4712.mp4",
                    description: null
                }
			};
		},

		computed: {
			failures() {
				return this.providers.filter((p) => p.status && p.status.toLowerCase() === 'failed');
			}
		},

		methods: {
			open(state = 'active') {
				this.state = state;
				this.model = true;
			},

			close() {
				this.model = false;
			},

			/**
			 * @name handleClick
			 * @description Handle CTA
			 */
			handleClick(type, data) {
				if (type === 'onAirArticle') this.$nextTick(() => EventBus.$emit('open-on-air-article', data.id));
				if (type === 'video') this.$nextTick(() => EventBus.$emit('open-video', data));
			}
		}
	};
</script>

<style lang="scss" scoped>
	.bg {
		animation:slide 3s ease-in-out infinite alternate;
		background-image: linear-gradient(-60deg, tint(#000000, 80%) 50%, tint(#000000, 65%) 50%);
		//background-image: linear-gradient(-60deg, tint(#2159ac, 75%) 50%, tint(#2159ac, 60%) 50%);
		bottom:0;
		left:-50%;
		opacity:.5;
		position:fixed;
		right:-50%;
		top:0;
		z-index:-1;
		mix-blend-mode: hard-light;
		opacity: 0.3;
	}

	.bg2 {
		animation-direction:alternate-reverse;
		animation-duration:4s;
	}

	.bg3 {
		animation-duration:5s;
	}

	@keyframes slide {
		0% {
			transform:translateX(-25%);
		}
		100% {
			transform:translateX(25%);
		}
	}
</style>