import { LifetimeMortgageKfi } from '@/service';

/**
 * @class @name Kfi
 * @description Exposes menu store module
 */
export default class Kfi {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name fetchKfiList
			 * @param {function} commit
			 * @param {Object} payload
			 */
			fetchKfiList(context, payload) {
				return LifetimeMortgageKfi.list(payload);
			},

			/**
			 * @async @name requestKfi
			 * @param {Object} context
			 * @param {Object} payload
			 */
			requestKfi(context, payload) {
				return LifetimeMortgageKfi.post(payload);
			},

			/**
			 * @async @name submitKfi
			 * @param {Object} context
			 * @param {Object} payload
			 */
			submitKfi(context, payload) {
				return LifetimeMortgageKfi.submit(payload);
			},

			/**
			 * @async @name deleteProduct
			 * @param {Object} context
			 * @param {Object} payload
			 */
			deleteProduct(context, payload) {
				return LifetimeMortgageKfi.delete(payload);
			},

			/**
			 * @async @name fetchKfiAdvisorList
			 * @param {function} commit
			 * @param {Object} payload
			 */
			fetchKfiAdvisorList(context, payload) {
				return LifetimeMortgageKfi.advisorList(payload);
			},
		};
	}
}
