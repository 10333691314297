<!--
	@name app-reward-history
	@description Rewards page history
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-structure-section :component="$options.name" body-class="flex-grow-1 pa-7 pr-5">
		<template #body>
			<v-fade-transition hide-on-leave>
				<v-skeleton-loader v-if="!rewardTransactionList" type="article" />
				<div v-else-if="rewardHistory.length" id="my-scrollbar" style="overflow: auto; max-height: 600px">
					<v-timeline class="mr-7 py-3">
						<v-slide-x-reverse-transition group hide-on-leave>
							<v-timeline-item v-for="(item, index) in rewardHistory" :key="index" small class="pb-0" :class="[ icon(item), { 'pt-6': index !== 0 } ]">
								<v-card class="elevation-2">
									<v-toolbar elevation="0" dark dense :class="toolbarClass(item)">
										<v-toolbar-title class="text-capitalize font-weight-medium">
											{{ title(item) }}
										</v-toolbar-title>
									</v-toolbar>
									<v-card-text class="text-body-2 font-weight-medium mb-0 pa-4 grey--text text--darken-2 ">
										<p class="ma-0" :class="{ 'text-decoration-line-through':item.rejected }">
											{{ buildCopy(item) }}
										</p>
										<v-tooltip v-if="item.rejected" bottom left max-width="300">
											<template #activator="{ on }">
												<v-chip v-if="item.rejected" label x-small color="error" class="mt-2" v-on="on">
													Declined
												</v-chip>
											</template>
											<span>This request was declined on {{ item.rejected | moment('MMMM Do YYYY') }} and the points added back on to your account</span>
										</v-tooltip>
										<div class="mt-2">
											<v-tooltip bottom>
												<template #activator="{ on }">
													<span class="text-body-2 font-weight-light" v-on="on">
														{{ item.created | moment('from', 'now') }}
													</span>
												</template>
												<span>{{ item.created | moment('MMMM Do YYYY') }}</span>
											</v-tooltip>
										</div>
									</v-card-text>
									<v-card-actions v-if="item.data.type === 'voucher' && !item.actioned && checkDate(item.expiry, 'isSameOrAfter')" class="pa-4 pt-0">
										<v-btn small color="primary" @click="$emit('redeem', 'voucher', item.id)">
											Redeem
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-timeline-item>
						</v-slide-x-reverse-transition>
					</v-timeline>
				</div>
				<div v-else>
					<p class="body-1 mb-0">
						Points added to and redeemed from your account will appear here.
					</p>
				</div>
			</v-fade-transition>
		</template>
	</common-structure-section>
</template>

<script>
	import numeral from 'numeral';
	import CommonStructureSection from '@/component/common/structure/section';
	import Scrollbar from 'smooth-scrollbar';
	import { easeOutExpo } from 'es6-easing';

	export default {
		name: 'app-reward-history',

		components: { CommonStructureSection },

		inject: ['$rewardTransactionList'],

		computed: {
			rewardTransactionList() {
				return this.$rewardTransactionList();
			},

			rewardHistory() {
				return (this.rewardTransactionList || []).filter((item) => item.data) || [];
			}
		},

		mounted() {
			this.initScrollbar();
		},

		methods: {
			checkDate(date, method) {
				if(method === 'isBefore') return this.$moment(date).isBefore(this.$moment(new Date()));
				if(method === 'isSameOrBefore') return this.$moment(date).isSameOrBefore(this.$moment(new Date()));
				if(method === 'isAfter') return this.$moment(date).isAfter(this.$moment(new Date()));
				if(method === 'isSameOrAfter') return this.$moment(date).isSameOrAfter(this.$moment(new Date()));
			},

			toolbarClass(item) {
				if(item.data.type === 'redemption') {
					if(item.rejected) return 'background-rotation-manual--error';
					return 'background-rotation-manual--1';
				}
				if(item.data.type === 'voucher') return 'background-rotation-manual--5';
				return 'background-rotation-manual--2';
			},

			icon(item) {
				if(item.data.type === 'redemption') {
					if(item.rejected) return 'rejection';
					return 'gift';
				}
				if(item.data.type === 'voucher') return 'bookmark';
				return 'diamond';
			},

			title(item) {
				return item.data.type === 'redemption' ? 'Redemption' : item.data.type === 'voucher' ? 'Voucher' : 'Reward';
			},

			buildCopy(item) {
				if (item.data.type === 'redemption') {
					let msg = `You redeemed ${numeral(item.amount).format('0,0')} points.`;
					return msg;
				}

				if (item.data.type === 'voucher') {
					let msg = `${numeral(item.amount).format('0,0')} points were added to a redemption voucher.`;
					if (item.actioned) msg += ` This voucher was redeemed on ${this.$moment(String(item.actioned)).format('ddd, Do MMM YYYY')}.`;
					else if (this.checkDate(item.expiry, 'isBefore')) msg += ` This voucher expired on ${this.$moment(String(item.expiry)).format('ddd, Do MMM YYYY')}.`;
					else msg += ` This voucher expires on ${this.$moment(String(item.expiry)).format('ddd, Do MMM YYYY')}.`;
					return msg;
				}

				let msg = `You earned ${numeral(item.amount).format('0,0')} Air Reward Points via a`;
				if (item.credit?.id === 'd31badda-dcfe-4586-8045-ee07cec70033' && item.data?.reason) msg += ` "${item.data.reason}".`;
				else msg += ` "${(item.credit || {}).name}".`;
				if (item.data.provider) msg += ` The Provider in this case was ${item.data.provider}.`;
				if (item.data.client) msg += ` The Reference for this case was ${item.data.client}.`;
				return msg;
			},

			initScrollbar() {
				this.scrollbar = Scrollbar.init(document.querySelector('#my-scrollbar'), {
					alwaysShowTracks: true,
					renderByPixels: true
				});

				this.scrollbar.scrollLeft = 750;

				setTimeout(() => {
					this.scrollbar.scrollTo(0, 0, 750, { easing: easeOutExpo });
				}, '500');

				this.interval = setInterval(this.autoScroll, 5000);
			}
		}
	};
</script>

<style scoped lang="scss">
	.v-application .v-list .v-list-item {
		border-radius: 2px !important;
		border: none;

		&.award {
			border-left-width: 5px !important;
			background-color: tint($col1, 85%) !important;
		}

		&.redemption {
			border-color: $col2 !important;
			background-color: tint($col2, 85%) !important;
		}
	}

	.v-timeline-item {
		&.gift {
			::v-deep .v-timeline-item__inner-dot {
				background-color: rgba(27, 120, 216, 0.95) !important;
			}
		}

		&.rejection {
			::v-deep .v-timeline-item__inner-dot {
				background-color: rgba(211, 47, 47, 0.95) !important;
			}
		}

		&.diamond {
			::v-deep .v-timeline-item__inner-dot {
				background-color: rgba(23, 184, 144, 0.95) !important;
			}
		}

		&.bookmark {
			::v-deep .v-timeline-item__inner-dot {
				background-color: rgba(103, 0, 86, 0.95) !important;
			}
		}
	}

	#my-scrollbar {
		::v-deep .scroll-content {
			display: flex;
		}

		::v-deep .scrollbar-thumb {
			background: rgba(71, 0, 72, 0.25);
			background: linear-gradient(135deg, rgba(71, 0, 72, 0.85) 0%, rgba(27, 120, 216, 0.85) 100%);
		}
	}
</style>
