<!--
	@name app-client-dashboard-workflow-template-resources
	@description Client Workflow Resources Template
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<div v-show="filteredResources.length">
			<v-divider inset class="mt-10 mb-8" />
			<p class="text-body-1 font-weight-bold mb-2">
				Resources
			</p>
			<p class="text-body-2 mb-0">
				The resources below have been produced by <strong>Air Academy</strong> to provide a helping hand as you progress through the AirFlow journey.
				Each stage has it's own dedicated resources, specifcally designed to equip you with all the knowledge you need to maximise your use of the platform.
				<span v-if="authStatus == 'error'" class="font-weight-bold">
					To access these resources, you'll first need to link your Air Academy account.
					If you have any questions on how to do this, please <a @click="openChat">start a live chat</a> or <a href="mailto:Academy.enquiries@airlaterlife.co.uk">click here</a> to get in contact.
				</span>
			</p>
			<v-btn v-if="authStatus == 'error'" light small class="mt-4 mb-0" color="primary" @click="openAuthoriseDialog">
				Connect my Air Academy Learning Platform
			</v-btn>

			<template v-if="authStatus == null">
				<p class="text-body-2 mt-4 mb-3">
					Retrieving your LMS account details
				</p>
				<v-progress-linear height="24" indeterminate />
			</template>
			<v-row v-else class="mt-4">
				<v-col v-for="(item, index) in filteredResources" :key="index" cols="4">
					<app-air-academy-training-webinar-item
						:item="{
							name: item.name,
							image: item.image ? `${VUE_APP_S3_AIR_ASSETS_URL}/${item.image}` : `${VUE_APP_S3_AIR_ASSETS_URL}/placeholder/gradient.png`,
							mimetype: 'video/mp4',
							hideStatus: true,
							fixedHeight: true,
							url: item.url,
							disable: authStatus == 'error' ? 'You must first link your Air Academy account' : false
						}"
					/>
					<v-card class="d-none">
						<v-img :src="(item.image ? item.image : 'placeholder/gradient.png') | urlize" height="120px" />

						<v-card-title class="pb-5" style="word-break: normal;">
							{{ item.name }}
						</v-card-title>

						<v-card-subtitle>
							{{ item.description | truncate(150) }}
						</v-card-subtitle>

						<v-card-actions>
							<v-btn color="primary" text :href="item.url" target="_blank">
								View
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
	import { EventBus } from '@/utils';
	import { mapState } from 'vuex';
	import AppAirAcademyTrainingWebinarItem from '@/component/app/air-academy/training/webinar-item';

	export default {
		name: 'app-client-dashboard-workflow-template-resources',

		components: {
			AppAirAcademyTrainingWebinarItem
		},

		props: {
			section: { type: String, required: true },
			resources: { type: Array, required: true },
			removeIntroVideo: { type: Boolean, required: true }
		},

		data() {
			return {
				VUE_APP_S3_AIR_ASSETS_URL: process.env.VUE_APP_S3_AIR_ASSETS_URL
			};
		},

		computed: {
			...mapState('AppWebinar', ['authStatus']),

			testimonial() {
				return {
					name: 'Adviser Accreditation for Later Life Lending',
					image: `${this.VUE_APP_S3_AIR_ASSETS_URL}/webinar/AcademyAccreditation.jpg`,
					mimetype: 'video/mp4',
					hideStatus: true,
					fixedHeight: true
				};
			},

			filteredResources() {
				return this.resources.filter(c =>
					c.workflowTemplateItem.some(wti => wti?.name_unique === this.section) &&
					!(this.removeIntroVideo && c.useAsIntro === true)
				);
			}
		},

		methods: {
			openAuthoriseDialog() {
				EventBus.$emit('open-authorise-dialog');
			},

			/**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			}
		}
	};
</script>

<style scoped lang="scss"></style>
