<!--
	@name app-enquiry-details-response
	@description Wrapper response component for timeline item
	@date 2020/06/23
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-timeline-item :component="$options.name" :left="isAdviser" :right="!isAdviser" :color="color" :small="isAdviser">
		<template #opposite>
			<img v-if="response.author_logo" :src="response.author_logo" width="90" class="mb-2 elevation-2" />
			<p v-if="response.created" class="text-body-2 grey--text text--darken-1 mb-0">
				{{ response.created | moment('Do MMM YYYY, HH:mm') }}
			</p>
		</template>

		<v-card class="elevation-2">
			<v-toolbar :color="color" dark dense elevation="0">
				<v-toolbar-title dense class="font-weight-medium">
					{{ responseTitle }}
				</v-toolbar-title>
			</v-toolbar>

			<v-card-text class="pa-4">
				<p v-if="isWithdraw" class="text-body-2 grey--text text--darken-3 mb-0">
					{{ getOrganisationName(response?.data?.id) }} withdrew from this enquiry.
				</p>
				<p v-else-if="isReconnect" class="text-body-2 grey--text text--darken-3 mb-0">
					{{ getOrganisationName(response?.data?.id) }} is readded to this enquiry.
				</p>
				<pre v-else class="text-body-2 grey--text text--darken-3 mb-0" style="white-space: break-spaces" v-html="$sanitize(response.data.message)" />

				<v-sheet v-if="response.parentProcess && !response.parentProcess.initialMessage" shaped elevation="0" class="mt-5 ml-5 pa-4 yellow lighten-5">
					<p class="text-caption grey--text text--darken-2 font-weight-medium mb-0">
						In response to {{ getOrganisationName(response.parentProcess?.data?.responder?.id) || 'Air Sourcing Support Team' }}:
					</p>
					<p class="text-caption mb-0 grey--text">
						{{ response.parentProcess.data.message }}
					</p>
				</v-sheet>

				<v-list v-if="anyRecipients && recipientList.length > 0" dense class="mt-5 pa-0">
					<p class="text-caption font-weight-bold black--text mb-1">
						Recipients
					</p>
					<div class="ma-n1">
						<v-tooltip v-for="(recipient, index) in recipientList" :key="index" bottom>
							<template #activator="{ on }">
								<v-chip
									class="ma-1"
									:class="[
										{
											'light-green': recipient.action?.length,
											'green lighten-1': recipient.data.read,
											'grey lighten-2': !recipient.action?.length && !recipient.data.read
										}
									]"
									small
									label
									v-on="on"
								>
									{{ getOrganisationName(recipient.data.recipient.id) }}
								</v-chip>
							</template>
							<span>{{ getStatus(recipient) }}</span>
						</v-tooltip>
					</div>
				</v-list>

				<v-list v-if="response.data.files" dense class="mt-5 pa-0">
					<p class="text-caption font-weight-bold black--text mb-1">
						Attachments
					</p>
					<v-list-item
						v-for="(file, index) in response.data.files"
						:key="index"
						:disabled="isDocumentExpired(response.created)"
						class="pa-0 blue-grey lighten-5"
						:class="[{ 'mt-1': index !== 0 }]"
						@click="downloadFile(file)"
					>
						<v-list-item-avatar height="30" width="30" class="mx-2">
							<v-progress-circular v-if="fileLoading[file.s3Key]" indeterminate size="20" width="2" color="amber" />
							<v-icon v-else>
								mdi-download
							</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title>
								{{ file.filename }}
							</v-list-item-title>
							<v-list-item-subtitle v-if="isDocumentExpired(response.created)" class="error--text font-weight-medium">
								Document expired
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-card-text>

			<v-card-actions v-if="timeSeen" class="pa-4 pt-0">
				<v-spacer />
				<p class="text-caption grey--text text--darken-1 font-italic mb-0">
					Seen {{ timeSeen | moment('DD MMM YYYY HH:mm') }}
				</p>
			</v-card-actions>

			<v-card-actions v-if="isResponded || response.data.responder" class="pa-4 pt-0">
				<v-spacer />
				<p v-if="isResponded" class="text-caption grey--text text--darken-1 font-italic mb-0">
					Response Sent {{ timeResponded | moment('DD MMM YYYY HH:mm') }}
				</p>
				<v-btn
					v-else-if="response.data.responder"
					:loading="loading[response.id]"
					:disabled="enquiryClosed || isWithdrawn"
					small
					color="primary"
					@click="$emit('open-response-dialog', response.id, iterationId)"
				>
					Send a Response
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-timeline-item>
</template>

<script>
	import { ElementTools, PopupWindow } from '@/utils';
	import { mapActions, mapState } from 'vuex';

	export default {
		name: 'app-enquiry-details-response',

		components: {},

		props: {
			response: { type: Object, required: true },
			loading: { type: Object, required: true },
			enquiryClosed: { type: Boolean, required: false },
			organisations: { type: Array, default: () => [] }
		},

		data() {
			return {
				adviserColor: 'background-rotation-manual--5',
				systemColor: 'background-rotation-manual--2',
				providerColor: 'background-rotation-manual--1',
				fileLoading: {}
			};
		},

		computed: {
			...mapState('Account', ['user']),

			isAdviser() {
				return !this.response?.data?.responder && !this.isWithdraw && !this.isReconnect;
			},

			isSystem() {
				return this.response?.data?.responder?.type === 'system';
			},

			isWithdraw() {
				return this.response?.processTypeNameUnique === 'withdraw';
			},

			isReconnect() {
				return this.response?.processTypeNameUnique === 'reconnect';
			},

			color() {
				if (this.isAdviser) return this.adviserColor;
				else if (this.isSystem) return this.systemColor;
				else return this.providerColor;
			},

			responseTitle() {
				return this.isAdviser
					? this.user.name
					: this.isSystem
					? 'Air Sourcing Support Team'
					: this.isWithdraw || this.isReconnect
					? this.getOrganisationName(this.response?.data?.id)
					: this.getOrganisationName(this.response.data.responder.id);
			},

			anyRecipients() {
				return this.response.processTypeNameUnique === 'update' && this.isAdviser;
			},

			recipientList() {
				return this.response.iteration?.filter((iteration) => iteration.data.recipient.type !== 'system');
			},

			isResponded() {
				return (
					this.response.data.responder && (this.response.iteration || []).find((iteration) => (iteration.action || []).find((action) => action.type === 'CreateResponse'))
				);
			},

			timeResponded() {
				return this.response.iteration?.find((iteration) => (iteration.action || []).find((action) => action.type === 'CreateResponse')).created;
			},

			timeSeen() {
				return this.response.iteration?.[0]?.data?.read;
			},

			// TODO: don't think this is working since the withdrawal was changed to a process, shouldn't it be return this.response.processTypeNameUnique === 'withdraw';?
			isWithdrawn() {
				return this.organisations.find((o) => o.id === this.response.data?.responder?.id)?.withdrawn;
			},

			iterationId() {
				return this.response.iteration?.[0]?.id;
			}
		},

		methods: {
			...mapActions('File', ['getFile']),

			isDocumentExpired(created) {
				return this.$moment(created).isBefore(this.$moment().subtract(10, 'days'));
			},

			/**
			 * @name getOrganisationName
			 * @description Get organisation name by id
			 * @param {String} id
			 */
			getOrganisationName(id) {
				return this.organisations?.find((o) => o.id === id)?.name;
			},

			/**
			 * @name getStatus
			 * @description Get status
			 * @param {Object} recipient
			 */
			getStatus(recipient) {
				return recipient.action?.length ? 'Responded' : recipient.data.read ? 'Seen' : 'Awaiting Response';
			},

			/**
			 * @name downloadFile
			 * @description get signed url and download file requested
			 * @param {Object} file
			 */
			downloadFile(file) {
				this.$set(this.fileLoading, file.s3Key, true);

				this.getFile(file)
					.then((response) => {
						if (response.data?.preSignedUrl) new PopupWindow().open(response.data.preSignedUrl);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured. Please try again later'))
					.finally(() => this.$set(this.fileLoading, file.s3Key, false));
			}
		}
	};
</script>
