<!--
	@name app-firm
	@description Firm admin dashboard
	@date 2020/07/10
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3">
		<v-sheet class="pa-6 mb-9 rounded background-gradient">
			<v-row no-gutters>
				<v-col class="shrink align-self-center">
					<div class="align-self-center nowrap headline white--text">
						My Company
					</div>
				</v-col>
				<v-col class="grow text-right">
					<common-dialog-glossary class="d-inline" :items="glossaryItems" :button-text-hidden="true" :icon-large="true" icon-color="white" title="Glossary" />

					<v-tooltip v-if="!restrictedRoutes.includes('myCompany.webhooks')" bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" :loading="loadingApiCredentials" @click="fetchApiCredentials()" v-on="on">
								<v-icon large>
									mdi-api
								</v-icon>
							</v-btn>
						</template>
						<span>API Credentials</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" @click="$refs.invite.open()" v-on="on">
								<v-icon large>
									mdi-account-plus
								</v-icon>
							</v-btn>
						</template>
						<span>Invite New Team Member</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn large text color="white" to="/account" v-on="on">
								<v-icon large>
									mdi-backburger
								</v-icon>
							</v-btn>
						</template>
						<span>Back to Dashboard</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-sheet>

		<v-row>
			<v-col class="py-0" cols="12" md="8">
				<div class="border-effect border-effect--lg ml-6 mb-10">
					<p class="text-h5 mb-2 font-weight-regular">
						Account name: <span class="font-weight-bold">{{ organisation.name }}</span>
					</p>
					<p class="text-body-1 mb-0">
						Here you can view all advisers who belong to your firm, add a new adviser, edit, remove or suspend any accounts within your
						control. You can also edit any information we have on file for you company.
					</p>
				</div>

				<!-- Tabs -->
				<common-base-tab-view :tabs="tabs" router-view @open-invite="openInvite" />
			</v-col>

			<v-col v-if="1 === 2" class="py-0" cols="12" md="4">
				<common-chart-sparkline color="blue darken-4" title="COMING SOON: Sessions last 6 months" :data="sparklineData" />
				<common-chart-sparkline class="mt-5" color="success" title="COMING SOON: Completions last 6 months" :data="sparklineData" />
				<common-chart-sparkline class="mt-5" color="yellow darken-3" title="COMING SOON: Proc Fees last 6 months" :data="sparklineData" />
			</v-col>

			<v-col v-if="hasBanner" class="py-0" cols="12" md="4">
				<common-promotion-advisor-advert />
			</v-col>
		</v-row>

		<app-firm-dialog-invite ref="invite" :form="form('firm_invite').value" />

		<common-dialog ref="apiCredentialsDialog" :max-width="650">
			<template #header>
				API Credentials
			</template>
			<template #body>
				<dynamic-form
					:form-schema="apiCredentialsFormSchema"
					:form-data="apiCredentialsFormData"
					:submit-button="false"
				/>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonBaseTabView from '@/component/common/base/tab-view';
	import CommonChartSparkline from '@/component/common/chart/sparkline';
	import CommonDialog from '@/component/common/dialog';
	import CommonDialogGlossary from '@/component/common/dialog/glossary';
	import CommonPromotionAdvisorAdvert from '@/component/common/promotion/advisor-advert';
	import AppFirmDialogInvite from '@/component/app/firm/dialog/invite';
	import DynamicForm from '@/component/common/base/dynamic-form';

	export default {
		name: 'app-firm',

		components: {
			CommonBaseTabView,
			CommonChartSparkline,
			CommonDialog,
			CommonDialogGlossary,
			CommonPromotionAdvisorAdvert,
			AppFirmDialogInvite,
			DynamicForm
		},

		data() {
			return {
				sparklineData: {
					labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
					value: [12, 24, 5, 19, 10, 22]
				},
				tabs: [
					{ title: 'manage accounts', alerts: 0, name: 'myCompany.accounts', path: '/firm/accounts' },
					{ title: 'edit details', alerts: 0, name: 'myCompany.details', path: '/firm/details' },
					{ title: 'trading styles', alerts: 0, name: 'myCompany.tradingStyles', path: '/firm/trading-styles' },
					{ title: 'domains', alerts: 0, name: 'myCompany.domains', path: '/firm/domains' },
					{ title: 'webhooks', alerts: 0, name: 'myCompany.webhooks', path: '/firm/webhooks' }
				],
				glossaryItems: [
					{
						key: 'inviteNewMember',
						type: 'icon',
						icon: 'mdi-account-plus',
						iconColour: 'primary darken-3',
						title: 'Invite New Team member',
						description:
							'This is functionality designed to allow an account owner or admin to send an invitation to a new member of staff to join their organisation/company.'
					},
					{
						key: 'suspendAccount',
						type: 'icon',
						icon: 'mdi-lock',
						iconColour: 'warning',
						title: 'Suspend Account',
						description: 'This offers the account owner or account admin the capability to revoke the access a staff member has to an account under their organization.'
					},
					{
						key: 'cancelInvitiation',
						type: 'icon',
						icon: 'mdi-cancel',
						iconColour: 'red',
						title: 'Cancel Invitation',
						description: 'The \'Cancel invitation\' functionality allows the account owner or account admin to terminate an invite.'
					},
					{
						key: 'resendInvitation',
						type: 'icon',
						icon: 'mdi-email-sync-outline',
						iconColour: 'blue',
						title: 'Resend Invitation',
						description:
							'An invited account will remain in the "Pending" state until they have completed the invitation acceptance process. Should you need to resend an invitation to an account with a status of "Pending", you can do so using the "Resend Invitation" button. Resending an invitation will invalidate any invitations previously sent to that recipient and trigger a new invitation email/link.'
					},
					{
						key: 'createAdmin',
						type: 'icon',
						icon: 'mdi-key-plus',
						iconColour: 'teal',
						title: 'Create Admin',
						description: 'This allows an account to be made an admin of the organisation on the air sourcing platform.'
					}
				],
				loadingApiCredentials: false,
				apiCredentialsFormSchema: [
					{
						"type": "text",
						"name": "clientId",
						"display": "Client ID",
						"readonly": true,
						"dense": true,
						"sm": 12
					},
					{
						"type": "password",
						"name": "clientSecret",
						"display": "Client Secret",
						"readonly": true,
						"dense": true,
						"sm": 12
					}
				],
				apiCredentialsFormData: {}
			};
		},

		computed: {
			...mapState('Account', ['organisation']),
			...mapState('CmsSite', ['site']),
			...mapGetters('CmsForm', ['form']),
			...mapGetters('CmsSite', ['restrictedRoutes']),

			hasBanner() {
				return this.site.adverts?.advisor;
			}
		},

		created() {
			this.loadForm('firmInvite');
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('AccountOrganisationUserApi', ['getOrganisationApiUser']),

			openInvite() {
				this.$refs.invite.open();
			},

			async fetchApiCredentials() {
				this.loadingApiCredentials = true;

				try {
					this.apiCredentialsFormData = (await this.getOrganisationApiUser(this.organisation.id)).data;
					this.$refs.apiCredentialsDialog.open();
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to load API Credentials');
				}

				this.loadingApiCredentials = false;
			}
		}
	};
</script>
