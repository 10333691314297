<!--
	@name app-client-list
	@description Client List
	@date 2020/06/03
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-row no-gutters class="justify-space-between pb-5">
			<v-col class="align-self-center">
				<v-pagination v-model="options.page" class="mx-n2" :length="pageCount" :total-visible="8" color="info" @input="paginationUpdated" />
			</v-col>
			<v-col class="shrink align-self-center">
				<v-text-field
					v-model="searchTerm"
					placeholder="Search: e.g. Luis Carruthers"
					append-icon="mdi-magnify"
					hide-details
					dense
					solo
					:class="{ 'flex-shrink-0': '20rem' }"
					:style="{ width: '20rem', 'max-width': '20rem' }"
				/>
			</v-col>
		</v-row>
		<common-structure-section :body-class="''">
			<template #body>
				<v-data-table
					class="table--styled elevation-3"
					:footer-props="footerProps"
					:headers="headers"
					:items="clientList"
					:search="searchTerm"
					:server-items-length="total"
					:loading="loading"
					:options.sync="options"
					loading-text="Loading... Please wait"
					no-data-text="No matching clients found"
					no-results-text="No matching clients found"
					@update:options="optionsUpdated($event)"
				>
					<template #loading>
						<span class="text-body-2 py-3"> Loading... Please wait </span>
					</template>
					<template #item="{ item }">
						<tr class="tbl-row">
							<td class="shrunk px-10">
								<v-avatar size="44px" :color="getStatus(item).color">
									<v-icon size="28" color="white">
										{{ icon(item).icon }}
									</v-icon>
								</v-avatar>
							</td>
							<td>
								<p class="text-body-1 font-weight-bold mb-0">
									{{ item.name }}
								</p>
								<p class="text-caption plain grey--text text--darken-1 font-italic mb-0">
									{{ item.id }}
								</p>
							</td>
							<td>
								<p v-if="item.asset && item.asset.length > 1" class="mb-0">
									<span class="font-weight-medium">{{ item.asset.length }} Properties</span> including {{ getAssetAddress(item.asset) }}
								</p>
								<p v-else-if="item.asset && item.asset.length > 0" class="mb-0">
									{{ getAssetAddress(item.asset) }}
								</p>
								<p v-else class="mb-0 font-weight-medium">
									<a @click="$router.push(`client/${item.id}`)">Click here to add a property</a>
								</p>
							</td>
							<td class="nowrap">
								<template v-if="item.created">
									{{ item.created | moment('Do MMM YYYY, HH:mm') }}
								</template>
								<template v-else>
									{{ '1970-01-01' | moment('Do MMM YYYY, HH:mm') }}
								</template>
							</td>
							<td class="nowrap">
								{{ item.updated | moment('Do MMM YYYY, HH:mm') }}
							</td>
							<td class="nowrap">
								<v-chip light label small :color="getStatus(item).color">
									{{ getStatus(item).text }}
								</v-chip>
							</td>
							<td class="shrunk nowrap text-right">
								<div class="d-flex flex-no-wrap justify-end">
									<v-tooltip bottom>
										<template #activator="{ on }">
											<v-btn
												color="primary"
												class="mr-2"
												small
												dark
												:loading="item.loading"
												:disabled="item.onBoarding"
												v-on="on"
												@click="$router.push(`client/${item.id}`)"
											>
												<v-icon>mdi-chevron-right</v-icon>
											</v-btn>
										</template>
										<span>Access Client Record</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template #activator="{ on }">
											<v-btn
												color="error"
												class="ml-1"
												small
												icon
												:loading="item.loading"
												:disabled="item.onBoarding"
												v-on="on"
												@click="submitDeleteClient(item)"
											>
												<v-icon>mdi-trash-can</v-icon>
											</v-btn>
										</template>
										<span>Delete Client Record</span>
									</v-tooltip>
								</div>
							</td>
						</tr>
					</template>

					<template #no-data>
						<div v-if="noData" class="pt-4 px-2 pb-6 text-center" max-width="800px">
							<p class="text-body-1 grey--text text--darken-4 mb-0">
								<strong>
									Click below to add your first client.
								</strong>
							</p>
							<p v-if="site && site.writeRouteAccess !== false" class="text-body-2 grey--text text--darken-4 mb-0">
								Use <span class="font-weight-medium">Get Started</span> to use the quick client setup wizard OR <span class="font-weight-medium">Open WriteRoute</span> to start a full fact find.
							</p>
							<div class="mx-n2 mt-5">
								<v-btn class="mx-2" color="primary" @click="addClient">
									get started
								</v-btn>
								<v-btn v-if="site && site.writeRouteAccess !== false" class="mx-2" color="secondary" :href="factFindUrl" target="_blank">
									open writeroute
								</v-btn>
							</div>
						</div>
						<template v-else>
							No matching clients found
						</template>
					</template>
				</v-data-table>
			</template>
		</common-structure-section>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { ElementTools } from '@/utils';
	import { debounce } from 'lodash';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';

	export default {
		name: 'app-client-list',

		components: { CommonStructureSection, CommonDialogConfirm },

		data() {
			return {
				clientList: [],
				searchTerm: '',
				pageCount: 0,
				total: 0,
				options: {
					page: 1,
					itemsPerPage: 10,
					sortBy: ['client.updated'],
					sortDesc: ['desc']
				},
				headers: [
					{
						text: '',
						value: '',
						sortable: false
					},
					{
						text: 'Name',
						value: 'client.name_given'
					},
					{
						text: 'Properties',
						value: 'property',
						sortable: false
					},
					{
						text: 'Created',
						value: 'client.created'
					},
					{
						text: 'Last update',
						value: 'client.updated'
					},
					{
						text: 'Status',
						value: "client.data->>'status'"
					},
					{
						text: '',
						value: 'actions',
						sortable: false
					}
				],
				footerProps: {
					'items-per-page-options': [5, 10, 15, 20, 25],
					showFirstLastPage: true,
					firstIcon: 'mdi-arrow-collapse-left',
					lastIcon: 'mdi-arrow-collapse-right',
					prevIcon: 'mdi-minus',
					nextIcon: 'mdi-plus'
				},
				loading: false,
				noData: true
			};
		},


		computed: {
			...mapState('CmsSite', ['site']),

			factFindUrl() {
				return `${process.env.VUE_APP_FACTFIND_ORIGIN}`;
			}
		},

		watch: {
			searchTerm() {
				if (!this.searchTerm.lengh) {
					this.options.page = 1;
				}
				this.debouncedLoadClients();
			}
		},

		methods: {
			...mapActions('AppClient', ['loadAggregatedClientList', 'deleteClient']),

			/**
			 * @name loadClients
			 * @description Load clients
			 */
			async loadClients() {
				this.loading = true;
				this.clientList = [];
				const { page, itemsPerPage, sortDesc, sortBy } = this.options;
				const payload = {
					limit: itemsPerPage,
					offset: (page - 1) * itemsPerPage,
					order: {
						property: sortBy[0] || 'client.updated',
						direction: sortDesc[0] ? 'desc' : 'asc'
					},
					search: this.searchTerm ? `%${this.searchTerm.trim()}%` : undefined
				};

				const { data: clients } = await this.loadAggregatedClientList(payload);
				this.pageCount = Math.ceil(clients.total / itemsPerPage);
				this.total = clients.total;
				if (this.total) this.noData = false; // Used to determine that data exists and avoid showing flash of no data message when using search with 0 results
				this.clientList = clients.data.map((c) => ({ ...c, name: this.getClientName(c) }));
				this.loading = false;
			},

			/**
			 * @name optionsUpdated
			 * @description event handler for options updated
			 * @param {Object} options
			 */
			optionsUpdated(options) {
				this.options = options || this.options;
				this.loadClients();
			},

			/**
			 * @name paginationUpdated
			 * @description event handler for pagination updated
			 */
			paginationUpdated() {
				this.optionsUpdated();
			},

			/**
			 * @name debouncedLoadClients
			 * @description Load clients with debounce
			 */
			debouncedLoadClients: debounce(function() {
				this.loadClients();
			}, 500),

			/**
			 * @name addClient
			 * @description emit addClient event
			 */
			addClient() {
				this.$emit('add-client');
			},

			/**
			 * @name getClientName
			 * @description Get client name
			 */
			getClientName(client) {
				let clientName = this.createFullName(client.nameTitleName, this.getClientFullName(client));
				if (client.association) clientName += ` and ${this.createFullName(client.association.nameTitleName, this.getClientFullName(client.association))}`;
				return clientName;
			},

			/**
			 * @name createFullName
			 * @description Create client name
			 */
			createFullName(title, name) {
				if (!title) return name;
				return `${title} ${name}`;
			},

			/**
			 * @name getClientFullName
			 * @description Get client full name
			 */
			getClientFullName(client) {
				let fullName = client.nameGiven;
				if (client.nameMiddle) fullName += ` ${client.nameMiddle}`;
				fullName += ` ${client.nameFamily}`;
				return fullName;
			},

			/**
			 * @name getAssetAddress
			 * @description Get asset address
			 */
			getAssetAddress(asset) {
				const { address1, address2, townCity, postcode } = asset[0]?.data?.location;
				return [address1, address2, townCity, postcode].filter(Boolean).join(', ');
			},

			/**
			 * @name avatar
			 * @description Get avatar
			 */
			avatar(client) {
				let img = 'client_default';
				if (client.association) img = 'client_joint';
				else if (client.genderName === 'Male') img = 'client_male';
				else if (client.genderName === 'Female') img = 'client_female';
				return `${img}.png`;
			},

			/**
			 * @name icon
			 * @description Get icon
			 */
			icon(client) {
				let icon = 'mdi-account';
				let color = 'rgba(234, 99, 140)';
				if (client.association) {
					icon = 'mdi-account-multiple';
					color = 'rgba(27, 120, 216)';
				} else if (client.genderName === 'Male') {
					icon = 'mdi-face-man';
					color = 'rgba(23, 184, 144)';
				} else if (client.genderName === 'Female') {
					icon = 'mdi-face-woman';
					color = 'rgba(103, 0, 86)';
				}
				return {
					icon,
					color
				};
			},

			/**
			 * @name getStatus
			 * @description Get status object
			 */
			getStatus(client) {
				let opacity = 0.25;
				const status = client?.status || client?.association?.status;
				let text = status,
					color;

				switch (text) {
					case 'Suspended':
						color = `rgba(241, 136, 5, ${opacity})`;
						break;
					case 'Appointment':
						color = `rgba(247, 203, 115, ${opacity})`;
						break;
					case 'Recommendation':
						color = `rgba(247, 203, 115, ${opacity})`;
						break;
					case 'Application':
						color = `rgba(247, 203, 115, ${opacity})`;
						break;
					case 'Complete':
						color = `rgba(23, 184, 144, ${opacity})`;
						break;
					case 'Completed':
						color = `rgba(23, 184, 144, ${opacity})`;
						break;
					case 'After Care':
						color = `rgba(23, 184, 144, ${opacity})`;
						break;
					case 'Declined':
						color = `rgba(234, 99, 140, ${opacity})`;
						break;
					case 'Lead':
					default: // Default to 'Lead' if none of the conditions match
						text = 'Lead';
						color = `rgba(103, 0, 86, ${opacity})`;
				}

				return {
					text,
					color
				};
			},

			/**
			 * @name submitDeleteClient
			 * @description Delete client
			 */
			submitDeleteClient(client) {
				this.$refs.confirm
					.open('Delete Client', `Are you sure you wish to delete client ${client.name}?`, { app_bar_color: 'error', system_bar_color: 'error darken-2' })
					.then(() => {
						this.$set(client, 'loading', true);
						const promises = [this.deleteClient(client.id)];
						if (client.association) promises.push(this.deleteClient(client.association.id));

						Promise.all(promises)
							.then(() => (ElementTools.fireNotification(this.$el, 'success', `Client ${client.name} successfully deleted`), this.loadClients()))
							.catch((error) => {
								ElementTools.fireNotification(this.$el, 'error', error.data || `Failed to delete client ${client.name}`);
								this.$set(client, 'loading', undefined);
							});
					});
			}
		}
	};
</script>

<style scoped lang="scss">
	::v-deep .v-data-table-header th .v-data-table-header__icon {
		color: #fff !important;
	}

	::v-deep .v-pagination {
		justify-content: start !important;
	}
</style>
