<!--
	@name app-summer
	@description Campaign page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<!-- Hero -->
		<template v-if="campaign === 'summer-2024-01'">
			<summer-202401-hero />
		</template>
		<div
			v-else
			class="py-15"
			style="
				position: relative;
				overflow: hidden;
				z-index: 1;
			"
			:style="
				`border-bottom: ${content[theme].hero.border || '20px solid rgb(30, 176, 225)'}; background: ${content[theme].hero.background} url('${VUE_APP_S3_AIR_ASSETS_URL}/${content[theme].hero.image}');`
			"
		>
			<v-container style="position: relative; z-index: 2">
				<div class="d-inline-block py-8 px-0 ml-md-10 rounded" :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '50%' : '80%' }">
					<div class="text-h3 text-md-h2 font-weight-bold white--text" :style="{ 'line-height': $vuetify.breakpoint.mdAndUp ? '70px' : '55px' }">
						{{ content[theme].hero.copy }}
					</div>
				</div>
			</v-container>
		</div>

		<!-- About Us -->
		<v-container class="py-8" :class="{ 'py-15': $vuetify.breakpoint.mdAndUp }">
			<template v-if="theme === 'libf'">
				<campaign-libf />
			</template>
			<template v-else>
				<div :class="content[theme].intro.class">
					<span @click="handleDynamicClick" v-html="$sanitize(content[theme].intro.copy)" />
				</div>
			</template>

			<div class="d-flex flex-wrap ma-n3">
				<v-hover
					v-for="section in content[theme].sections"
					v-slot="{ hover }"
					:key="section.id"
				>
					<v-card
						class="ga--trigger ga--campaign-click ma-3 pa-2 flex-grow-1 flex-shrink-1 d-flex flex-column"
						:class="[`ga--section-${section.id}`, hover ? 'grey darken-4' : 'background-rotation']"
						:style="{ 'flex-basis': $vuetify.breakpoint.lgAndUp ? '30%' : $vuetify.breakpoint.mdOnly ? '40%' : '100%' }"
						hover
						@click="section.show ? scrollToAnchorPoint(section.id) : scrollToAnchorPoint('contact', section.id)"
					>
						<v-card-title class="text-h5 font-weight-bold white--text flex-grow-0">
							{{ section.title }}
						</v-card-title>

						<v-card-text class="text-body-2 white--text flex-grow-1" v-html="$sanitize(section.copy)" />

						<v-card-actions class="pt-0 flex-grow-0">
							<v-btn class="ga--trigger ga--campaign-click" :class="`ga--section-${section.id}`" color="white" block @click="section.show ? scrollToAnchorPoint(section.id) : scrollToAnchorPoint('contact', section.id)">
								Learn more
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-hover>
			</div>

			<div v-if="content[theme].contact" class="d-flex align-center flex-column mt-12">
				<v-row style="max-width: 700px;">
					<v-col class="shrink">
						<v-avatar size="140px">
							<img :alt="content[theme].contact.title" :src="content[theme].contact.image | urlize" />
						</v-avatar>
					</v-col>
					<v-col class="grow align-content-center">
						<p class="text-h5 font-weight-bold mb-3">
							{{ content[theme].contact.title }}
						</p>
						<p class="text-body-1 font-weight-regular mb-4">
							{{ content[theme].contact.copy }}
						</p>
						<v-btn class="ga--trigger ga--campaign-click ga--callback-general" color="primary" @click="scrollToAnchorPoint('contact', 'general')">
							Arrange a call back
						</v-btn>
					</v-col>
				</v-row>
			</div>
		</v-container>

		<!-- Partner logos -->
		<div v-if="content[theme].partners" class="grey lighten-2">
			<v-container class="py-10" :class="{ 'py-15': $vuetify.breakpoint.mdAndUp }">
				<p class="text-h5 font-weight-bold text-center mb-10">
					<span class="link--special">Trusted</span>
					<span class="ml-n1">by well known companies</span>
				</p>
				<div class="logos d-flex justify-center">
					<div v-for="logo in [1,2,3,4,5,6]" :key="logo" class="logo mx-5">
						<img :src="'logo/brand/logo.png' | urlize" width="130px" alt="" />
					</div>
				</div>
			</v-container>
		</div>
		<v-divider v-else inset />

		<!-- Testimonial -->
		<template v-if="testimonial && ['trmn-a-1408024', 'trmn-b-1408024'].includes(campaign) && content[theme].testimonials">
			<trmn-1408024-testimonial :testimonials="content[theme].testimonials" />
		</template>
		<div v-else-if="testimonial">
			<v-container class="py-8" :class="{ 'py-15': $vuetify.breakpoint.mdAndUp }">
				<v-sheet dark class="background-gradient rounded pa-6">
					<v-row class="px-10">
						<v-col class="grow align-content-center pr-10">
							<v-icon size="60" color="success" class="mb-0" style="position: relative; left: -8px; top: -8px;">
								mdi-format-quote-open
							</v-icon>
							<p class="text-h5 font-weight-bold mb-0" v-html="autoLink($sanitize(testimonial.copy))" />
							<p v-if="testimonial.name" class="text-body-2 font-weight-light mt-5 mb-0">
								{{ testimonial.name }}
							</p>
							<p v-if="testimonial.position" class="text-body-2 font-weight-light mb-0">
								{{ testimonial.position }}
							</p>
						</v-col>
						<v-col class="shrink align-content-center d-none d-md-block">
							<v-avatar
								size="200px"
							>
								<img
									:alt="testimonial.name"
									:src="testimonial.image | urlize"
								/>
							</v-avatar>
						</v-col>
					</v-row>
				</v-sheet>
			</v-container>
		</div>

		<!-- Content sections -->
		<summer-section
			v-for="section, sectionIndex in displaySections"
			:id="section.id"
			:key="sectionIndex"
			:ref="section.id"
			:reverse="sectionIndex%2>0"
			:subtitle="section.title"
			:image="section.image ? `${VUE_APP_S3_AIR_ASSETS_URL}${section.image}` : null"
			:video="section.video ? `${VUE_APP_S3_AIR_ASSETS_URL}${section.video}`: null"
			:poster="section.poster ? `${VUE_APP_S3_AIR_ASSETS_URL}${section.poster}` : null"
			class="scroll-margin--fixed-header-offset"
		>
			<template #copy>
				<slot>
					<div class="text-body-1" v-html="$sanitize(section.main)" />
					<div class="ma-n2 pt-6">
						<v-btn v-for="cta, index in section.ctas" :key="index" :color="cta.color" class="ma-2" @click="handleCtaClick(cta)">
							{{ cta.copy }}
						</v-btn>
					</div>
				</slot>
			</template>
		</summer-section>

		<template v-if="campaign === 'summer-2024-01'">
			<summer-202401-banner />
		</template>

		<!-- Contact form -->
		<div id="contact" ref="contact" class="header--waves background-gradient scroll-margin--fixed-header-offset">
			<!--Content before waves-->
			<div class="inner-header flex">
				<v-container class="py-8" :class="{ 'py-15': $vuetify.breakpoint.mdAndUp }">
					<div class="text-md-center d-flex align-center flex-column">
						<div class="grey lighten-4 pa-8 rounded elevation-6" style="max-width: 700px;">
							<v-fade-transition>
								<div v-if="success">
									<p class="text-h4 font-weight-bold mb-4 mx-auto" style="max-width: 500px;">
										Thank you for reaching out!
									</p>
									<p class="text-h6 font-weight-regular mb-6 mx-auto" style="max-width: 500px;">
										Your email has been sent. We'll get back to you shortly. In the meantime, check out <a class="ga--trigger ga--campaign-click ga--events" @click="$router.push('/events')">upcoming events</a> or read more <a class="ga--trigger ga--campaign-click ga--academy" @click="$router.push('/air-academy')">about us</a>.
									</p>
									<v-icon color="success" size="60">
										mdi-check-circle
									</v-icon>
								</div>
							</v-fade-transition>
							<template v-if="!success">
								<p v-if="content[theme].form.title" class="text-h4 font-weight-regular mb-3 mx-auto" v-html="$sanitize(content[theme].form.title)" />
								<p v-if="content[theme].form.copy" class="text-h6 font-weight-regular mb-6 mx-6" v-html="$sanitize(content[theme].form.copy)" />
								<validation-observer ref="observer">
									<v-form @submit.prevent="submit">
										<v-row>
											<v-col cols="12" md="6">
												<validation-provider v-slot="{ errors }" name="First Name" rules="required">
													<v-text-field v-model="firstName" :disabled="disabled" :error-messages="errors" label="First Name" :hide-details="!errors?.length" filled />
												</validation-provider>
											</v-col>
											<v-col cols="12" md="6">
												<validation-provider v-slot="{ errors }" name="Last Name" rules="required">
													<v-text-field v-model="lastName" :disabled="disabled" :error-messages="errors" label="Last Name" :hide-details="!errors?.length" filled />
												</validation-provider>
											</v-col>
											<v-col cols="12">
												<validation-provider v-slot="{ errors }" name="Firm Name" rules="required">
													<v-text-field v-model="firmName" :disabled="disabled" :error-messages="errors" label="Firm Name" :hide-details="!errors?.length" filled />
												</validation-provider>
											</v-col>
											<v-col cols="12">
												<validation-provider v-slot="{ errors }" name="FCA Number" rules="required|numeric|min:6|max:7">
													<v-text-field v-model="fcaNumber" :disabled="disabled" :error-messages="errors" label="FCA Number" :hide-details="!errors?.length" filled />
												</validation-provider>
											</v-col>
											<v-col cols="12">
												<validation-provider v-slot="{ errors }" name="Email" rules="required|email">
													<v-text-field v-model="email" :disabled="disabled" :error-messages="errors" label="Email" :hide-details="!errors?.length" filled />
												</validation-provider>
											</v-col>
											<v-col cols="12">
												<validation-provider v-slot="{ errors }" name="Phone" rules="required|numeric">
													<v-text-field v-model="phone" :disabled="disabled" :error-messages="errors" label="Phone" :hide-details="!errors?.length" filled />
												</validation-provider>
											</v-col>
											<v-col cols="12">
												<v-btn class="ga--trigger ga--campaign-click ga--form-submit white--text" color="primary" :disabled="disabled" :loading="loading" type="submit" large block>
													Submit for a call back
												</v-btn>
											</v-col>
										</v-row>
									</v-form>
								</validation-observer>
							</template>
						</div>
					</div>
				</v-container>
				<v-container v-if="['trmn-a-1408024', 'trmn-b-1408024'].includes(campaign)" class="pt-0 pb-8" :class="{ 'pb-15': $vuetify.breakpoint.mdAndUp }">
					<v-row
						no-gutters
						class="rounded"
						style="border-left: 3px solid #ffffff; border-right: 3px solid #ffffff;"
					>
						<v-col class="px-6 py-3">
							<p class="text-h6 font-weight-regular mb-0" style="color: #ffffff;">
								The team at The Right Mortgage & Protection Network are here to help our members succeed in this market.
								Please don't hesitate to reach out to us - call <strong>01564 732 744</strong> or email <a class="font-weight-bold" style="color: #219ebc;" href="mailto:victoria.clark@therightmortgage.co.uk">victoria.clark@therightmortgage.co.uk</a>.
							</p>
						</v-col>
					</v-row>
				</v-container>
			</div>

			<!--Waves Container-->
			<div>
				<svg
					class="waves"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					viewBox="0 24 150 28"
					preserveAspectRatio="none"
					shape-rendering="auto"
				>
					<defs>
						<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
					</defs>
					<g class="parallax">
						<use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
						<use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
						<use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
						<use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
					</g>
				</svg>
			</div>
			<!--Waves end-->
		</div>
		<div class="body--waves d-flex" />

		<!-- Dialogs -->
		<common-dialog v-if="selectedVideo" ref="videoDialog" :max-width="960" @closed="selectedVideo = null">
			<template #header>
				{{ selectedVideo?.copy }}
			</template>
			<template #body>
				<div class="video-container">
					<video :controls="true" :src="selectedVideo?.src" :poster="selectedVideo?.poster | urlize" width="100%" height="auto" autoplay />
				</div>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import SummerSection from '@/component/app/air-later-life/summer/section';
	import CommonDialog from '@/component/common/dialog';
	import CampaignContent from '@/component/app/air-later-life/summer/content.json';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import { EventBus, ElementTools } from '@/utils';
	import Autolinker from 'autolinker';

	import Trmn1408024Testimonial from '@/component/app/air-later-life/summer/campaigns/trmn1408024/testimonial';
	import Summer202401Hero from '@/component/app/air-later-life/summer/campaigns/summer202401/hero';
	import Summer202401Banner from '@/component/app/air-later-life/summer/campaigns/summer202401/banner';
	import CampaignLibf from '@/component/app/air-later-life/summer/campaigns/libf';

	export default {
		name: 'app-summer',

		components: {
			SummerSection,
			CommonDialog,
            ValidationObserver,
            ValidationProvider,
			Trmn1408024Testimonial,
			Summer202401Hero,
			Summer202401Banner,
			CampaignLibf
		},

		data() {
			return {
                VUE_APP_S3_AIR_ASSETS_URL: process.env.VUE_APP_S3_AIR_ASSETS_URL,
                theme: 'default',
				campaign: null,
				campaigns: [
					'libf',
					'dt-ns-02082024',
					'dt-02082024',
					'summer-2024-01',
					'trmn-a-1408024',
					'trmn-b-1408024'
				],
				subject: null,
                firstName: '',
				lastName: '',
				firmName: '',
				fcaNumber: '',
				email: '',
				phone: '',
				message: '',
				loading: false,
				success: false,
				disabled: false,
				selectedVideo: null,
				videos: [
					{
						title: 'Celebrating a decade',
						copy: 'Celebrating a decade of professional development with the Air Academy',
						src: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/manager_documents/65568bfd3ba980849ff38ad31a8c1fa1.mp4`,
						poster: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/manager_documents/76cd26f4f6e9da657e7bde2eb964c709.png`,
						controls: false
					},
					{
						title: 'Affordability module',
						copy: 'Bringing affordability to the forefront of your advice process with our 10th Academy module',
						src: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/manager_documents/90e581e6d22471df00df811ecce6f16e.mp4`,
						poster: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/manager_documents/fe1387496eb4a6440bb261344762905a.png`,
						controls: false
					},
					{
						title: 'LIBF Trusted Partner',
						copy: ' LIBF Trusted Partner: Establishing excellence with Air Academy',
						src: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/cms/Maverix+-+Trusted+Partner.mp4`,
						poster: `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/manager_documents/69c8eca99dfe81a6627eb94a6dc0ad64.png`,
						controls: false
					}
				]
			};
		},

		computed: {
            content() {
                return CampaignContent;
            },

			displaySections() {
				return this.content[this.theme].sections.filter((s => s.show));
			},

			testimonials() {
				if (!this.content[this.theme].testimonials) return false;
				return this.content[this.theme].testimonials;
			},

			testimonial() {
				if (!this.testimonials) return false;
				return this.testimonials[0];
			}
		},

		created() {
			this.setTheme();
		},

		methods: {
			...mapActions('ContactCampaign', ['submitContactCampaign']),

			setTheme() {
				if (!this.$route.query || !this.$route.query.utm_id) this.$router.push('/');
				if (!this.campaigns.includes(this.$route.query.utm_id)) this.$router.push('/');
				this.campaign = this.$route.query.utm_id;
				if (this.campaign in this.content) this.theme = this.campaign;
			},

            scrollToAnchorPoint(refName, subject = null) {
				if (subject) this.subject = subject;
                document.getElementById(refName).scrollIntoView({ behavior: 'smooth'});
            },

            handleCtaClick(cta) {
				if (cta.function) {
					let fnctn = cta.function;
					if (fnctn === 'arrangeDemo') this.scrollToAnchorPoint('contact');
					if (fnctn === 'openChat') EventBus.$emit('open-chat');
				} else if (cta.route) {
					let route = cta.route;
					this.$router.push(route);
				} else if (cta.url) {
					let url = cta.url;
					window.open(url, '_blank');
				}
			},

			handleDynamicClick(e) {
				EventBus.$emit('handle-dynamic-click', e);
			},

			playVideo(video) {
				this.selectedVideo = video;

				this.$nextTick(() => {
					this.$refs.videoDialog.open();
				});
			},

			submit() {
				this.$refs.observer.validate().then(async(valid) => {
					if (!valid) return false;

					this.success = false;
					this.loading = true;

					this.submitContactCampaign({
						firstName: this.firstName,
						lastName: this.lastName,
						firmName: this.firmName,
						fcaNumber: this.fcaNumber,
						email: this.email,
						phone: this.phone,
						campaign: this.campaign,
						subject: this.subject
					})
						.then(() => {
							this.success = true;
							this.resetForm();
						})
						.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occurred. Please try again later.'))
						.finally(() => {
							this.subject = null;
							this.loading = false;
						});
				});
			},

			resetForm() {
				this.subject = null;
				this.firstName = '';
				this.lastName = '';
				this.firmName = '';
				this.fcaNumber = '';
				this.email = '';
				this.phone = '';
				this.disabled = true;
				this.$refs.observer.reset();
			},

			/**
			 * @name autoLink
			 * @description Purify html
			 */
			autoLink(text) {
				return Autolinker.link(text, { stripPrefix: false });
			}
		}
	};
</script>

<style lang="scss" scoped>
    .header--waves {
        position:relative;
    }

    .inner-header {
        width:100%;
        margin: 0;
        padding: 0;
    }

    .waves {
        position:relative;
        width: 100%;
        height:50px;
        margin-bottom:-7px; /*Fix for safari gap*/
        min-height:50px;
        max-height:50px;
    }

    .body--waves {
        position:relative;
        height:50px;
        text-align:center;
        background-color: white;
    }

	.video-iframe {
		width: 100%;
		height: 100%;
		border: none;
	}

	.video-container {
		width: 100%;
		height: 100%;
		aspect-ratio: 1.77777;
	}

	.play-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0.5;
		cursor: pointer;
		&:hover {
			opacity: 0.8;
		}
	}

    /* Animation */
    .parallax > use {
        animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
    }
    .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
    }
    .parallax > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
    }
    .parallax > use:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
    }
    .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
    }
    @keyframes move-forever {
        0% {
        transform: translate3d(-90px,0,0);
        }
        100% {
            transform: translate3d(85px,0,0);
        }
    }
    /*Shrinking for mobile*/
    @media (max-width: 768px) {
        .waves {
            height:40px;
            min-height:40px;
        }
        .body--waves {
            height:30vh;
        }
    }

    .scroll-margin--fixed-header-offset {
        scroll-margin: 140px;
    }

    .link--special {
		background-image: url($VUE_APP_S3_AIR_ASSETS_URL + '/cms/texthighlight.svg');
        background-position: 50% 50%;
        padding: 8px 16px;
		margin: 0px !important;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .logos {
        .logo {
            mix-blend-mode: luminosity;
            opacity: 0.75;

            &:nth-child(odd) {
                mix-blend-mode: multiply;
            }
        }
    }

	#overlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: transparent;
		background-image: linear-gradient(340deg, #6464645c 45%, #1eb0e1 100%);
		opacity: 1;
		mix-blend-mode: multiply;
		transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
	}

	@function tint($color, $percentage) {
		@return mix(white, $color, $percentage);
	}
</style>
