<!--
	@name app-client-dashboard-summary
	@description Client Dashboard Tabs
	@date 2020/07/07
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-card class="d-flex flex-wrap" flat tile>
			<template v-if="!clientMatterTypes().length || !client || !assetList">
				<v-card v-for="key in 3" :key="key" class="ma-2 flex-grow-1 elevation-3">
					<v-skeleton-loader type="article" />
				</v-card>
			</template>

			<template v-else>
				<v-row class="ma-n3">
					<v-col v-for="(item, index) in clientMatterTypes()" :key="index" cols="4">
						<v-card height="100%" hover link @click="serviceRouteHandler(item.nameUnique)">
							<v-system-bar height="16" :color="isSectionActive(item) ? 'success' : 'error'" />
							<v-card-title class="text-body-1 font-weight-bold pa-5 pb-2">
								{{ item.data.displayName || item.name }}
							</v-card-title>
							<v-card-text class="pa-5 pt-0">
								{{ item.data.introduction | truncate(150) }}
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</template>
		</v-card>

		<app-client-service-home-reversion-plan
			ref="homeReversionPlan"
			:items="homeReversionPlans"
			:client="client"
			:association="association"
			:asset-list="assetList"
			:workflow-asset="workflowAsset"
			@submit="loadHomeReversionPlans(true)"
		/>
		<app-client-service-care-plan
			ref="carePlans"
			:items="carePlans"
			:client="client"
			:association="association"
			:asset-list="assetList"
			:workflow-asset="workflowAsset"
			@submit="loadCarePlans(true)"
		/>
		<app-client-service-loan ref="loans" />
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { camelCase } from 'lodash';
	import AppClientServiceLoan from '@/component/app/client/service/loan';
	import AppClientServiceCarePlan from '@/component/app/client/service/care-plan';
	import AppClientServiceHomeReversionPlan from '@/component/app/client/service/home-reversion-plan';

	export default {
		name: 'app-client-dashboard-summary',

		components: {
			AppClientServiceLoan,
			AppClientServiceCarePlan,
			AppClientServiceHomeReversionPlan
		},

		props: {
			client: { type: Object, default: null },
			association: { type: Object, default: null },
			assetList: { type: Array, default: null },
			workflowAsset: { type: Object, default: null },
			items: { type: Array, default: null }
		},

		data() {
			return {
				homeReversionPlans: null,
				carePlans: null
			};
		},

		computed: {
			...mapGetters('AppMatterType', ['clientMatterTypes']),

			service() {
				return this.$route.query.service;
			},

			activeService() {
				return !!(this.service && this.client && this.assetList);
			}
		},

		watch: {
			activeService: {
				handler() {
					this.openServiceDialog();
				}
			}
		},

		mounted() {
			if (this.activeService) this.openServiceDialog();
			this.loadHomeReversionPlans();
			this.loadCarePlans();
		},

		methods: {
			...mapActions('AppHomeReversionPlan', ['loadHomeReversionPlanList']),
			...mapActions('AppCarePlan', ['loadCarePlanList']),

			loadHomeReversionPlans(template) {
				const entityIds = [this.client.id];
				if (this.association) entityIds.push(this.association.id);
				this.loadHomeReversionPlanList({ entityIds }).then(({ data }) => {
					this.homeReversionPlans = data;
					if (template) this.$refs.homeReversionPlan.open();
				});
			},

			loadCarePlans(template) {
				const entityIds = [this.client.id];
				if (this.association) entityIds.push(this.association.id);
				return this.loadCarePlanList({ entityIds }).then(({ data }) => {
					this.carePlans = data;
					if (template) this.$refs.carePlans.open();
				});
			},

			/**
			 * @name isSectionActive
			 * @description is section active
			 * @param ref
			 */
			isSectionActive(item) {
				if (item.nameUnique === 'home_reversion_plan') return this.homeReversionPlans?.length;
				if (item.nameUnique === 'care_plans') return this.carePlans?.length;
				else return false;
			},

			serviceRouteHandler(matterTypeNameUnique) {
				let ref = camelCase(matterTypeNameUnique);
				this.$router.push({ query: Object.assign({}, this.$route.query, { service: ref }) });
			},

			openServiceDialog() {
				if (this.activeService && this.$refs[`${this.service}`]) this.$refs[`${this.service}`].open();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.services {
		flex-basis: 30%;
	}
</style>
