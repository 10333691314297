import Vuex from 'vuex';
import Vue from 'vue';

// Updated
import Account from './modules/Account';
import AccountOrganisation from './modules/Account/Organisation';
import AccountOrganisationTradingStyle from './modules/Account/Organisation/TradingStyle';
import AccountOrganisationDomain from './modules/Account/Organisation/Domain';
import AccountOrganisationUser from './modules/Account/Organisation/User';
import AccountOrganisationUserApi from './modules/Account/Organisation/User/Api';
import AccountOrganisationWebhook from './modules/Account/Organisation/Webhook';
import AccountRegistrationComplete from './modules/Account/Registration/Complete';
import AccountRegistrationAcceptInvitation from './modules/Account/Registration/AcceptInvitation';
import AccountRegistrationRegenerate from './modules/Account/Registration/Regenerate';
import AccountRegistrationVerify from './modules/Account/Registration/Verify';
import AccountUser from './modules/Account/User';
import AccountUserTradingStyle from './modules/Account/User/TradingStyle';
import AccountUserInvitation from './modules/Account/UserInvitation';
import AccountFirmJoinRequest from './modules/Account/Registration/FirmJoinRequest';
import AccountUserRecommendation from './modules/Account/UserRecommendation';
import AccountSecurityQuestion from './modules/Account/SecurityQuestion';
import Affordability from './modules/Affordability';
import App from './modules/App';
import AppCalculator from './modules/App/Calculator';
import AppCarePlan from './modules/App/CarePlan';
import AppClient from './modules/App/Client';
import AppClientConnected from './modules/App/Client/Connected';
import AppClientAsset from './modules/App/Client/Asset';
import AppClientHealth from './modules/App/Client/Health';
import AppConveyancing from './modules/App/Conveyancing';
import AppLpa from './modules/App/Lpa';
import AppSourceIteration from './modules/App/Source/Iteration';
import AppSourceProcess from './modules/App/Source/Process';
import AppSourceResult from './modules/App/SourceResult';
import AppStateBenefit from './modules/App/StateBenefit';
import AppHomeReversionPlan from './modules/App/HomeReversionPlan';
import AppInterface from './modules/App/Interface';
import AppInterfaceUser from './modules/App/InterfaceUser';
import AppMatter from './modules/App/Matter';
import AppMatterType from './modules/App/MatterType';
import AppNotification from './modules/App/Notification';
import AppProduct from './modules/App/Product';
import AppReward from './modules/App/Reward';
import AppWebinar from './modules/App/Webinar';
import AppWill from './modules/App/Will';
import AppWorkflow from './modules/App/Workflow';
import Case from './modules/Case';
import AppCompletion from './modules/App/Completion';
import CmsBanner from './modules/Cms/Banner';
import CmsConfig from './modules/Cms/Config';
import CmsContent from './modules/Cms/Content';
import CmsData from './modules/Cms/Data';
import CmsDownload from './modules/Cms/Download';
import CmsEvent from './modules/Cms/Event';
import CmsForm from './modules/Cms/Form';
import CmsInsight from './modules/Cms/Insight';
import CmsMedia from './modules/Cms/Media';
import CmsMenu from './modules/Cms/Menu';
import CmsNews from './modules/Cms/News';
import CmsProductUpdate from './modules/Cms/ProductUpdate';
import CmsShortcut from './modules/Cms/Shortcut';
import CmsSite from './modules/Cms/Site';
import CmsKfi from './modules/Cms/Kfi';
import CmsChangelog from './modules/Cms/Changelog';
import CmsCourse from './modules/Cms/Course';
import CmsAirMail from './modules/Cms/AirMail';
import Enquiry from './modules/Enquiry';
import FactFind from './modules/FactFind';
import Vulnerability from './modules/Vulnerability';
import LifetimeMortgageApplication from './modules/LifetimeMortgage/Application';
import LifetimeMortgageKfi from './modules/LifetimeMortgage/Kfi';
import LifetimeMortgageSourcing from './modules/LifetimeMortgage/Sourcing';
import SystemRegisterNavigatorInterest from './modules/System/RegisterNavigatorInterest';
import SignUpManifesto from './modules/System/SignUpManifesto';
import PartnerKnowledgeBank from './modules/Partner/KnowledgeBank';
import File from './modules/File';
import LogActivityUser from './modules/Log/ActivityUser';
import Oauth from './modules/Oauth';
import Option from './modules/Option';
import PropertyDashboard from './modules/PropertyDashboard';
import CmsCpdTrainingItem from './modules/Cms/CpdTrainingItem';
import ContactCampaign from './modules/System/ContactCampaign';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
	modules: {
		Account,
		AccountOrganisation,
		AccountOrganisationTradingStyle,
		AccountOrganisationDomain,
		AccountOrganisationUser,
		AccountOrganisationUserApi,
		AccountOrganisationWebhook,
		AccountRegistrationAcceptInvitation,
		AccountRegistrationComplete,
		AccountRegistrationRegenerate,
		AccountRegistrationVerify,
		AccountUser,
		AccountUserTradingStyle,
		AccountUserInvitation,
		AccountFirmJoinRequest,
		AccountUserRecommendation,
		AccountSecurityQuestion,
		Affordability,
		App,
		AppCalculator,
		AppCarePlan,
		AppClient,
		AppClientConnected,
		AppClientAsset,
		AppClientHealth,
		AppConveyancing,
		AppHomeReversionPlan,
		AppLpa,
		AppSourceResult,
		AppSourceIteration,
		AppSourceProcess,
		AppStateBenefit,
		AppInterface,
		AppInterfaceUser,
		AppMatter,
		AppMatterType,
		AppNotification,
		AppProduct,
		AppReward,
		AppWebinar,
		AppWill,
		AppWorkflow,
		Case,
		AppCompletion,
		CmsBanner,
		CmsConfig,
		CmsContent,
		CmsData,
		CmsDownload,
		CmsEvent,
		CmsForm,
		CmsInsight,
		CmsMedia,
		CmsMenu,
		CmsNews,
		CmsProductUpdate,
		CmsShortcut,
		CmsSite,
		CmsKfi,
		CmsChangelog,
		CmsCourse,
		CmsAirMail,
		Enquiry,
		FactFind,
		Vulnerability,
		LifetimeMortgageApplication,
		LifetimeMortgageKfi,
		LifetimeMortgageSourcing,
		SignUpManifesto,
		SystemRegisterNavigatorInterest,
		PartnerKnowledgeBank,
		File,
		LogActivityUser,
		Oauth,
		Option,
		PropertyDashboard,
		CmsCpdTrainingItem,
		ContactCampaign
	}
});
