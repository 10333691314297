<!--
	@name common-structure-news
	@description Displays a list of news entries with pageable listings and a detail view
	@date 2020/03/04
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!--
	@example

	<common-structure-news :items="items" :pageSize="pageSize" >
		<template v-slot:title>Your Header</template>
		<template v-slot:detail>Some content</template>
	</common-structure-news>

	[
		{
			"id": "cbad5b84-7744-42f8-bf8f-d69aadd728b5",
			"name": "Join Air Group CEO for a live Q&A",
			"headline": "Stuart will be taking your questions!",
			"story": "Join us for a live Q&A with Air Group's CEO: Stuart Wilson. Taking your questions and discussing the ongoing changes to our industry and the impacts they may have on advisers like you. We look forward to seeing you there: Thursday 30th April, 11am Register here: https://www.eventbrite.co.uk/e/air-group-stuart-wilson-in-conversation-with-later-life-advisers-registration-102983941742",
			"url": null,
			"created": "2020-04-29T10:17:22.000Z",
			"updated": "2020-04-29T10:17:22.000Z",
			"tag": [
				{
					"id": "c657b7f4-f9ad-4983-b93b-578ff60f0569",
					"name": "Air Group",
					"visible": false
				}
			]
		}
	]
-->

<template>
	<div :component="$options.name">
		<div class="d-flex mb-6">
			<div class="left flex-grow-1">
				<h2 v-if="hasTitleSlot" class="text-h5 font-weight-bold mb-2">
					<slot name="title" />
				</h2>
				<p v-if="hasDetailSlot" class="text-body-1 mb-0">
					<slot name="detail" />
				</p>
			</div>
			<div>
				<v-btn-toggle v-if="items.length > pageSize">
					<v-btn small @click="pageDown">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-btn small @click="pageUp">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-btn-toggle>
			</div>
		</div>
		<div class="body">
			<div v-if="items.length" class="d-flex flex-wrap ma-n3">
				<v-card v-for="item in filteredItems" :key="item.id" class="article ma-3 d-flex flex-column flex-grow-1">
					<v-img class="white--text align-end flex-grow-0 grey" height="200px" :src="item.image || 'placeholder/gradient.png' | urlize">
						<v-card-title v-if="item.name">
							{{ item.name | truncate(30) }}
						</v-card-title>
					</v-img>
					<v-card-subtitle class="pb-1">
						Posted: {{ item.postedOn }}
					</v-card-subtitle>
					<v-card-text class="text--primary">
						<div>{{ item.headline | truncate(150) }}</div>
					</v-card-text>
					<v-card-actions class="mt-auto">
						<v-btn
							class="ga--trigger ga--article-opened"
							data-ga-id="dashboard"
							:data-ga-value="item.name"
							color="primary"
							text
							@click="handleReadMore(item)"
						>
							Read More
						</v-btn>
					</v-card-actions>
				</v-card>
			</div>
			<div v-else class="flexer">
				<v-card v-for="i in 2" :key="i" class="item">
					<v-skeleton-loader type="card" />
				</v-card>
			</div>
		</div>
		<app-dialog-news ref="news" />
	</div>
</template>

<script>
	import { PopupWindow } from '@/utils';
	import DateTools from '@/utils/DateTools';
	import AppDialogNews from '@/component/app/dialog/news';

	export default {
		name: 'common-structure-news',

		components: { AppDialogNews },

		props: {
			items: { type: Array, required: true, default: () => [] },
			pageSize: { type: Number, required: false, default: 6 }
		},

		data() {
			return {
				page: 1
			};
		},

		computed: {
			/**
			 * @name filteredItems
			 * @description the items filtered for news
			 */
			filteredItems() {
				return this.items
					.map((item) => ({ ...item, ...{ postedOn: DateTools.stringToLocale(item.updated, 'medium') } }))
					.filter((d, i) => i >= (this.page - 1) * this.pageSize && i < this.page * this.pageSize);
			},

			/**
			 * @name filteredItems
			 * @description does the title slot have content
			 */
			hasTitleSlot() {
				return !!this.$slots.title;
			},

			/**
			 * @name hasDetailSlot
			 * @description does the detail slot have content
			 */
			hasDetailSlot() {
				return !!this.$slots.detail;
			}
		},

		methods: {
			/**
			 * @name imageUrl
			 * @description Return image name
			 * @return {String} An image name
			 */
			image(item) {
				let img = 'group';

				const isAcademy = item.tag.some((t) => t.name === 'Academy');
				const isClub = item.tag.some((t) => t.name === 'Club');

				if (isClub && isAcademy) img = ['club', 'academy'][Math.floor(Math.random() * 2)];
				else if (isClub) img = 'club';
				else if (isAcademy) img = 'academy';

				return img + [1, 2, 3][Math.floor(Math.random() * 3)];
			},

			/**
			 * @name handleReadMore
			 * @description Open external link if available or open dialog
			 */
			handleReadMore(item) {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: 'articleOpened',
					articleTitle: item.name || item.headline
				});

				const url = item.url || `/news/${item.id}`;
				new PopupWindow().open(url, '_blank');
			},

			/**
			 * @name pageDown
			 * @description move page down
			 */
			pageDown() {
				this.page = this.page < 2 ? 1 : this.page - 1;
			},

			/**
			 * @name pageUp
			 * @description move page up
			 */
			pageUp() {
				const pages = Math.ceil(this.items.length / this.pageSize);
				this.page = this.page >= pages ? pages : this.page + 1;
			}
		}
	};
</script>

<style scoped>
	.article {
		width: 30%;
	}
</style>
