<!--
	@name app-dashboard-tab-view-event
	@description Events tab view for the dashboard page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<common-structure-calendar :items="eventList">
			<template #detail>
				You'll find upcoming events diarised here. Click on an event to view further information regarding location, start/end times, booking details and more.
			</template>
		</common-structure-calendar>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import CommonStructureCalendar from '@/component/common/structure/calendar';

	export default {
		name: 'app-dashboard-tab-view-event',

		components: { CommonStructureCalendar },

		computed: {
			...mapState('CmsEvent', ['eventList'])
		}
	};
</script>
