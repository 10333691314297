<!--
	@name app-client-dashboard-workflow-application-activity
	@description Client Application Workflow Activity
	@date 2024/02/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-sheet>
			<v-skeleton-loader v-if="loading" type="list-item-three-line" />

			<v-sheet v-else-if="!applications?.length">
				<v-row>
					<v-col v-if="site && 'usesAirFlow' in site && site.usesAirFlow === false" cols="12">
						<p class="text-body-1 font-weight-bold mb-6">
							The ability to visually oversee and track the latest status of any current applications will help you manage your customers expectations.
						</p>
						<ul class="text-body-1 pl-8 mb-8">
							<li class="mb-2">
								Track the status relating to your current application from “Pending,” through to “Completed”.
							</li>
							<li class="mb-2">
								Download a PDF copy of the recommended product KFI and/or a copy of the Research PDF.
							</li>
							<li class="mb-0">
								An easy to understand and efficient audit trail is delivered in the form of both current status and history of any previous applications.
							</li>
						</ul>
						<v-btn color="primary" @click="getStarted">
							Get started
						</v-btn>
					</v-col>
					<v-col v-else cols="12">
						<video
							v-if="introResource"
							class="d-block ml-10 mb-6"
							:src="introResource.video"
							:poster="introResource.poster"
							controls
							width="50%"
							style="float: right"
						/>
						<p class="text-body-1 font-weight-bold mb-6">
							The ability to visually oversee and track the latest status of any current applications will help you manage your customers expectations.
						</p>
						<ul class="text-body-1 pl-8 mb-6">
							<li class="mb-2">
								Track the status relating to your current application from “Pending,” through to “Completed”.
							</li>
							<li class="mb-2">
								Download a PDF copy of the recommended product KFI and/or a copy of the Research PDF.
							</li>
							<li class="mb-0">
								An easy to understand and efficient audit trail is delivered in the form of both current status and history of any previous applications.
							</li>
						</ul>
						<p class="text-body-1 mb-8">
							Click on the intro video now to gain a deeper insight into how you can navigate AirFlow's Application tile and maximise its benefits and features.
						</p>
						<v-btn color="primary" @click="getStarted">
							Get started
						</v-btn>
						<a class="text-body-2 font-weight-medium d-block mt-4" @click="openChat"> Need more help? Chat with our team. </a>
					</v-col>
				</v-row>
			</v-sheet>

			<div v-else class="pl-4">
				<v-text-field v-if="items.length > 3" v-model="search" label="Search: e.g. Provider or Product name" outlined dense />
				<div class="overflow-y-auto overflow-x-hidden" :class="{ 'height--max': activeItems.length > 3 }">
					<v-timeline align-top dense class="pa-0">
						<v-timeline-item v-for="(item, index) in activeItems" :key="index" :color="item.completed ? 'grey' : 'grey'" small class="py-6">
							<v-row no-gutters>
								<v-col class="pr-8">
									<p class="text-h6 mt-n1 mb-1 line-clamp" style="-webkit-line-clamp: 1">
										<span class="font-weight-bold">
											{{ item.product.name | truncate(50) }}
										</span>
										<v-chip label x-small dark :color="getStatusColor(getStatus(item.status))" style="position: relative; left: 8px; top: -4px">
											{{ getStatus(item.status) }}
										</v-chip>
									</p>
									<p class="text-body-2 mb-3">
										{{ item.product.provider.name }}
									</p>

									<v-timeline align-top dense class="pt-1">
										<v-timeline-item color="white" small fill-dot class="pb-2 remove-dot">
											<template #icon>
												<v-icon color="success">
													mdi-check-bold
												</v-icon>
											</template>
											<v-row no-gutters style="position: relative; top: 2px">
												<v-col class="pa-0" cols="12" sm="2">
													<span class="text-caption plain mb-0 d-block"> Created: </span>
												</v-col>
												<v-col class="pa-0">
													<span class="text-caption plain mb-0 d-block">
														{{ item.kfiRequestSubmitted | moment('MMM Do YYYY, h:mma') }}
													</span>
												</v-col>
											</v-row>
										</v-timeline-item>
										<v-timeline-item color="white" small fill-dot class="pb-2 remove-dot">
											<template #icon>
												<v-icon color="success">
													mdi-check-bold
												</v-icon>
											</template>
											<v-row no-gutters style="position: relative; top: 2px">
												<v-col class="pa-0" cols="12" sm="2">
													<span class="text-caption plain mb-0 d-block"> KFI Received: </span>
												</v-col>
												<v-col class="pa-0">
													<span class="text-caption plain mb-0 d-block">
														{{ item.kfiRecieved | moment('MMM Do YYYY, h:mma') }}
													</span>
												</v-col>
											</v-row>
										</v-timeline-item>
										<v-timeline-item color="white" small fill-dot class="pb-2 remove-dot">
											<template #icon>
												<v-icon color="success">
													mdi-check-bold
												</v-icon>
											</template>
											<v-row no-gutters style="position: relative; top: 2px">
												<v-col class="pa-0" cols="12" sm="2">
													<span class="text-caption plain mb-0 d-block"> Application Submitted: </span>
												</v-col>
												<v-col class="pa-0">
													<span class="text-caption plain mb-0 d-block">
														{{ item.created | moment('MMM Do YYYY, h:mma') }}
													</span>
												</v-col>
											</v-row>
										</v-timeline-item>
									</v-timeline>

									<div class="mt-4 mx-n1">
										<template v-for="(action, index) in getActions(item)">
											<v-tooltip :key="index" bottom content-class="text-center" max-width="300">
												<template #activator="{ on }">
													<v-btn
														v-show="!action.hide"
														class="mx-1"
														:color="action.colour"
														:dark="'dark' in action ? action.dark : false"
														:disabled="action.disabled"
														:icon="'type' in action && action.type === 'icon'"
														:loading="fileLoading[item.iterationId]"
														:small="'small' in action ? action.small : true"
														@click="handleAction(action, item)"
														v-on="on"
													>
														<v-icon v-if="action.icon" :color="'type' in action && action.type === 'icon' ? action.colour : 'white'">
															{{ action.icon }}
														</v-icon>
														<template v-else>
															{{ action.text }}
														</template>
													</v-btn>
												</template>
												<span>{{ action.tooltip }}</span>
											</v-tooltip>
										</template>
									</div>
								</v-col>
								<v-col class="shrink pr-8 d-none">
									<common-base-image-tooltip
										:src="imageUrl(item) | urlize"
										:tooltip="item.product.provider.name"
										width="auto"
										height="auto"
										:max-width="150"
										:max-height="100"
										center
									/>
								</v-col>
							</v-row>
						</v-timeline-item>
					</v-timeline>
				</div>
			</div>
		</v-sheet>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { EventBus, ElementTools } from '@/utils';
	import documentMixin from '@/mixins/document';

	export default {
		name: 'app-client-dashboard-workflow-application-activity',

		components: {},

		mixins: [documentMixin],

		props: {
			client: { type: Object, required: true },
			association: { type: Object, default: null },
			asset: { type: Object, required: true },
			introResource: { type: Object, required: false, default: null }
		},

		data() {
			return {
				loading: true,
				applications: null,
				search: null,
				applicationsLimit: 10,
				fileLoading: {}
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('AppWorkflow', ['getStatusColor']),

			items() {
				return this.applications;
			},

			activeItems() {
				let filtered = this.items;
				if (this.search) {
					filtered = filtered.filter((k) => {
						if (k.product.name.toLowerCase().includes(this.search.toLowerCase())) return true;
						if (k.product.provider.name.toLowerCase().includes(this.search.toLowerCase())) return true;
						return false;
					});
				}
				return filtered.slice(0, this.applicationsLimit);
			}
		},

		created() {
			this.loadApplications();
		},

		methods: {
			...mapActions('LifetimeMortgageApplication', ['fetchApplicationList']),

			loadApplications() {
				this.fetchApplicationList({
					entityIds: [this.asset.id]
				})
					.then(({ data }) => {
						this.applications = data.data;
						this.applications = this.applications.slice(0, this.applicationsLimit);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', `An error occured`))
					.finally(() => {
						this.loading = false;
						if (!this.applications?.length) this.$emit('toggle-intro-resource');
					});
			},

			getStatus(status) {
				if (status == 'Application Submitted') return 'Completed';
				return 'Completed';
			},

			/**
			 * @name imageUrl
			 * @description Return image URL
			 * @return {String}
			 */
			imageUrl(item) {
				const primary = item?.product?.provider?.logo?.find((l) => l.primary);
				if (primary) return `logo/organisation/${primary.name}`;
				return null;
			},

			getActions(item) {
				let actions = [
					{
						title: 'Help',
						function: `openChat`,
						colour: 'blue-grey lighten-4',
						dark: false,
						small: false,
						text: 'contact support',
						tooltip: 'Help'
					},
					{
						title: 'Download KFI',
						function: `fetchDocument`,
						functionParams: item?.documents?.find((doc) => doc?.type === 'keyFactsIllustration'),
						icon: 'mdi-file-key',
						colour: 'info',
						type: 'icon',
						tooltip: 'Download KFI'
					}
				];

				item?.documents
					?.filter((doc) => doc.type === 'supportingDocument')
					?.forEach((doc) => {
						actions.push({
							title: doc.filename,
							function: 'fetchDocument',
							functionParams: doc,
							icon: 'mdi-file-document-plus',
							colour: 'info',
							type: 'icon',
							tooltip: doc.title
						});
					});

				actions.push({
					title: 'Download Research PDF',
					function: 'fetchDocument',
					functionParams: item?.documents?.find((doc) => doc?.type === 'researchPdf'),
					icon: 'mdi-file-table',
					colour: 'info',
					type: 'icon',
					tooltip: 'Download Research PDF'
				});

				return actions;
			},

			/**
			 * @name handleAction
			 * @description execute correct action
			 * @param action item action
			 * @param item item that needs updating
			 */
			handleAction(action, item) {
				if (action.path) this.$router.push(action.path);
				if (action.function) this[action.function](item, action.functionParams);
			},

			/**
			 * @name fetchDocument
			 * @description TODO
			 * @param item
			 * @param data
			 */
			async fetchDocument(item, data) {
				this.$set(this.fileLoading, item.iterationId, true);
				this.downloadDocument({ processId: item?.sourcing?.process?.id, iterationId: item?.sourcing?.iteration?.id, s3Key: data?.s3Key, filename: data?.filename }).then(
					async() => {
						if (!data.s3Key) await this.loadApplications();
						this.$set(this.fileLoading, item.iterationId, false);
					}
				);
			},

			/**
			 * @name openChat
			 */
			openChat() {
				EventBus.$emit('open-chat');
			},

			/**
			 * @name getStarted
			 */
			getStarted() {
				this.$emit('get-started');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.remove-dot {
		::v-deep .v-timeline-item__dot--small {
			height: 0px !important;
			width: 0px !important;
			padding-top: 12px !important;

			.v-timeline-item__inner-dot {
				height: 0px !important;
				width: 0px !important;
				margin: 0 !important;
			}
		}
	}

	.v-timeline-item:has(.v-timeline-item):nth-child(even) {
		background: rgba(30, 176, 225, 0.1);
		background: linear-gradient(90deg, rgba(27, 120, 216, 0.05) 0%, rgba(103, 0, 86, 0.05) 100%);
	}

	.height--max {
		max-height: 32rem;
	}
</style>
