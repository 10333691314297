window.dataLayer = window.dataLayer || [];

import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import iubenda from '@/plugins/iubenda';
import sanitize from '@/plugins/sanitize';
import store from '@/store';
import router from '@/router';
import directives from '@/directives';
import filters from '@/filters';
import App from '@/component';
import validator from '@/validator';
import { browserCompatible, airbrake } from '@/utils';
import GrowthBook from '@/plugins/GrowthBook';
import WebSocketPlugin from '@/plugins/websocket';

Vue.use(GrowthBook);
Vue.use(WebSocketPlugin);

if (process.env.VUE_APP_AIRBRAKE_ENABLED === 'true') {
	Vue.config.errorHandler = function(err, vm, info) {
		airbrake.notify({
			error: err,
			params: { info: info }
		});
	};
}

const EXTERNAL_ROUTES = ['/oauth', '/cookie-policy'];
window._isExternalRoute = !!EXTERNAL_ROUTES.find((url) => window.location.pathname.includes(url));

Vue.config.productionTip = process.env.NODE_ENV === 'production';
if (!Vue.config.productionTip) Vue.config.devtool = 'source-map';

Vue.use(validator);
Vue.use(filters);
Vue.use(directives);
Vue.use(sanitize);

if (!window._isExternalRoute)
	Vue.use(iubenda, {
		consentOnContinuedBrowsing: false,
		countryDetection: true,
		floatingPreferencesButtonDisplay: 'bottom-right',
		gdprAppliesGlobally: false,
		lang: 'en-GB',
		perPurposeConsent: true,
		siteId: 2610265,
		whitelabel: false,
		cookiePolicyId: 38118348,
		cookiePolicyUrl: '/cookie-policy',
		banner: {
			acceptButtonColor: '#1b78d8',
			acceptButtonDisplay: true,
			backgroundColor: '#1C1C1D',
			closeButtonDisplay: false,
			customizeButtonCaptionColor: '#888888',
			customizeButtonColor: '#dddddd',
			customizeButtonDisplay: true,
			logo: null,
			position: 'bottom',
			rejectButtonColor: '#1b78d8',
			rejectButtonDisplay: true,
			textColor: '#FFFFFF'
		}
	});

if (browserCompatible()) new Vue({ store, router, vuetify, render: (h) => h(App) }).$mount('#app');
