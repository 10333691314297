import Vue from 'vue';
import { CmsConfig } from '@/service';

/**
 * @class @name Form
 * @description Exposes menu store module
 */
export default class Config {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			configList: {}
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {
			/**
			 * @name form
			 * @description used to get filtered forms from store
			 * @param {Object} state
			 */
			config:
				(state) =>
				({ type, name }) => {
					if (!state.configList[type]) return {};
					return state.configList[type][name] || {};
				}
		};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setForm
			 * @description used to form on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setConfig: (state, { type, name, data }) => {
				if (!state.configList[type]) Vue.set(state.configList, type, {});
				Vue.set(state.configList[type], name, data);
			}
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 * @async @name loadForm
			 * @param {function} commit
			 */
			async loadConfig({ commit }, { type, name }) {
				const response = await CmsConfig.get(type.replace(/_/g, '-'), name.replace(/_/g, '-'));
				if (!response.error) commit('setConfig', { type, name, data: response.data });
				return response;
			}
		};
	}
}
