<!--
	@name app-common-api-health-item
	@description Load products via API
	@date 2020/07/06
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<v-list-item :component="$options.name" class="white" dense>
			<v-list-item-avatar size="28" class="ma-0">
				<v-icon :color="color" size="27">
					mdi-circle-slice-8
				</v-icon>
			</v-list-item-avatar>
			<v-list-item-content class="pl-3">
				<v-list-item-title class="text-body-1">
					{{ provider.name }}
				</v-list-item-title>
			</v-list-item-content>
			<v-list-item-action>
				<v-list-item-action-text v-if="failed">
					<v-btn text small @click="openDialog">
						View Details
					</v-btn>
				</v-list-item-action-text>
			</v-list-item-action>
		</v-list-item>

		<common-dialog ref="dialog" :max-width="600" :color="color">
			<template #header>
				{{ provider.name }}
			</template>
			<template #body>
				<p class="text-body-1 mb-0">
					{{ message }}
				</p>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-source-result-product-loader-item',

		components: { CommonDialog },

		props: {
			provider: { type: Object, required: true }
		},

		data() {
			return {};
		},

		computed: {
			failed() {
				return ['warning', 'error'].includes(this.provider?.apiHealth?.status?.toLowerCase());
			},

			message() {
				if (this.provider?.apiHealth?.message) return this.provider.apiHealth.message;
				return `This Provider is having issues with their API. Please speka to one of our support team for more details.`;
			},

			color() {
                if(['warning', 'error'].includes(this.provider?.apiHealth?.status?.toLowerCase())) return this.provider.apiHealth.status.toLowerCase();
				return 'success';
			}
		},

		methods: {
			/**
			 * @name openDialog
			 * @description open error details dialog
			 */
			openDialog() {
				this.$refs.dialog.open();
			},

			/**
			 * @name closeDialog
			 * @description close error details dialog
			 */
			closeDialog() {
				this.$refs.dialog.close();
			}
		}
	};
</script>
