import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Changelog
 * @exports Service/Cms/Changelog
 * @description API Changelog Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Changelog {
	/**
	 * @static @name list
	 * @description Get all changelog as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	static list(payload, domain, cache = true) {
		return $http.post('cms/changelog/list', payload, {
			cache,
			cachePath: `table-cms-changelog-${domain}`
		});
	}

	/**
	 * @static @name get
	 * @description Get changelog
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	static get(id) {
		return $http.get(`cms/changelog/${id}`);
	}

	/**
	 * @static @name list
	 * @description Get all changelog as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	static getChangelogCategories(payload, domain, cache = true) {
		return $http.post('cms/changelog-category/list', payload, {
			cache,
			cachePath: `table-cms-changelog-category-${domain}`
		});
	}

	/**
	 * @static @name addChangelogLike
	 * @description Add a changelog like to a specific changelog item
	 * @returns {Object} Promise a http response
	 */
	static addChangelogLike(payload) {
		return $http.post('cms/changelog-like', payload);
	}

	/**
	 * @static @name addChangelogComment
	 * @description Add a changelog like to a specific changelog item
	 * @returns {Object} Promise a http response
	 */
	static addChangelogComment(payload) {
		return $http.post('cms/changelog-comment', payload);
	}

	/**
	 * @static @name listLikeCounts
	 * @description Get like counts for given changelog items
	 * @returns {Object} Promise a http response
	 */
	static listLikeCounts(payload) {
		return $http.post('cms/changelog-like/list-counts', payload);
	}

	/**
	 * @static @name listCommentCounts
	 * @description Get comment counts for given changelog items
	 * @returns {Object} Promise a http response
	 */
	static listCommentCounts(payload) {
		return $http.post('cms/changelog-comment/list-counts', payload);
	}
}
