<!--
	@name common-base-dynamic-form
	@description Dynamically created form component with a schema
	@date 2020/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<validation-observer v-if="formSchema.length" ref="observer">
			<form @submit.prevent="submit">
				<v-row>
					<v-col v-for="field in formSchema" v-show="getDisplayCondition(field)" :key="field.name" :class="field.colClass" :cols="field.sm" :sm="field.sm">
						<validation-provider v-slot="{ errors }" :name="field.display" :rules="getRules(field, true)" :vid="field.ref" :debounce="getDebounceValue(field.rules)">
							<address-search
								v-if="field.name === 'propertySearch'"
								:model="formData"
								:value="formData[field.name]"
								:label="field.display"
								:disabled="field.disabled || getDisabledCondition(field)"
								:hide-details="!errors.length"
								:error-messages="errors"
								:country-options="countryOptions"
							/>

							<h1 v-else-if="field.type === 'title'" :class="[field.class, 'mb-0']">
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-chip v-if="field.new" label small class="mr-1 pink--text yellow px-2" v-on="on">
											NEW
										</v-chip>
									</template>
									<span>{{ field.new }}</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-chip v-if="field.updated" label small class="mr-1 blue--text teal accent-2" v-on="on">
											UPDATED
										</v-chip>
									</template>
									<span>{{ field.updated }}</span>
								</v-tooltip>
								{{ field.display }}
							</h1>

							<h2 v-else-if="field.type === 'subtitle'" :class="field.class">
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-chip v-if="field.new" label small class="mr-1 pink--text yellow px-2" v-on="on">
											NEW
										</v-chip>
									</template>
									<span>{{ field.new }}</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-chip v-if="field.updated" label small class="mr-1 blue--text teal accent-2" v-on="on">
											UPDATED
										</v-chip>
									</template>
									<span>{{ field.updated }}</span>
								</v-tooltip>
								{{ field.display }}
							</h2>

							<p v-else-if="field.type === 'paragraph'" :class="field.class">
								<v-tooltip v-if="field.tooltip" :max-width="700" :color="field.tooltip.color" top>
									<template #activator="{ on }">
										<span v-on="on">{{ field.display }}</span>
									</template>
									<div :class="field.tooltip.textClass" v-html="$sanitize(field.tooltip.text)" />
								</v-tooltip>
								<span v-else>{{ field.display }}</span>
							</p>

							<v-alert
								v-else-if="field.type === 'alert'"
								class="mb-0"
								:class="field.class"
								:text="field.alertText || false"
								:prominent="field.alertProminent || false"
								:dense="field.alertDense || true"
								:type="field.alertType || 'info'"
								v-html="$sanitize(field.display)"
							/>

							<!-- eslint-disable vue/no-v-html -->
							<div v-else-if="field.type === 'html'" :class="field.class" @click="handleDynamicClick" v-html="$sanitize(field.display)" />

							<v-divider v-else-if="field.type === 'divider'" :class="field.class" />

							<!-- Password Strength Component -->
							<common-base-password
								v-else-if="['passwordStrength'].includes(field.type)"
								:ref="field.ref"
								:rules="getRules(field, true)"
								:model.sync="formData[field.name]"
								:field="field"
								:disabled="field.disabled || getDisabledCondition(field)"
								:error="errors"
							/>

							<!-- Text Component -->
							<v-text-field
								v-else-if="field.type === 'text' && field.name !== 'property_search'"
								:ref="field.ref"
								v-model="formData[field.name]"
								:error-messages="customError[field.name] || errors"
								:label="field.display"
								:placeholder="field.placeholder"
								:type="field.type"
								:hide-details="!errors.length && !field.hint && !customError[field.name]"
								:disabled="field.disabled || getDisabledCondition(field)"
								:prefix="field.prefix"
								:readonly="field.readonly"
								:prepend-icon="field.prependIcon"
								:prepend-inner-icon="field.prependInnerIcon"
								:suffix="field.suffix"
								:counter="field.counter"
								:hint="field.hint"
								persistent-hint
								outlined
								:dense="field.dense === null || field.dense !== false"
								@input="field.onInput ? onInput($event, field.onInput) : false"
							/>

							<!-- Password Component -->
							<v-text-field
								v-else-if="field.type === 'password'"
								:ref="field.ref"
								v-model="formData[field.name]"
								:error-messages="errors"
								:label="field.display"
								:placeholder="field.placeholder"
								:hide-details="!errors.length && !field.hint"
								:disabled="field.disabled || getDisabledCondition(field)"
								:prefix="field.prefix"
								:readonly="field.readonly"
								:prepend-icon="field.prependIcon"
								:prepend-inner-icon="field.prependInnerIcon"
								:suffix="field.suffix"
								:counter="field.counter"
								:hint="field.hint"
								persistent-hint
								outlined
								:dense="field.dense === null || field.dense !== false"
								:append-icon="!showPassword ? 'mdi-eye-off' : 'mdi-eye'"
								:type="!showPassword ? 'password' : 'text'"
								@click:append="showPassword = !showPassword"
								@input="field.onInput ? onInput($event, field.onInput) : false"
							/>

							<!-- Numeric text Component -->
							<v-text-field
								v-else-if="field.type === 'number'"
								:ref="field.ref"
								v-model.number="formData[field.name]"
								:error-messages="errors"
								:label="field.display"
								:placeholder="field.placeholder"
								:type="field.type"
								:hide-details="!errors.length && !field.hint"
								:disabled="field.disabled || getDisabledCondition(field)"
								:prefix="field.prefix"
								:readonly="field.readonly"
								:prepend-icon="field.prependIcon"
								:prepend-inner-icon="field.prependInnerIcon"
								:suffix="field.suffix"
								:counter="field.counter"
								:hint="field.hint"
								:step="(field.rules || '').includes('decimal') ? 'any' : false"
								persistent-hint
								outlined
								:dense="field.dense === null || field.dense !== false"
								@input="field.onInput ? onInput($event, field.onInput) : false"
							/>

							<!-- Select Component -->
							<v-select
								v-else-if="field.type === 'select'"
								v-model="formData[field.name]"
								:error-messages="errors"
								:disabled="field.disabled || getDisabledCondition(field)"
								:label="field.display"
								:items="field.options"
								:multiple="field.multiple"
								:hide-details="!errors.length"
								outlined
								dense
								@change="selectHandler"
							>
								<template v-if="field.selectAll && formData[field.name]" #prepend-item>
									<v-list-item ripple @click="selectAll(field)">
										<v-list-item-action>
											<v-icon v-if="formData[field.name].length == 0" color="success">
												mdi-checkbox-blank-outline
											</v-icon>
											<v-icon v-else-if="formData[field.name].length > 0 && formData[field.name].length !== field.options.length" color="success">
												mdi-minus-box
											</v-icon>
											<v-icon v-else color="red lighten-1">
												mdi-close-box
											</v-icon>
										</v-list-item-action>
										<v-list-item-content>
											<v-list-item-title v-if="formData[field.name].length !== field.options.length">
												Select All
											</v-list-item-title>
											<v-list-item-title v-else>
												Clear All
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-divider class="mt-2" />
								</template>
							</v-select>

							<!-- DatePicker Component -->
							<template v-else-if="field.type === 'date-picker'">
								<v-menu
									v-model="datePickerMenu[field.name]"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
								>
									<template #activator="{ on }">
										<validation-provider v-slot="{ errors }" :name="field.display" :rules="getRules(field)">
											<v-text-field
												v-model="formData[field.name]"
												:disabled="field.disabled || getDisabledCondition(field)"
												:label="field.display"
												:hide-details="!errors.length"
												:error-messages="errors"
												outlined
												dense
												autocomplete="off"
												@input="parseDate(field.name)"
												@focus="dateFieldFocus(field)"
												v-on="on"
											/>
										</validation-provider>
									</template>

									<v-date-picker
										v-model="datePickerModel[field.name]"
										:disabled="field.disabled || getDisabledCondition(field)"
										:label="field.display"
										outlined
										dense
										@input="parseDateToText(field.name)"
									/>
								</v-menu>
							</template>

							<!-- Switch Components -->
							<v-switch
								v-else-if="field.type === 'switch'"
								v-model="formData[field.name]"
								:disabled="field.disabled || getDisabledCondition(field)"
								:label="field.display"
								:hide-details="!errors.length && !field.hint"
								:hint="field.hint"
								persistent-hint
								color="primary"
								class="ma-0 pt-0"
							/>

							<!-- Checkbox Component -->
							<v-checkbox
								v-else-if="field.type === 'checkbox'"
								v-model="formData[field.name]"
								:disabled="field.disabled || getDisabledCondition(field)"
								:label="field.display"
								:hide-details="!errors.length"
								:error-messages="errors"
								color="primary"
								class="ma-0 pt-0"
							>
								<template #append>
									<v-tooltip v-if="field.tooltip" :max-width="700" :color="field.tooltip.color" top>
										<template #activator="{ on, attrs }">
											<v-btn icon v-bind="attrs" style="margin-top: -7px" v-on="on">
												<v-icon :color="field.tooltip.iconColor">
													{{ field.tooltip.icon }}
												</v-icon>
											</v-btn>
										</template>
										<div :class="field.tooltip.textClass" v-html="$sanitize(field.tooltip.text)" />
									</v-tooltip>
								</template>

								<template v-if="field.labelSlot" #label>
									<div v-html="$sanitize(field.labelSlot)" />
								</template>
							</v-checkbox>

							<!-- Button Group -->
							<div v-else-if="field.type === 'button-group'">
								<p class="mb-2 font-weight-medium">
									<v-tooltip bottom>
										<template #activator="{ on }">
											<v-chip v-if="field.new" label small class="mr-1 pink--text yellow px-2" v-on="on">
												NEW
											</v-chip>
										</template>
										<span>{{ field.new }}</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template #activator="{ on }">
											<v-chip v-if="field.updated" label small class="mr-1 blue--text teal accent-2" v-on="on">
												UPDATED
											</v-chip>
										</template>
										<span>{{ field.updated }}</span>
									</v-tooltip>
									{{ field.display }}
								</p>

								<v-btn-toggle
									v-model="formData[field.name]"
									:disabled="field.disabled || getDisabledCondition(field)"
									:mandatory="isRequired(field)"
									:label="field.display"
									dense
									:color="color"
								>
									<v-btn v-for="(option, index) in field.options" :key="index" :value="option.value" :small="field.small">
										{{ option.text }}
									</v-btn>
								</v-btn-toggle>
							</div>

							<!-- Chip Group -->
							<div v-else-if="field.type === 'chip-group'">
								<h2 :class="field.titleClass">
									{{ field.display }}
								</h2>

								<v-chip-group
									v-model="formData[field.name]"
									:active-class="field.activeClass"
									:max="field.max"
									:multiple="field.multiple"
									column
									@change="$emit('field-updated', { name: field.name, data: formData[field.name] })"
								>
									<v-chip v-for="option in field.options" :key="option.id" :value="option.value">
										{{ option.text }}
									</v-chip>
								</v-chip-group>

								<div v-if="errors.length" class="v-messages theme--light error--text ml-3">
									<div class="v-messages__message">
										{{ errors[0] }}
									</div>
								</div>
							</div>

							<!-- Chip Group with Question Answers -->
							<div v-else-if="field.type === 'chip-group-question-answer'">
								<h2 :class="field.titleClass">
									{{ field.display }}
								</h2>

								<v-chip-group
									:ref="'chip-group-question-' + field.name"
									v-model="formData.selected"
									:active-class="field.activeClass"
									:max="field.max"
									:multiple="field.multiple"
									column
									@change="$emit('field-updated', { name: field.name, data: formData[field.name] })"
								>
									<v-chip v-for="option in field.options" :key="option.value" :value="option.value">
										{{ option.text }}
									</v-chip>
								</v-chip-group>

								<div v-if="errors.length" class="v-messages theme--light error--text ml-3">
									<div class="v-messages__message">
										{{ errors[0] }}
									</div>
								</div>

								<div v-for="option in field.options" :key="option.value">
									<div v-if="formData.selected.includes && formData.selected.includes(option.value)">
										<validation-provider v-slot="{ errors }" :name="field.display" rules="required">
											<v-text-field
												:ref="'chip-group-answer-' + field.name"
												v-model="formData[option.value].answer"
												:label="option.text"
												:error-messages="errors"
												outlined
												dense
												autocomplete="off"
											/>
										</validation-provider>
									</div>
								</div>
							</div>

							<!-- TextArea Component -->
							<v-textarea
								v-else-if="field.type === 'textarea'"
								v-model="formData[field.name]"
								:rows="field.rows || 5"
								:error-messages="errors"
								:label="field.display"
								:hide-details="!errors.length"
								:disabled="field.disabled || getDisabledCondition(field)"
								:readonly="field.readonly"
								outlined
								dense
							/>

							<!-- File Upload -->
							<!-- Important Note: Multiple file upload fields in a single form is NOT supported at the moment. -->
							<div v-else-if="field.type === 'file'" class="d-flex align-center flex-wrap">
								<div class="d-block" style="width: 100%">
									<v-card
										:class="[{ dragover: dragging }, 'pt-2 pb-4 elevation-0 file-upload-container v-input d-block']"
										@drop.prevent="dropFile(field, $event)"
										@dragover.prevent="dragging = true"
										@dragenter="dragging = true"
										@dragend="dragging = false"
										@dragleave="dragging = false"
									>
										<v-card-text class="text-center pa-2 pb-0">
											<v-icon :size="65">
												mdi-cloud-upload
											</v-icon>
											<p class="text-body-2 grey--text text--darken-2 mt-1 mb-0">
												Drag your documents here to start uploading
											</p>
										</v-card-text>

										<v-card-text class="text-body-2 grey--text text--darken-2 text-center pa-2 pt-0">
											or <a class="grey--text text--darken-4 text-decoration-underline" @click="browse()">click to browse files</a>
										</v-card-text>

										<v-file-input
											v-show="false"
											id="fileUpload"
											v-model="fileUploadModel"
											:label="field.display"
											prepend-icon="mdi-attachment"
											:accept="getValidFileTypes(field)"
											:multiple="field.multiple"
											class="pt-0 mt-0 file-upload-imput"
											hide-details
											outlined
											dense
											@change="uploadFiles(field)"
										/>
									</v-card>
								</div>

								<!-- Required error message -->
								<div v-if="!fileUploadValid" class="v-messages theme--light error--text mt-2 pl-10">
									<div class="v-messages__message">
										{{ field.display }} field is required
									</div>
								</div>

								<!-- File upload error messages -->
								<div v-if="fileUploadErrors.length > 0" class="mt-4 py-2" style="width: 100%">
									<div class="error--text text-body-2 mb-0">
										<span class="font-weight-bold d-block pb-1">
											{{ fileUploadErrors.length }} issue{{ fileUploadErrors.length > 1 ? 's' : '' }} was encountered whilst attempting to upload your files:
										</span>

										<ul>
											<li v-for="(error, index) in fileUploadErrors" :key="index">
												<span class="font-weight-medium">{{ error.type === 'count' ? error.message : `${error.fileName} - ${error.message}` }}</span>
											</li>
										</ul>
									</div>
								</div>

								<!-- Successfully uploaded Files -->
								<div v-show="uploadedFiles.length > 0" class="mt-4">
									<div class="mx-n2 mb-n2">
										<v-chip
											v-for="(item, index) in uploadedFiles"
											:key="index"
											class="ma-2 mt-0"
											:color="item.progress === null ? 'green' : 'orange'"
											outlined
											dark
											label
										>
											{{ item.file.name }}
											<v-progress-circular v-show="item.progress !== null" :value="item.progress" :size="20" :width="3" color="orange" class="ml-2" />

											<v-btn v-show="item.progress == null" light icon right x-small color="black" class="ml-2" @click="confirmRemoveFile(item, field.name)">
												<v-icon dense>
													mdi-close
												</v-icon>
											</v-btn>
										</v-chip>
									</div>
								</div>
							</div>

							<!-- Hidden Field -->
							<input v-else-if="field.type === 'hidden'" v-model="formData[field.name]" type="hidden" />

							<!-- Payment Options -->
							<v-btn-toggle v-else-if="field.type === 'payment-options'" v-model="formData[field.name]" mandatory multiple>
								<v-container class="px-0 py-0">
									<v-row>
										<v-col v-for="productType in field.options" :key="productType.id" cols="12" md="6" class="py-2" style="position: relative">
											<v-icon
												v-if="formData[field.name] && formData[field.name].includes(productType.id)"
												color="success accent-3"
												style="position: absolute; top: 2px; right: 4px; z-index: 1"
											>
												mdi-check-bold
											</v-icon>
											<v-icon v-else color="red lighten-4" style="position: absolute; top: 2px; right: 4px; z-index: 1">
												mdi-close-outline
											</v-icon>
											<v-btn active-class="success" :disabled="productType.disabled" :value="productType.id" block>
												{{ productType.title }}
											</v-btn>
										</v-col>
									</v-row>
								</v-container>
							</v-btn-toggle>
						</validation-provider>

						<div v-if="field.alert" @click="handleDynamicClick">
							<v-fade-transition hide-on-leave>
								<v-alert
									v-show="showAlert(field)"
									class="mt-4"
									border="left"
									:color="field.alert.color"
									:dense="field.alert.dense ? field.alert.dense : false"
									:dark="field.alert.dark"
									v-html="$sanitize(field.alert.text)"
								/>
							</v-fade-transition>
						</div>
					</v-col>
				</v-row>

				<div v-if="cancelButton || submitButton" class="text-center mt-3">
					<v-btn v-if="cancelButton" color="grey lighten-3 error--text mr-3" @click="cancel">
						{{ cancelButtonText }}
					</v-btn>

					<v-btn
						v-if="submitButton"
						color="primary"
						type="submit"
						:loading="submitButtonLoading"
						:large="submitButtonLarge"
						:block="submitButtonBlock"
						:disabled="fileUploadInProgress || submitButtonDisabled"
					>
						{{ submitButtonText }}
					</v-btn>
				</div>
			</form>
		</validation-observer>

		<div v-else>
			<v-skeleton-loader type="list-item-three-line" />
		</div>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import axios from 'axios';
	import { $http } from '@/utils';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import { debounce, isEmpty } from 'lodash';
	import { EventBus, ElementTools, Unit } from '@/utils';
	import AddressSearch from '@/component/common/base/address-search';
	import CommonBasePassword from '@/component/common/base/password';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';

	export default {
		name: 'common-base-dynamic-form',

		components: {
			ValidationObserver,
			ValidationProvider,
			AddressSearch,
			CommonBasePassword,
			CommonDialogConfirm
		},

		props: {
			formSchema: {
				type: Array,
				default: () => []
			},

			formData: {
				type: Object,
				required: true
			},

			submitButton: {
				type: Boolean,
				default: true
			},

			submitButtonText: {
				type: String,
				default: 'Submit'
			},

			submitButtonLoading: {
				type: Boolean,
				default: false
			},

			submitButtonLarge: {
				type: Boolean,
				default: false
			},

			submitButtonBlock: {
				type: Boolean,
				default: false
			},

			submitButtonDisabled: {
				type: Boolean,
				default: false
			},

			cancelButton: {
				type: Boolean,
				default: false
			},

			cancelButtonText: {
				type: String,
				default: 'Cancel'
			},

			customError: {
				type: Object,
				default: () => ({})
			}
		},

		data() {
			return {
				datePickerModel: {},
				datePickerMenu: {},
				color: 'primary',
				submitted: false,
				uploadedFiles: [],
				fileUploadModel: [],
				fileUploadErrors: [],
				fileUploadInProgress: false,
				fileUploadValid: true,
				dragging: false,
				showPassword: false
			};
		},

		computed: {
			countryOptions() {
				return this.formSchema.find((item) => item.name === 'countryId')?.options;
			}
		},

		watch: {
			uploadedFiles(val) {
				if (val.length > 0) this.fileUploadValid = true;
			}
		},

		methods: {
			/**
			 * @name handleDynamicClick
			 * @description Handle clicks on dynamically created alert boxes
			 * @param {Object} e
			 */
			handleDynamicClick(e) {
				EventBus.$emit('handle-dynamic-click', e);
			},

			/**
			 * @name showAlert
			 * @description Show alert based on a condition
			 * @param {Object} field
			 * @returns {Boolean} Is visible
			 */
			showAlert(field) {
				return (
					(field.alert.condition.type === 'equals' && this.formData[field.name] == field.alert.condition.value) ||
					(field.alert.condition.type === 'not_equals' && this.formData[field.name] != field.alert.condition.value) ||
					(field.alert.condition.type === 'greater_than' && this.formData[field.name] > field.alert.condition.value) ||
					(field.alert.condition.type === 'less_than' && this.formData[field.name] < field.alert.condition.value)
				);
			},

			/**
			 * @name dropFile
			 * @description File drag & drop handler for file input field
			 * @param {Object} field
			 * @param {Object} event
			 */
			dropFile(field, event) {
				this.dragging = false;
				this.fileUploadModel = Array.from(event.dataTransfer.files);
				this.uploadFiles(field);
			},

			/**
			 * @name uploadFiles
			 * @description File upload handler for file input field
			 * @param {Object} field
			 */
			uploadFiles(field) {
				let fileUploadRequests = [];
				this.fileUploadErrors = [];
				this.fileUploadInProgress = true;
				this.$emit('file-upload-in-progress', true);
				this.formData[field.name] = this.formData[field.name] || [];

				this.fileUploadModel.map((file) => {
					let valid = true;

					(field.validations || []).map((validation) => {
						if (
							(validation.type === 'size' && file.size / 1024 > validation.value) ||
							(validation.type === 'count' && this.uploadedFiles.length >= validation.value) ||
							(validation.type === 'type' && !validation.value.includes(file.type))
						) {
							let fileCountError = this.fileUploadErrors.filter((error) => error.type === 'count')[0];

							if (!validation.type === 'count' || !fileCountError)
								this.fileUploadErrors.push({
									fileName: file.name,
									type: validation.type,
									message: validation.errorMessage
								});

							valid = false;
						}
					});

					if (valid) {
						this.uploadedFiles.push({
							id: Math.random().toString(36).substr(2, 9),
							file,
							progress: null,
							requested: false
						});
					}
				});

				this.uploadedFiles.map((item, index) => {
					if (item.requested) return;

					item.requested = true;
					item.progress = 0;

					const payload = {
						name: item.file.name,
						size: item.file.size,
						type: item.file.type,
						s3Path: field.s3Path
					};

					const axiosConfig = {
						headers: { 'Content-Type': item.file.type },
						onUploadProgress(progressEvent) {
							item.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
						}
					};

					$http
						.post(`file`, payload)
						.then((response) => {
							fileUploadRequests.push(
								axios
									.put(response.data.preSignedUrl, item.file, axiosConfig)
									.then(() => {
										this.formData[field.name].push({
											s3Key: field.s3Path + response.data.preSignedUrl.split(field.s3Path)[1].split('?')[0],
											filename: item.file.name
										});
									})
									.catch(() => {
										ElementTools.fireNotification(this.$el, 'error', 'An error occured whilst attempting to upload your file');
										this.removeFile(item, field.name);
									})
									.finally(() => {
										if (index === this.uploadedFiles.length - 1) {
											this.fileUploadInProgress = false;
											this.$emit('file-upload-in-progress', false);
										}
										item.progress = null;
									})
							);
						})
						.catch(() => {
							ElementTools.fireNotification(this.$el, 'error', 'An error occured whilst attempting to upload your file');
							this.removeFile(item, field.name);
						});
				});

				this.fileUploadModel = [];
			},

			/**
			 * @name removeFile
			 * @description Remove file failed to upload successfully
			 * @param {Object} item
			 * @param {String} fieldName
			 */
			confirmRemoveFile: debounce(function(item, fieldName) {
				this.$refs.confirm
					.open('Remove File', 'Are you sure you wish to remove this file?')
					.then(() => this.removeFile(item, fieldName))
					.catch(() => {});
			}, 200),

			/**
			 * @name removeFile
			 * @description Remove file failed to upload successfully
			 * @param {Object} item
			 * @param {String} fieldName
			 */
			removeFile(item, fieldName) {
				this.uploadedFiles = this.uploadedFiles.filter((file) => file.id !== item.id);
				this.formData[fieldName] = this.formData[fieldName].filter((file) => file.filename !== item.filename);
			},

			/**
			 * @name getValidFileTypes
			 * @description Get accepted file mimetypes for file upload
			 * @param {Object} field
			 */
			getValidFileTypes(field) {
				let validation = (field.validations || []).filter((validation) => validation.type === 'type');

				if (validation.length > 0) return validation[0].value.join(', ');
			},

			/**
			 * @name getDisabledCondition
			 * @description Get if the field is disabled
			 * @param {Object} field
			 * @returns {Boolean} Is disabled
			 */
			getDisabledCondition(field) {
				if (!field.conditions || !field.conditions.disable) return false;

				let isDisabled = false;

				field.conditions.disable.forEach((condition) => {
					if (
						(condition.type === 'equals' && this.formData[condition.name] == condition.value) ||
						(condition.type === 'not_equals' && this.formData[condition.name] != condition.value) ||
						(condition.type === 'includes' &&
							((Array.isArray(condition.value) && condition.value.some((val) => this.formData[condition.name]?.includes(val))) ||
								(!Array.isArray(condition.value) && this.formData[condition.name]?.includes(condition.value)))) ||
						(condition.type === 'in_array' && condition.value.includes(this.formData[condition.name])) ||
						(condition.type === 'not_in_array' && !condition.value.includes(this.formData[condition.name])) ||
						(condition.type === 'is_empty' && isEmpty(this.formData[condition.name])) ||
						(condition.type === 'not_empty' && !isEmpty(this.formData[condition.name]))
					) {
						isDisabled = true;
						return;
					}
				});

				return isDisabled;
			},

			/**
			 * @name getDisplayCondition
			 * @description Get if the field is visible
			 * @param {Object} field
			 * @returns {Boolean} Is visible
			 */
			getDisplayCondition(field) {
				if (field.type === 'hidden') return false;
				if (!field.conditions || !field.conditions.show) return true;

				let isVisible = true;

				field.conditions.show.forEach((condition) => {
					if (
						(condition.type === 'equals' && this.formData[condition.name] != condition.value) ||
						(condition.type === 'not_equals' && this.formData[condition.name] == condition.value) ||
						(condition.type === 'includes' &&
							((Array.isArray(condition.value) && !condition.value.some((val) => this.formData[condition.name]?.includes(val))) ||
								(!Array.isArray(condition.value) && !this.formData[condition.name]?.includes(condition.value)))) ||
						(condition.type === 'in_array' && !condition.value.includes(this.formData[condition.name])) ||
						(condition.type === 'not_in_array' && condition.value.includes(this.formData[condition.name])) ||
						(condition.type === 'is_empty' && !isEmpty(this.formData[condition.name])) ||
						(condition.type === 'not_empty' && isEmpty(this.formData[condition.name]))
					) {
						isVisible = false;
						return;
					}
				});

				return isVisible;
			},

			/**
			 * @name getDisplayCondition
			 * @description Get if the field is visible
			 * @param {Object} field
			 * @returns {Boolean} Is visible
			 */
			getRulesCondition(field) {
				if (!field.conditions || !field.conditions.rules) return true;

				let applyRules = false;

				field.conditions.rules.forEach((condition) => {
					if (
						(condition.type === 'equals' && this.formData[condition.name] == condition.value) ||
						(condition.type === 'not_equals' && this.formData[condition.name] != condition.value) ||
						(condition.type === 'includes' &&
							((Array.isArray(condition.value) && condition.value.some((val) => this.formData[condition.name]?.includes(val))) ||
								(!Array.isArray(condition.value) && this.formData[condition.name]?.includes(condition.value)))) ||
						(condition.type === 'in_array' && condition.value.includes(this.formData[condition.name])) ||
						(condition.type === 'not_in_array' && !condition.value.includes(this.formData[condition.name])) ||
						(condition.type === 'is_empty' && isEmpty(this.formData[condition.name])) ||
						(condition.type === 'not_empty' && !isEmpty(this.formData[condition.name]))
					) {
						applyRules = true;
						return;
					}
				});

				return applyRules;
			},

			/**
			 * @name getRules
			 * @description Get validation rules for a form field
			 * @param {Object} field
			 * @returns {String} Rules
			 */
			getRules(field, validationWrapper) {
				if (field.type === 'date-picker' && validationWrapper) return;

				let rules = field.rules;

				if (field.conditions) {
					if (!this.getDisplayCondition(field) || this.getDisabledCondition(field) || !this.getRulesCondition(field)) {
						rules = null;
					}
				}

				return rules;
			},

			/**
			 * @name getDebounceValue
			 * @description returns a debounce value if rules contains a server-side validation
			 * @param {String} rules validation rules
			 * @returns {Number} debounce value
			 */
			getDebounceValue(rules) {
				if (rules && (rules.includes('is_unique_email') || rules.includes('is_unique_username'))) {
					return 500;
				}
			},

			clearCustomError() {
				this.$emit('form-updated', this.formData);
				// // if (this.customError[field.name]) {
				// console.log('this.customError :>> ', this.customError);
				// console.log('this.customError[field.name]; :>> ', this.customError[field.name]);
				// return this.customError[field.name];
				// }
			},

			/**
			 * @name isRequired
			 * @description Get if the field is required
			 * @param {Object} field
			 * @returns {Boolean} Is required
			 */
			isRequired(field) {
				let isRequired = (field.rules || '').includes('required');

				if (field.conditions && field.conditions.show) return this.getDisplayCondition(field) ? isRequired : null;

				return isRequired;
			},

			/**
			 * @name validate
			 * @description validate form
			 * @param {Boolean} reset reset fields after validation is completed
			 * @param {Boolean} silent silent validation
			 */
			async validate(reset = true, silent = false) {
				return this.$refs.observer.validate({ silent }).then((isValid) => {
					if (reset)
						requestAnimationFrame(() => {
							this.resetValidation();
						});

					let fileUploadField = this.formSchema.filter((field) => field.type === 'file')[0];

					if (
						fileUploadField &&
						(fileUploadField.validations || []).filter((validation) => validation.type === 'required' && validation.value).length > 0 &&
						!this.uploadedFiles.length > 0
					) {
						this.fileUploadValid = isValid = false;
					}

					return isValid;
				});
			},

			/**
			 * @name resetValidation
			 * @description Resets the validation observer and clears the validation errors
			 */
			resetValidation() {
				this.$refs.observer.reset();
			},

			/**
			 * @name selectAll
			 * @description Select all
			 * @param {Object} field the input
			 */
			selectAll(field) {
				if (this.formData[field.name].length === field.options.length) {
					this.formData[field.name] = [];
				} else {
					this.formData[field.name] = (field.options || []).map((option) => option.value);
				}
			},

			/**
			 * @name parseDateToText
			 * @description Parse datepicker value into text field
			 * @param {String} fieldName field name
			 */
			parseDateToText(fieldName) {
				let date = this.datePickerModel[fieldName];

				if (!date) return null;

				const [year, month, day] = date.split('-');
				this.formData[fieldName] = `${day}/${month}/${year}`;
				this.$set(this.datePickerMenu, fieldName, false);
			},

			/**
			 * @name parseDate
			 * @description Parse text field date value into datepicker
			 * @param {String} fieldName field name
			 */
			parseDate(fieldName) {
				let regex =
					/(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;
				let date = this.formData[fieldName];
				let isValid = regex.test(date);
				let pickerValue = null;

				if (isValid) {
					const [day, month, year] = date.split('/');
					try {
						pickerValue = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
					} catch {
						pickerValue = null;
					}
				}

				this.$set(this.datePickerModel, fieldName, pickerValue);
			},

			/**
			 * @name dateFieldFocus
			 * @description Event handler for date field focus
			 * @param {Object} field
			 */
			dateFieldFocus(field) {
				if (!this.formData[field.name] && (field.valueDefault || field.ageRelativeDob)) {
					this.$set(this.formData, field.name, field.valueDefault || field.ageRelativeDob);
				}

				this.$nextTick(() => {
					this.parseDate(field.name);
				});
			},

			/**
			 * @name submit
			 * @description dispatch a form submit event with form data
			 */
			submit() {
				this.validate(false).then((isValid) => {
					if (!isValid) return;

					let dob = this.formSchema.find((schema) => schema.name === 'formatted_dob');

					if (dob && dob.ageRelativeDob) {
						const defaultAge = dob.ageRelativeDob.split('/')[2];
						const selectedAge = this.formData.formatted_dob.split('/')[2];

						if (defaultAge !== selectedAge) {
							this.$refs.confirm
								.open(
									'Age Inconsistency',
									'The date of birth does not match with the age entered at the sourcing stage. This will force you back to the results screen. Do you wish to proceed?',
									{
										app_bar_color: 'error',
										system_bar_color: 'error darken-2'
									}
								)
								.then(async() => {
									EventBus.$emit('age-inconsistency-accepted');
									// this.$emit('dynamic-form-submit', this.formData);
									// this.clear();
								})
								.catch(() => {});

							return;
						}
					}

					this.$emit('dynamic-form-submit', this.formData);
					this.clear();
				});
			},

			/**
			 * @name clear
			 * @description clears validations and local state of the form
			 */
			clear() {
				this.$refs.observer.reset();
				this.datePickerModel = {};
				this.datePickerMenu = {};
				this.fileUploadModel = [];
				this.uploadedFiles = [];
			},

			/**
			 * @name cancel
			 * @description dispatch a cancel event
			 */
			cancel() {
				this.$emit('dynamic-form-cancel');
			},

			/**
			 * @name emit
			 * @description Eventbus
			 */
			emit(name, data) {
				this.$emit('field-updated', { name, data });
			},

			/**
			 * @name onInput
			 * @description on text field input
			 */
			onInput: debounce(function(inputValue, options) {
				const res = this[options.fnctn](inputValue, options.params);
				if (options.populate && res) this.$set(this.formData, options.populate, res);
			}, 1000),

			/**
			 *
			 * @param value
			 * @param params
			 * @returns {string|null|number}
			 */
			convertHeight(value, params) {
				if (isNaN(value)) return null;
				const convertedValue = Unit.convertHeight(value.split('.'), params.unit);
				if (params.unit.to === 'cm') return convertedValue.cm;
				else if (params.unit.to === 'feet') return `${convertedValue.feet}.${convertedValue.inches}`;
				return null;
			},

			/**
			 *
			 * @param value
			 * @param params
			 * @returns {string|null|number}
			 */
			convertWeight(value, params) {
				if (isNaN(value)) return null;
				const convertedValue = Unit.convertWeight(value.split('.'), params.unit);
				if (params.unit.to === 'kg') return convertedValue.kg;
				else if (params.unit.to === 'stone') return `${convertedValue.stones}.${convertedValue.lbs}`;
				return null;
			},

			browse() {
				let fileUpload = document.getElementById('fileUpload');
				fileUpload.click();
			},

			selectHandler() {
				this.$emit('select-handler');
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '~vuetify/src/styles/styles.sass';

	.v-input--checkbox {
		::v-deep .v-input__control {
			width: auto;
			flex-grow: 0;
		}
	}

	.file-upload-container {
		border: 2px dashed rgba(0, 0, 0, 0.25);
		opacity: 0.75;
		transition: all 0.05s ease-in-out;
		::v-deep .v-icon {
			opacity: 0.75;
		}
		&.dragover {
			border: 2px solid rgba(0, 0, 0, 0.3) !important;
			border-style: solid !important;
			transform: scale(1.01);
			background-color: rgba(0, 0, 0, 0.05);

			::v-deep .v-icon {
				transform: scale(1.1);
			}
		}
	}
</style>
