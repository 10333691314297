<!--
	@name app-register
	@description Register page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-row no-gutters class="pa-6 mx-auto" style="max-width: 1280px">
			<v-col cols="12" sm="8" offset-md="2" class="text-center">
				<router-link v-if="logo" to="/">
					<v-img class="mx-auto" contain :max-height="logo.maxHeight" :src="logo.path | urlize('brand')" />
				</router-link>

				<div v-if="isCompleteRegistration">
					<v-skeleton-loader v-if="validating" type="list-item-three-line" class="mx-auto my-6" />

					<div v-else-if="invalid">
						<v-icon class="text-h2" color="error">
							mdi-exclamation
						</v-icon>
						<v-card-title class="text-h6 font-weight-bold d-block">
							Invalid Invitation
						</v-card-title>
						<v-card-text class="text-body-1 pb-0">
							This invitation is not valid. If you think this is not correct please let us know at {{ site.contact.number }} or
							<a :href="`mailto:${site.contact.email}`">{{ site.contact.email }}</a>
						</v-card-text>
					</div>
					<div v-else-if="expired">
						<v-icon class="text-h2" color="error">
							mdi-cancel
						</v-icon>
						<v-card-title class="text-h6 font-weight-bold d-block">
							Invitation Expired
						</v-card-title>
						<v-card-text class="text-body-1 pb-0">
							This invitation is now expired. Please contact account owner for new invitation.
						</v-card-text>
					</div>
					<template v-else>
						<p class="text-h5 font-weight-bold mt-8 mb-2">
							Complete Registration
						</p>
						<p class="text-body-2 mb-3">
							Complete the short registration form below to gain access to the exclusive range of benefits offered by Air Sourcing. If you have any questions, please
							email us at {{ site.contact.email }} or call on {{ site.contact.number }}.
						</p>
					</template>
				</div>

				<div v-else>
					<p class="text-h5 font-weight-bold mt-8 mb-2">
						Create your account
					</p>
					<div v-if="isAirRegistration && content('registration').text && content('registration').text.introduction" v-html="$sanitize(content('registration').text.introduction)" />
					<!-- contentSearch('registration', 'introduction') -->
					<p v-else-if="isClub" class="text-body-2 mb-3">
						Register here for the industry's first and best club for equity release specialists. We will email you full details of this exciting proposition very soon.
						All registrations received after 4:00pm will be processed the next working day.
					</p>
					<p v-else-if="isAcademy" class="text-body-2 mb-3">
						Individual membership is simple and affordable, with a simple transparent contract. We want our members to WANT to belong, not be under any obligation. We
						charge NO application fees and our module fees are charged at market leading value. We offer additional discounts for larger groups, Networks and other
						organisations, please email enquiries@laterlifeacademy.co.uk for membership fees and application forms.
					</p>
					<v-btn color="blue-grey" text class="mt-1 mb-0 text-none" to="/advisers">
						Already have an account?
					</v-btn>
				</div>
			</v-col>
		</v-row>
		<common-base-window-form
			v-if="!isCompleteRegistration || (isCompleteRegistration && !validating && !invalid && !expired)"
			ref="windowForm"
			:form-data="formData"
			:forms="formSchema"
			:submitting="submitting"
			:operation="successResponse"
			:custom-error="customError"
			@step-submit-window-form="stepSubmit"
			@submit-window-form="submit"
			@form-updated="customError = {}"
		>
			<template #success-step>
				<v-row v-if="isCompleteRegistration" class="mx-auto">
					<v-col cols="12" class="text-center">
						<v-icon class="text-h2" color="success">
							mdi-checkbox-marked-circle-outline
						</v-icon>
						<v-card-title class="text-body-1 font-weight-bold d-block">
							You have successfully completed your registration!
						</v-card-title>
						<v-card-text class="text-body-2">
							Please wait whilst we redirect you to the log in page...
						</v-card-text>
					</v-col>
				</v-row>
				<div v-else>
					<div v-if="successStatusAutoJoin">
						<p class="text-body-1 mb-1 font-weight-bold">
							Your company's FCA number is already being used by an existing firm
						</p>
						<p class="text-body-2 mb-6">
							Your account will be automatically be placed under <strong>{{ existingFirmText }}</strong> if you click to confirm below. If you do not want this to
							happen, please click back and change your FCA number.
						</p>
						<v-card-actions class="pa-4 blue-grey lighten-5">
							<v-btn text @click="jumpToFcaForm">
								Change FCA Number
							</v-btn>
							<v-spacer />
							<v-btn color="primary" depressed :loading="loading" @click="submit">
								Auto-Join Firm
							</v-btn>
						</v-card-actions>
					</div>
					<div v-else-if="successStatusRequestJoin">
						<p class="text-body-1 mb-1 font-weight-bold">
							Your company's FCA number is already being used by an existing firm
						</p>
						<p class="text-body-2 mb-6">
							You will need to make a request to join <strong>{{ existingFirmText }}</strong> as an advisor. You can do this by clicking the "Request to Join" button
							below. If you do not want to join this firm, you must click back and change your FCA number.
						</p>
						<v-divider />
						<v-card-actions class="pa-4 blue-grey lighten-5">
							<v-btn text @click="jumpToFcaForm">
								Change FCA Number
							</v-btn>
							<v-spacer />
							<v-btn color="primary" depressed :loading="loading" @click="submit">
								Request to Join Firm
							</v-btn>
						</v-card-actions>
					</div>
					<div v-else>
						<div v-if="actionRequestJoinMessage">
							<p class="text-body-1 mb-1 font-weight-bold">
								You have requested to join {{ existingFirmText }}
							</p>
							<p class="text-body-2 mb-0">
								A request has been sent to the owner of this firm. Once they have accepted your request, you will receive a verification link email. Click on the
								link inside the email and you will complete registration.
							</p>
						</div>
						<div v-else>
							<p class="text-body-1 mb-1 font-weight-bold">
								You're one step away from completing your registration!
							</p>
							<p class="text-body-2 mb-0">
								A verification link has been sent to your email account. Please allow up to 10 minutes for this message to arrive. If you do not receive the
								verification email, please contact our support team on {{ site.contact.number }}.
							</p>
						</div>
					</div>
				</div>
			</template>
		</common-base-window-form>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonBaseWindowForm from '@/component/common/base/window-form';

	export default {
		name: 'app-register',

		components: { CommonBaseWindowForm },

		data() {
			return {
				sourcingIntroduction: null,
				formData: {
					company_details: {},
					credentials: {},
					personal_details: {},
					marketing_preferences: {},
					terms: {
						mortgageClubMembership: true
					}
				},
				submitting: false,
				validating: true,
				invalid: false,
				expired: false,
				successResponse: false,
				actionResponse: false,
				existingFirm: false,
				customError: {}
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('CmsForm', ['form']),
			...mapGetters('CmsContent', ['content', 'contentSearch']),

			logo() {
				return this.isAcademy ? { path: 'air_academy_logo.svg', maxHeight: '4rem' } : this.isClub ? { path: 'air_club_logo.svg', maxHeight: '4rem' } : this.site?.logo;
			},

			isAirRegistration() {
				return this.$route.name === 'register';
			},

			isClub() {
				return this.$route.name === 'clubRegister';
			},

			isAcademy() {
				return this.$route.name === 'academyRegister';
			},

			isCompleteRegistration() {
				return !!this.$route.params.token;
			},

			type() {
				return this.isAirRegistration || this.isCompleteRegistration ? 'air' : this.isClub ? 'club' : 'academy';
			},

			successStatusAutoJoin() {
				return this.successResponse === 'auto_join';
			},

			successStatusRequestJoin() {
				return this.successResponse === 'request_join';
			},

			actionRequestJoinMessage() {
				return this.actionResponse === 'join_request_sent';
			},

			existingFirmText() {
				return this.existingFirm;
			},

			formSchema() {
				let form = this.form('air_registration').value;
				if (!form) return [];

				if (this.isCompleteRegistration) {
					// Remove company details section from the form
					form = form.filter((item) => item.nameUnique !== 'company_details');

					let formIndex = form.findIndex((item) => item.nameUnique === 'personal_details');
					form[formIndex].value = form[formIndex].value.filter((item) => item.name !== 'organisationLandline');
					return form;
				}

				if (this.isAcademy || this.isClub) {
					// Remove fields related to Air Club from terms and conditions section
					let termsIndex = form.findIndex((item) => item.nameUnique === 'terms');
					form[termsIndex].value = form[termsIndex].value.filter((item) => !['clubMarketingCopy', 'mortgageClubMembership', 'whyJoinClubCopy'].includes(item.name));

					return form;
				}

				if (this.isAirRegistration && !this.site.usesMortgageClub) {
					// Remove marketing preferences section from the form
					form = form.filter((item) => item.nameUnique !== 'marketing_preferences');

					// Remove fields related to Air Club from terms and conditions section
					let termsIndex = form.findIndex((item) => item.nameUnique === 'terms');
					form[termsIndex].value = form[termsIndex].value.filter((item) => !['clubMarketingCopy', 'mortgageClubMembership', 'whyJoinClubCopy'].includes(item.name));

					return form;
				}

				return form;
			},

			loading() {
				if (this.validating || this.submitting) return true;
				return false;
			}
		},

		created() {
			this.loadForm(`airRegistration`);
			if (this.isAirRegistration) this.loadContent('registration');
			if (this.isCompleteRegistration)
				this.loadCompleteRegistration(this.$route.params.token)
					.then(({ data }) => {
						if (!data.id || data.accepted) this.invalid = true;
						else {
							this.user = data;
							this.loadForm('complete_registration');
							this.formData['personal_details'].name = data.registration.data.name;
							this.formData['personal_details'].email = data.registration.identity;
						}
					})
					.catch(() => (this.invalid = true))
					.finally(() => (this.validating = false));
		},

		methods: {
			...mapActions('Account', ['register', 'login']),
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsContent', ['loadContent']),
			...mapActions('AccountRegistrationComplete', ['loadCompleteRegistration', 'submitCompleteRegistration']),
			...mapActions('AccountRegistrationVerify', ['submitVerifyRegistration']),

			/**
			 * @name submit
			 * @description Submits registration form to server
			 */
			async stepSubmit() {
				const payload = { ...this.formData, stepValidation: true };

				this.submitting = true;

				this.register(payload)
					.then(() => {
						this.customError = {};
						this.$refs.windowForm.incrementCurrentStep();
					})
					.catch((e) => {
						let uniqueValidationError;
						if (e.status === 422) {
							e.data.forEach((i) => {
								const label = i.label.substring(i.label.indexOf('.') + 1);
								if (['email', 'username'].includes(label)) {
									uniqueValidationError = true;
									this.$set(this.customError, label, i.message);
								}
							});
							this.validating = false;
							this.submitting = false;
						}

						if (!uniqueValidationError) {
							let errorMessage = '';

							try {
								errorMessage = e.data[0]?.message;
							} catch (error) {
								errorMessage = 'An error occurred';
							}

							ElementTools.fireNotification(this.$el, 'error', {
								text: errorMessage || 'An error occurred',
								timeout: 0,
								close: true
							});
						}
					})
					.finally(() => {
						this.validating = false;
						this.submitting = false;
					});
			},

			/**
			 * @name submit
			 * @description Submits registration form to server
			 */
			async submit() {
				const payload = {
					identity: this.formData.credentials.username,
					identityType: 'username',
					password: this.formData.credentials.password,
					submitAction: this.successResponse,
					data: {
						organisation: this.isCompleteRegistration
							? undefined
							: {
									type: 'advisor',
									name: this.formData.company_details.name
							  },
						organisationAddress: this.isCompleteRegistration
							? undefined
							: {
									addressLine1: this.formData.company_details.address1,
									addressLine2: this.formData.company_details.address2,
									townCity: this.formData.company_details.townCity,
									postalCode: this.formData.company_details.postcode
							  },
						organisationCommunication: this.isCompleteRegistration ? undefined : [{ type: 'phone', identifier: this.formData.personal_details.organisationLandline }],
						name: this.formData.personal_details.name,
						directlyAuthorised: this.formData.company_details.directlyAuthorised,
						insurerName: this.formData.company_details.insurerName,
						memberOfNetwork: this.formData.company_details.memberOfNetwork,
						networkId: this.formData.company_details.networkId,
						otherNetwork: this.formData.company_details.otherNetwork,
						policyNumber: this.formData.company_details.policyNumber,
						fcaNumber: this.formData.company_details.fcaNumber,
						terms: { accepted: new Date().toISOString(), version: 1 },
						marketingPreferences: this.formData.marketing_preferences,
						userDetail: {
							mobile: this.formData.personal_details.mobile,
							email: this.formData.personal_details.email
						},
						membership: [{ type: 'academy', value: true, statusRequest: new Date().toISOString() }]
					}
				};

				if ((!!this.formData.terms?.mortgageClubMembership || !!this.isClub || !!this.isAcademy) && this.type !== 'academy')
					payload.data.membership.push({ type: this.type === 'air' ? 'club' : this.type, value: true, statusRequest: new Date().toISOString() });

				this.submitting = true;

				if (this.isCompleteRegistration) {
					this.submitCompleteRegistration({ token: this.$route.params.token, payload })
						.then(({ data }) => {
							this.submitVerifyRegistration({ token: data.verifyToken })
								.then(() => {
									this.login({
										username: this.formData.credentials.username,
										password: this.formData.credentials.password
									})
										.then(() => {
											this.$refs.windowForm.incrementCurrentStep();
											setTimeout(() => this.$router.push('/advisers'), 2500); // Redirect to login
										})
										.finally(() => (this.submitting = false));
								})
								.catch(() => {
									ElementTools.fireNotification(this.$el, 'error', 'Failed to verify token');
									this.submitting = false;
								});
						})
						.catch(() => {
							ElementTools.fireNotification(this.$el, 'error', 'Failed to complete registration');
							this.submitting = false;
						});
				} else {
					this.register(payload)
						.then((response) => {
							// Handle reponse if registration requires validation actions
							if (response.data.validation) {
								this.validating = false;
								this.submitting = false;
							// Handle reponse if registration requires further actions
							} else if(response.data.operation) {
								this.validating = false;
								this.submitting = false;
								this.successResponse = response.data.operation;
								this.existingFirm = response.data.existingFirm;
								this.$refs.windowForm.incrementCurrentStep();
							} else {
								// If we don't have a success response step, we need to go to the next step
								if(!this.successResponse) this.$refs.windowForm.incrementCurrentStep();
								// If we do, then we just need to remain on current step and display the success message
								else this.successResponse = false;

								this.actionResponse = response.data.action;
							}
						})
						.catch((error) => {
							const message = error.status === 429 ? error.data : error.data.message;
							ElementTools.fireNotification(this.$el, 'error', {
								text: message || 'An error occurred',
								timeout: 0,
								close: true
							});
						})
						.finally(() => {
							this.validating = false;
							this.submitting = false;
						});
				}
			},

			jumpToFcaForm() {
				this.successResponse = false;
				this.$refs.windowForm.goToStep(1);
			}
		}
	};
</script>
