import { EventBus } from '@/utils';
import { CmsChangelog } from '@/service';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default class Changelog {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	static get namespaced() {
		return true;
	}

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	static get state() {
		return {
			changelogItems: null
		};
	}

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	static get getters() {
		return {};
	}

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	static get mutations() {
		return {
			/**
			 * @name setChangelogList
			 * @description used to set content on store
			 * @param {Object} state
			 * @param {Object} data
			 */
			setChangelogList: (state, data) => (state.changelogItems = data.data.data)
		};
	}

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	static get actions() {
		return {
			/**
			 *  @name loadChangelogList
			 * @param {function} commit
			 */
			async loadChangelogList({ commit, rootState }) {
				const domain = rootState.CmsSite.site.name || 'default';
				let changelogItems = await CmsChangelog.list({ order: { property: 'posted', direction: 'desc' } }, domain);
				commit('setChangelogList', changelogItems);
				EventBus.$emit('changelog-loaded');
				return changelogItems;
			},

			/**
			 * @name loadChangelogCategoryList
			 * @param {function} commit
			 */
			loadChangelogCategoryList({ rootState }) {
				const domain = rootState.CmsSite.site.name || 'default';
				return CmsChangelog.getChangelogCategories({ order: { property: 'position', direction: 'asc' } }, domain);
			},

			/**
			 * @name addChangelogLike
			 * @description submit like for changelog
			 * @param {function} commit
			 */
			addChangelogLike(context, payload) {
				return CmsChangelog.addChangelogLike(payload);
			},

			/**
			 * @name addChangelogComment
			 * @description submit comment for changelog
			 * @param {function} commit
			 */
			addChangelogComment(context, payload) {
				return CmsChangelog.addChangelogComment(payload);
			},

			/**
			 *  @name loadChangelogLikeCounts
			 * @param {function} commit
			 */
			loadChangelogLikeCounts(context, payload) {
				return CmsChangelog.listLikeCounts(payload);
			},

			/**
			 *  @name loadChangelogCommentCounts
			 * @param {function} commit
			 */
			loadChangelogCommentCounts(context, payload) {
				return CmsChangelog.listCommentCounts(payload);
			}
		};
	}
}
