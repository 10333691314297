import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Workflow
 * @exports Service/Workflow
 * @description API Workflow Service
 * @date 2024/02/05
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class Workflow {
	/**
	 * @static @name find
	 * @description Find workflow for client(s) and asset
	 * @param {Object} payload - Client(s) and asset data
	 * @returns {Object} Promise a http response
	 */
	static find(payload) {
		return $http.post(`workflow/find`, payload);
	}

	/**
	 * @static @name post
	 * @description Create new workflow with associated client(s) and asset
	 * @param {Object} payload - Client(s) and asset data
	 * @returns {Object} Promise a http response
	 */
	static post(payload) {
		return $http.post(`workflow`, payload);
	}

	/**
	 * @static @name patch
	 * @description Patch excisting workflow to mark an item as completed
	 * @param {Object} payload - Client(s) and asset data
	 * @returns {Object} Promise a http response
	 */
	static patch({ id, payload }) {
		return $http.patch(`workflow/${id}`, payload);
	}
}
