<!--
	@name app-air-academy-dashboard
	@description Join tab view for the air academy page
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-dialog v-model="active" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
			<v-card tile>
				<v-toolbar flat dark class="pl-3 pr-3 flex-grow-0 generic-gradient">
					<v-row>
						<v-col cols="12" sm="10" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								{{ title }}
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-col cols="12" sm="2" class="d-flex pa-0 justify-end">
							<v-btn icon dark class="align-self-center" @click="active = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-toolbar>
				<v-card-text>
					<v-container class="px-0 pb-10">
						<v-sheet class="pa-6 mb-3 rounded grey lighten-2">
							<v-row no-gutters>
								<v-col cols="12" sm="7" class="align-content-center">
									<p class="text-h6 mb-0">
										{{ title }}
									</p>
								</v-col>
								<v-col cols="12" sm="5" class="grow text-right">
									<slot name="logo" />
								</v-col>
							</v-row>
						</v-sheet>
						<slot name="highlight" />

						<v-row class="mb-6">
							<v-col cols="7" class="pr-10">
								<slot name="copy" />
							</v-col>
							<v-col cols="5">
								<v-skeleton-loader v-if="!testimonial.name" type="card" class="pa-0 ma-0" />
								<app-air-academy-training-webinar-item v-else :item="testimonial" @play-video="playVideo" />
								<app-dialog-video-player ref="videoDialog" />
							</v-col>
						</v-row>

						<v-divider class="my-8" inset />

						<template v-if="restricted && courseIntersection.length && coursesToDisplay.length && authStatus == 'success'">
							<slot name="upgrade" />
						</template>

						<div v-if="authStatus == 'error'" class="mt-6">
							<p class="font-weight-bold body-1 mb-4">
								To link your Air Academy account, please use the username and password sent to your email by academy.enquiries@airlaterlife.co.uk.
								If you have any questions on how to do this, please <a href="mailto:Academy.enquiries@airlaterlife.co.uk">click here</a> to get in contact.
							</p>

							<v-btn light color="primary" @click="openAuthoriseDialog">
								Connect my Air Academy Learning Platform
							</v-btn>
						</div>

						<div v-else-if="!catalog.length" class="mt-8 text-center">
							<p class="mb-2 body-1 font-italic">
								Retrieving LMS course list
							</p>
							<v-progress-linear height="10" indeterminate />
						</div>

						<div v-else-if="authStatus == null" class="mt-8 text-center">
							<p class="mb-2 body-1 font-italic">
								Retrieving your LMS account details
							</p>
							<v-progress-linear height="10" indeterminate />
						</div>

						<v-row v-else-if="coursesToDisplay.length > 0">
							<v-col v-for="(item, index) in coursesToDisplay" :key="item.id" cols="4">
								<app-air-academy-training-webinar-item :key="index" :item="item" />
							</v-col>
						</v-row>

						<template v-else>
							<v-alert type="error" class="text-body-2 mb-3">
								Error retrieving LMS course list
							</v-alert>
						</template>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { mapGetters, mapState } from 'vuex';
	import { EventBus } from '@/utils';
	import AppAirAcademyTrainingWebinarItem from '@/component/app/air-academy/training/webinar-item';
	import AppDialogVideoPlayer from '@/component/app/dialog/video-player';

	export default {
		name: 'app-air-academy-dashboard',

		components: {
			AppAirAcademyTrainingWebinarItem,
            AppDialogVideoPlayer
		},

        props: {
			restricted: { type: Boolean, required: false, default: true },
			ring: { type: Number, required: true },
			catalogId: { type: Number, required: true },
			title: { type: String, required: true },
			video: { type: String, required: true },
			testimonial: { type: Object, default: () => {} },
			lenders: { type: Array, default: () => [] }
		},

		data() {
			return {
				active: false
			};
		},

		computed: {
			...mapState('AppWebinar', ['authStatus', 'catalogs']),
			...mapGetters('AppWebinar', ['getCatalogWithEnrolment', 'getRingEnrolled', 'getRingTeasers', 'getRingEnrolledCourseIds']),

			/**
			 * @name catalog
			 * @description Fetch all courses in a given catalog and merge enrolment data
			 * @return {Array}
			 */
			catalog() {
				return this.getCatalogWithEnrolment(this.ring, this.catalogId);
			},

			/**
			 * @name teasers
			 * @description TODO
			 * @return {Array}
			 */
			teasers() {
				return this.getRingTeasers(this.ring, this.catalogId);
			},

			/**
			 * @name courses
			 * @description TODO
			 * @return {Array}
			 */
			courses() {
				return this.getRingEnrolled(this.ring);
			},

			/**
			 * @name courseIntersection
			 * @description Determine whether I am enrolled on all courses
			 * @return {Array}
			 */
			courseIntersection() {
				let courses = this.courses.map((x) => Number(x.id_course));
				let courseIds = this.getRingEnrolledCourseIds(this.ring);
				let intersection = courseIds.filter(x => !courses.includes(x));
				return intersection;
			},

			/**
			 * @name coursesToDisplay
			 * @description TODO
			 * @return {Array}
			 */
			coursesToDisplay() {
                if (!this.restricted) return this.catalog;
				return this.courseIntersection.length ? this.teasers : this.catalog;
			}
		},

		methods: {
            open() {
                this.active = true;
            },

            close() {
                this.active = false;
            },

			playVideo() {
				this.$refs.videoDialog.open(`${process.env.VUE_APP_S3_AIR_ASSETS_URL}/${this.video}`);
			},

			openAuthoriseDialog() {
				EventBus.$emit('open-authorise-dialog');
			}
		}
	};
</script>

<style scoped lang="scss">
</style>