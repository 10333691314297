<!--
	@name app-common-rate-change-table
	@description Rate changes table
	@date 2021/03/09
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<p class="caption mb-1">
			Click on a column heading to sort results by that column. Click the same column a second time to switch from ascending order to descending order.
			<br />
			You can sort the table using multiple columns in order to tailor results to your specific needs.
			<span class="font-weight-bold">By default, results are ordered by Last KFI date in ascending order.</span>
		</p>
		<v-row class="mb-2">
			<v-col md="3">
				<v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dense solo outlined clearable />
			</v-col>
			<v-col md="6">
				<v-combobox v-model="activeCategories" clearable dense solo multiple outlined small-chips hide-details :items="categories" label="Filter" />
			</v-col>
		</v-row>

		<v-data-table
			:headers="headers"
			:items="orderedItems"
			:items-per-page="10"
			:loading="orderedItems.length ? false : true"
			:search="search"
			hide-default-footer
			disable-pagination
			multi-sort
			class="elevation-3 table--styled"
		>
			<template #body="{ items }">
				<tbody>
					<tr v-for="item in items" :key="item.name">
						<td class="shrunk">
							<img class="item-image" alt="#" :src="`${item.organisationId}.png` | urlize('organisation')" />
						</td>
						<td>
							<p class="font-weight-bold mb-3">
								{{ item.product }}
							</p>
						</td>
						<td class="nowrap text-center">
							<v-tooltip bottom>
								<template #activator="{ on }">
									<v-icon x-large :color="getChangeIcon(item).color" v-on="on">
										{{ getChangeIcon(item).icon }}
									</v-icon>
								</template>
								<span>{{ getChangeIcon(item).hover }}</span>
							</v-tooltip>
						</td>
						<td class="nowrap text-center">
							<v-tooltip bottom :disabled="!getInfoText(item).hover">
								<template #activator="{ on }">
									<span :class="{ 'text-decoration-underline': !!getInfoText(item).hover }" v-on="on">
										{{ getInfoText(item).text }}
									</span>
								</template>
								<span>{{ getInfoText(item).hover }}</span>
							</v-tooltip>
						</td>
						<td class="shrunk nowrap text-left font-weight-bold">
							{{ item.effective | moment('MMM DD, YYYY') }}
						</td>
						<td class="shrunk nowrap text-left font-weight-bold">
							{{ getDateDisplay(item, 'lastKfi', 'MMM DD, YYYY') }}
						</td>
						<td class="shrunk nowrap text-left font-weight-bold">
							{{ getDateDisplay(item, 'lastApplication', 'MMM DD, YYYY') }}
						</td>
					</tr>
				</tbody>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { orderBy } from 'lodash';

	export default {
		name: 'app-common-rate-change-table',

		components: {},

		props: {
			productChanges: { type: Array, required: false, default: () => [] }
		},

		data() {
			return {
				activeCategories: [],
				categories: ['Rate change', 'LTV change', 'Cashback withdrawal', 'Product withdrawal'],
				headers: [
					{ text: 'Provider', value: 'organisationName', class: 'shrunk nowrap' },
					{ text: 'Product', value: 'product' },
					{ text: 'Change', value: 'data', sort: this.changeSort, class: 'nowrap', align: 'center' },
					{ text: 'More info', value: 'info', class: 'nowrap', sortable: false, align: 'center' },
					{ text: 'Effective Date', value: 'effective', class: 'shrunk nowrap', align: 'left' },
					{ text: 'Last KFI', value: 'lastKfi', class: 'shrunk nowrap', align: 'left' },
					{ text: 'Last Application', value: 'lastApplication', class: 'shrunk nowrap', align: 'left' }
				],
				search: ''
			};
		},

		computed: {
			items() {
				return this.productChanges.map((o) => Object.assign({ data: o }, o));
			},

			orderedItems() {
				let activeCategoriesLower = this.activeCategories.map((element) => element.toLowerCase());
				let filtered = activeCategoriesLower.length ? this.items.filter((item) => activeCategoriesLower.includes(item.category.toLowerCase())) : this.items;
				return orderBy(filtered, 'lastKfi', ['asc']);
			}
		},

		methods: {
			changeSort(a, b) {
				b = (b.category.toLowerCase() + b.type.toLowerCase()).replace(/\s/g, '');
				return (a.category.toLowerCase() + a.type.toLowerCase()).replace(/\s/g, '').localeCompare(b);
			},

			getInfoText(item) {
				if (item.category.toLowerCase() == 'rate change') {
					if (item.info) return { text: item.info, hover: `New rates from ${item.info}` };
					return { text: 'Awaiting details', hover: false };
				} else if (item.info) return { text: item.info, hover: false };
				return { text: '--', hover: false };
			},

			getChangeIcon(item) {
				if (item.category.toLowerCase() == 'rate change') {
					if (item.type === 'Increase') return { icon: 'mdi-arrow-up-thin-circle-outline', color: 'red', hover: 'Rates will increase' };
					if (item.type === 'Decrease') return { icon: 'mdi-arrow-down-thin-circle-outline', color: 'green', hover: 'Rates will decrease' };
					return { icon: 'mdi-progress-question', color: 'amber', hover: 'Rates will increase, decrease or stay the same' };
				} else if (item.category.toLowerCase() == 'ltv change') {
					if (item.type === 'Increase') return { icon: 'mdi-home-plus', color: 'green', hover: 'LTVs will increase' };
					if (item.type === 'Decrease') return { icon: 'mdi-home-minus', color: 'red', hover: 'LTVs will decrease' };
					return { icon: 'mdi-progress-question', color: 'amber', hover: 'LTVs will increase, decrease or stay the same' };
				} else if (item.category.toLowerCase() == 'product withdrawal') {
					return { icon: 'mdi-package-variant-remove', color: 'red darken-2', hover: 'Product is being withdrawn from the market' };
				} else if (item.category.toLowerCase() == 'product launch') {
					return { icon: 'mdi-package-variant-plus', color: 'green', hover: 'Product is being launched' };
				} else if (item.category.toLowerCase() == 'cashback withdrawal') {
					return { icon: 'mdi-cash-remove', color: 'red darken-2', hover: 'Cashback is being withdrawn from the market' };
				}
			},

			categoryColor(category) {
				if (category.toLowerCase() == 'cashback withdrawal') return 'warning';
				if (category.toLowerCase() == 'product withdrawal') return 'red accent-2 white--text';
				if (category.toLowerCase() == 'product launch') return 'green white--text';
				if (category.toLowerCase() == 'ltv change') return 'pink accent-1';
				if (category.toLowerCase() == 'rate change') return 'blue accent-2';
				return 'grey';
			},

			getDateDisplay(item, key, format) {
				if (item.category.toLowerCase() == 'product launch') return '--';
				return this.$moment(item[key]).format(format);
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '~vuetify/src/styles/styles.sass';

	.date-table {
		margin-top: 50px;
		&:first-child {
			margin-top: 0;
		}
	}

	.item-image {
		max-height: 100px;
		max-width: 80px;
	}

	::v-deep .theme--light.v-data-table table th,
	::v-deep .theme--light.v-data-table table td {
		font-size: map-deep-get($headings, 'body-2', 'size') !important;
		padding: 16px !important;
		vertical-align: top;
	}

	::v-deep .shrunk {
		width: 1px;
	}

	::v-deep .nowrap {
		white-space: nowrap;
	}
</style>
